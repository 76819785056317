import React from "react"
import { ThemeProps } from "style/theme"
import styled from "styled-components"

const CastLauncher = styled.div`
  display: contents;
  width: 24px;
  height: 24px;
  cursor: pointer;

  > google-cast-launcher {
    width: inherit;
    height: inherit;

    --connected-color: ${({ theme }: ThemeProps): string => theme.primary.color};

    :hover {
      --disconnected-color: white;
    }
  }
`

export const CastControl = ({ className }: React.HTMLAttributes<HTMLElement>): JSX.Element => (
  <CastLauncher>
    <google-cast-launcher id="castbutton" class={className} />
  </CastLauncher>
)

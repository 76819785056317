import { LoginEndpoint } from "constants/endpoints"
import { LoginErrorResponse, LoginRequest, LoginResponse } from "typings"
import { HttpClient, isAxiosException } from "../api-client"

export const login = async (httpClient: HttpClient, request: LoginRequest): Promise<LoginResponse> => {
  /* eslint-disable functional/no-let */
  let success: boolean = true
  let statusCode: number = 200
  let error: string | undefined = undefined
  /* eslint-enable functional/no-let */

  // eslint-disable-next-line functional/no-let
  let username: string = ""

  try {
    const response = await httpClient.post<LoginApiResponse>(LoginEndpoint, null, request, false)
    statusCode = response.status
    username = response.data.username
  } catch (ex) {
    if (isAxiosException<LoginErrorResponse>(ex)) {
      statusCode = ex.response?.status ?? 400
      error = ex.response?.data.error
    }
    success = false
  }

  const loginResponse: LoginResponse = {
    success,
    username,
    statusCode,
    error,
  }

  return loginResponse
}

interface LoginApiResponse {
  readonly userId: number
  readonly username: string
}

import { ContentList } from "components/content-list"
import { CustomScroller } from "components/custom-scroller"
import { PaddedPageContent } from "components/page"
import { Body1 } from "components/typography"
import { useComingSoon } from "domain/content/hooks"
import React from "react"
import styled from "styled-components"
import { UseEffectReturn } from "typings"
import { usePageHistory } from "util/hooks"
import { HomeRoute, Levels } from "../../../constants"
import { useWatchHistory } from "../hooks"
import { useRecentlyAdded } from "../hooks/use-recently-added"

const HomeContainer = styled.div`
  display: grid;
  place-content: stretch;
  overflow: hidden;
  z-index: ${Levels.Content};
`

const ContentListContainer = styled.div`
  display: grid;
  gap: 32px;
  padding: 32px 0 0 0;

  > *:last-child {
    margin-bottom: 32px;
  }
`

const Home = (): JSX.Element => {
  const { data: watchHistory, error: watchHistoryError } = useWatchHistory()
  const { data: recentlyAdded, error: recentlyAddedError } = useRecentlyAdded()
  const { data: comingSoon, error: comingSoonError } = useComingSoon()
  const { pushHistory } = usePageHistory()

  React.useEffect((): UseEffectReturn => {
    pushHistory({
      title: "Home",
      path: HomeRoute,
    })
  }, [])

  return watchHistoryError && recentlyAddedError && comingSoonError ? (
    <PaddedPageContent>
      <Body1>Failed to load Home content</Body1>
    </PaddedPageContent>
  ) : (
    <HomeContainer>
      <CustomScroller>
        <ContentListContainer>
          {(watchHistory?.watchHistories.length ?? 0) > 0 && <ContentList id="watch_history" header="Recently Watched" items={watchHistory!.watchHistories} />}
          {(recentlyAdded?.recentlyAdded.length ?? 0) > 0 && <ContentList id="recently_added" header="Recently Added" items={recentlyAdded!.recentlyAdded} />}
          {(comingSoon?.comingSoon.length ?? 0) > 0 && (
            <ContentList id="coming_soon" header="Coming Soon" items={comingSoon!.comingSoon} alwaysFullBrightness={true} />
          )}
        </ContentListContainer>
      </CustomScroller>
    </HomeContainer>
  )
}

export default Home

import React from "react"
import ReactPlayer from "react-player"
import { UseControlsVisibility, useControlsVisibility } from "./player/hooks/use-controls-visibility"
import { UseSubtitles, useSubtitles } from "./player/hooks/use-subtitles"
import { useVideoFullscreen, UseVideoFullscreen } from "./player/hooks/use-video-fullscreen"
import { useVideoPausePlay, UseVideoPausePlay } from "./player/hooks/use-video-pause-play"
import { useVideoPlayback, UseVideoPlayback } from "./player/hooks/use-video-playback"
import { useVideoVolume, UseVideoVolume } from "./player/hooks/use-video-volume"
import { WatchPlayerMediaIdentifier } from "./watch-player-media-identifier"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const WatchPlayerContext = React.createContext<WatchPlayerContext>({} as any)

export const WatchPlayerProvider = ({
  type,
  movieDbId,
  episodeId,
  watchToken,
  reactPlayerRef,
  wrapperRef,
  overlayRef,
  children,
}: WatchPlayerProviderProps): JSX.Element => {
  const videoPlayback = useVideoPlayback(reactPlayerRef)
  const videoPausePlay = useVideoPausePlay(reactPlayerRef)
  const videoVolume = useVideoVolume(reactPlayerRef)
  const videoFullscreen = useVideoFullscreen(wrapperRef)
  const controlsVisibility = useControlsVisibility(overlayRef)
  const videoSubtitles = useSubtitles()

  const value: WatchPlayerContext = {
    type,
    movieDbId,
    episodeId,
    watchToken,
    reactPlayerRef,
    wrapperRef,
    overlayRef,
    ...videoPlayback,
    ...videoPausePlay,
    ...videoVolume,
    ...videoFullscreen,
    ...controlsVisibility,
    ...videoSubtitles,
  }

  return <WatchPlayerContext.Provider value={value}>{children}</WatchPlayerContext.Provider>
}

export const useWatchPlayer = (): WatchPlayerContext => React.useContext(WatchPlayerContext)

interface WatchPlayerProviderProps extends WatchPlayerMediaIdentifier {
  readonly children: JSX.Element
  readonly reactPlayerRef: React.RefObject<ReactPlayer>
  readonly wrapperRef: React.RefObject<HTMLDivElement>
  readonly overlayRef: React.RefObject<HTMLDivElement>
}

export interface WatchPlayerContext
  extends WatchPlayerMediaIdentifier,
    UseVideoPlayback,
    UseVideoPausePlay,
    UseVideoVolume,
    UseVideoFullscreen,
    UseControlsVisibility,
    UseSubtitles {
  readonly reactPlayerRef: React.RefObject<ReactPlayer>
  readonly wrapperRef: React.RefObject<HTMLDivElement>
  readonly overlayRef: React.RefObject<HTMLDivElement>
}

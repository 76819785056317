import React from "react"
import ReactDOM from "react-dom"
import { ThemeProps } from "style/theme"
import styled from "styled-components"
import { Levels, Portals } from "../../constants"

const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${({ theme }: ThemeProps): string => theme.background.color};
  color: ${({ theme }: ThemeProps): string => theme.surface.textOn};
  padding: 16px;
  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.4);
`

const Scrim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${Levels.Modal};
  background: rgba(0, 0, 0, 0.3);
`

export const ModalPortal = (): JSX.Element => <div id={Portals.Modal} />

export const Modal = ({ children, className, isOpen }: ModalProps): JSX.Element =>
  ReactDOM.createPortal(
    isOpen ? (
      <Scrim>
        <ModalContainer className={className}>{children}</ModalContainer>
      </Scrim>
    ) : null,
    document.getElementById(Portals.Modal)!,
  )

export interface ModalProps {
  readonly isOpen: boolean
  readonly children: React.ReactNode
  readonly className?: string
}

import React from "react"
import styled from "styled-components"
import { Levels } from "../../../constants"
import { CastControl } from "./controls/cast-control"
import { FullscreenControl } from "./controls/fullscreen-control"
import { PausePlayControl } from "./controls/pause-play-control"
import { PlaybackLabels } from "./controls/playback-labels"
import { PlaybackSlider } from "./controls/playback-slider"
import { SubtitlesControl } from "./controls/subtitles-control"
import { VolumeControl } from "./controls/volume-control"

const controlGridAreas = {
  pausePlay: "pausePlay",
  playbackLabels: "playbackLabels",
  subtitles: "subtitles",
  cast: "cast",
  volume: "volume",
  fullscreen: "fullscreen",
  playbackSlider: "playbackSlider",
}

const controlsGrid = `
"${controlGridAreas.pausePlay} ${controlGridAreas.playbackLabels} ${controlGridAreas.subtitles} ${controlGridAreas.cast} ${controlGridAreas.volume} ${controlGridAreas.fullscreen}" auto
"${controlGridAreas.playbackSlider} ${controlGridAreas.playbackSlider} ${controlGridAreas.playbackSlider} ${controlGridAreas.playbackSlider} ${controlGridAreas.playbackSlider} ${controlGridAreas.playbackSlider}" auto / auto 1fr auto auto auto
`

const Controls = styled.div`
  display: grid;
  align-content: end;
  align-items: center;
  grid: ${controlsGrid};

  column-gap: 8px;
  transition: opacity 0.6s;

  user-select: none;
  * {
    user-select: none;
  }

  z-index: ${Levels.ElevatedContent};
`

const PausePlayArea = styled(PausePlayControl)`
  grid-area: ${controlGridAreas.pausePlay};
`

const PlaybackLabelsArea = styled(PlaybackLabels)`
  grid-area: ${controlGridAreas.playbackLabels};
`

const PlaybackSliderArea = styled(PlaybackSlider)`
  grid-area: ${controlGridAreas.playbackSlider};
`

const VolumeArea = styled(VolumeControl)`
  grid-area: ${controlGridAreas.volume};
`

const FullscreenArea = styled(FullscreenControl)`
  grid-area: ${controlGridAreas.fullscreen};
`

const CastArea = styled(CastControl)`
  grid-area: ${controlGridAreas.cast};
`

const SubtitlesArea = styled(SubtitlesControl)`
  grid-area: ${controlGridAreas.subtitles};
`

export const WatchPlayerControls = ({ className, isVisible }: WatchPlayerControlsProps): JSX.Element => (
  <Controls className={className}>
    {isVisible && (
      <>
        <PausePlayArea />
        <PlaybackLabelsArea />
        <PlaybackSliderArea />
        <SubtitlesArea />
        <CastArea />
        <VolumeArea />
        <FullscreenArea />
      </>
    )}
  </Controls>
)

export interface WatchPlayerControlsProps extends React.HTMLAttributes<HTMLDivElement> {
  readonly isVisible: boolean
}

import { FullscreenRouteParams } from "domain/content/components/fullscreen/fullscreen-route-params"
import React from "react"
import { matchPath, useLocation } from "react-router"
import { Func0, Func1, MovieDbMediaType, Nullable } from "typings"
import { FullscreenRoutePath, MoviesRoute, ShowsRoute, typeMap } from "../../constants"

export type Page = {
  readonly path: string
  readonly title: string
}

export type PageHistoryState = {
  readonly previousPage: Nullable<Page>
  readonly currentPage: Nullable<Page>
}

export type PageHistoryDispatch = {
  readonly pushHistory: Func1<Page, void>
  readonly popHistory: Func0<void>
}

export type PageHistoryContext = PageHistoryState & PageHistoryDispatch

const detectPreviousPage = (pathname: string): Nullable<Page> => {
  const fullscreenMatch = matchPath<FullscreenRouteParams>(pathname, FullscreenRoutePath)
  if (fullscreenMatch) {
    switch (fullscreenMatch.params.type) {
      case typeMap.get(MovieDbMediaType.Movie):
        return {
          path: MoviesRoute,
          title: "Movies",
        }
      case typeMap.get(MovieDbMediaType.Tv):
        return {
          path: ShowsRoute,
          title: "Shows",
        }
    }
  }

  return null
}

const PageHistoryContext = React.createContext({} as PageHistoryContext)

export const PageHistoryProvider = ({ children }: React.HTMLAttributes<HTMLElement>): JSX.Element => {
  const { pathname } = useLocation()
  const [history, setHistory] = React.useState<ReadonlyArray<Page>>([])

  const pushHistory = (page: Page): void => setHistory([...history, page])
  const popHistory = (): Page => {
    const poppedPage = history[history.length - 1]
    setHistory(history.slice(0, -1))

    return poppedPage
  }

  const state: PageHistoryState = {
    previousPage: history[history.length - 2] ?? detectPreviousPage(pathname),
    currentPage: history[history.length - 1],
  }

  const dispatch: PageHistoryDispatch = {
    pushHistory,
    popHistory,
  }

  const value: PageHistoryContext = {
    ...state,
    ...dispatch,
  }

  return <PageHistoryContext.Provider value={value}>{children}</PageHistoryContext.Provider>
}

export const usePageHistory = (): PageHistoryContext => React.useContext(PageHistoryContext)

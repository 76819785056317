import React from "react"
import { fullSizeCss } from "style/css"
import { ThemeProps } from "style/theme"
import styled from "styled-components"
import { AvailabilityStatus } from "typings"
import { Levels } from "../constants"
import { MdIcon } from "./md-icon"
import { Body1 } from "./typography"

const AvailabilityOverlayContainer = styled.div<AvailabilityOverlayProps>`
  ${fullSizeCss};

  display: grid;
  place-items: center;
  place-content: center;

  z-index: ${Levels.Overlay};

  border: 2px solid;
  border-color: transparent;
  cursor: ${({ availabilityStatus }: AvailabilityOverlayProps): string => (availabilityStatus === AvailabilityStatus.Available ? "pointer" : "")};

  opacity: 0;

  transition: opacity 150ms, border-color 150ms;
  :hover {
    opacity: 1;
    border-color: ${({ theme, availabilityStatus }: ThemeProps & AvailabilityOverlayProps): string =>
      availabilityStatus === AvailabilityStatus.Available ? theme.primary.color : theme.surface.textOn};
  }
`

const OverlayIconBase = styled(MdIcon)`
  font-size: 3rem;
`

const UnavailableIcon = styled(OverlayIconBase).attrs({ icon: "help_outline" })`
  color: ${({ theme }: ThemeProps): string => theme.surface.textOn};
  opacity: 0.75;
  cursor: default;
`

const AvailableIcon = styled(OverlayIconBase).attrs({ icon: "play_circle_outline" })`
  color: ${({ theme }: ThemeProps): string => theme.primary.color};
  cursor: pointer;
`

export const AvailabilityOverlay = (props: AvailabilityOverlayProps): JSX.Element | null => {
  switch (props.availabilityStatus) {
    case AvailabilityStatus.Available: {
      return (
        <AvailabilityOverlayContainer {...props}>
          <AvailableIcon />
        </AvailabilityOverlayContainer>
      )
    }

    case AvailabilityStatus.Unavailable:
    case AvailabilityStatus.DownloadInProgress:
    case AvailabilityStatus.Requested: {
      return (
        <AvailabilityOverlayContainer {...props}>
          <UnavailableIcon />
          <Body1>Unavailable</Body1>
        </AvailabilityOverlayContainer>
      )
    }
  }

  return null
}

export interface AvailabilityOverlayProps {
  readonly availabilityStatus: AvailabilityStatus
}

import { MdIcon } from "components/md-icon"
import { useWatchPlayer } from "domain/watch/watch-player-context"
import { getVideoFromReactPlayerRef } from "domain/watch/watch-player-utils"
import React, { useEffect } from "react"
import { ColorShift } from "style/color-shifting"
import { ThemeProps } from "style/theme"
import styled from "styled-components"

const SubtitlesIcon = styled(MdIcon)<SubtitlesIconProps>`
  font-size: 28px;
  cursor: pointer;

  color: ${({ theme, enabled }: ThemeProps & SubtitlesIconProps): string => (enabled ? theme.primary.color : theme.surface.textOn)};

  :hover {
    color: ${({ theme, enabled }: ThemeProps & SubtitlesIconProps): string => (enabled ? ColorShift.medium(theme.primary.color, "white") : "white")};
  }
`

export const SubtitlesControl = ({ className }: React.HTMLAttributes<HTMLElement>): JSX.Element => {
  const { isSubtitlesEnabled, setIsSubtitlesEnabled, reactPlayerRef } = useWatchPlayer()

  const onIconClicked = async (): Promise<void> => {
    setIsSubtitlesEnabled(!isSubtitlesEnabled)
  }

  useEffect(() => {
    const video = getVideoFromReactPlayerRef(reactPlayerRef)
    if (video) {
      video.textTracks[0].mode = isSubtitlesEnabled ? "showing" : "hidden"
    }
  }, [isSubtitlesEnabled])

  return <SubtitlesIcon className={className} enabled={isSubtitlesEnabled} tabIndex={-1} icon="closed_caption" onClick={onIconClicked} />
}

interface SubtitlesIconProps {
  readonly enabled: boolean
}

import GetMoviePlaybackTime from "./get-movie-playback-time.graphql"
import GetTvEpisodePlaybackTime from "./get-tv-episode-playback-time.graphql"
import GetWatchToken from "./get-watch-token.graphql"
import SetMoviePlaybackTime from "./set-movie-playback-time.graphql"
import SetTvEpisodePlaybackTime from "./set-tv-episode-playback-time.graphql"

export const WatchGraphqlQueries = {
  GetMoviePlaybackTime,
  GetTvEpisodePlaybackTime,
  GetWatchToken,
}

export const WatchGraphqlMutations = {
  SetMoviePlaybackTime,
  SetTvEpisodePlaybackTime,
}

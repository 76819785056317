import { CustomScroller } from "components/custom-scroller"
import { NavigationArrows } from "components/navigation/navigation-arrows"
import { NavigationContainer, navigationGridAreas, NavigationHeader, NavigationScroller } from "components/navigation/navigation-container"
import { useNavbar } from "domain/navbar/context/navbar-context"
import React from "react"
import styled from "styled-components"
import { GetMovieDetailsGetMovieDetailsCast, GetShowDetailsGetTvDetailsCast } from "typings"
import { ContentNavigationProvider, useContentNavigation } from "util/hooks"
import { getEffectiveItemSize } from "./cast-list-calculations"
import { CastListItem } from "./cast-list-item"

const CastListScrollerWrapper = styled.div<CastListScrollerWrapperProps>`
  grid-area: ${navigationGridAreas.scroller};
  height: ${({ itemHeight }: CastListScrollerWrapperProps): string => `${itemHeight + 50}px`};
  overflow-x: hidden;

  &:after {
    height: ${({ itemHeight }: CastListScrollerWrapperProps): string => `${itemHeight}px`};
  }
`

const InnerCastList = ({ header, items }: CastListProps): JSX.Element => {
  const {
    scrollerRef,
    customScrollerRef,
    scrollerClientWidth,
    scrollerScrollWidth,
    onCustomScrollerScrolled,
    onCustomScrollerScrollStop,
  } = useContentNavigation()
  const navbar = useNavbar()

  const [itemWidth, itemHeight] = getEffectiveItemSize(scrollerClientWidth)

  const mapItem = (item: GetMovieDetailsGetMovieDetailsCast | GetShowDetailsGetTvDetailsCast): JSX.Element => (
    <CastListItem key={item.id} item={item} itemWidth={itemWidth} itemHeight={itemHeight} />
  )

  return (
    <NavigationContainer>
      <NavigationHeader {...navbar}>{header}</NavigationHeader>
      {!!scrollerClientWidth && !!scrollerScrollWidth && <NavigationArrows />}
      <CastListScrollerWrapper itemHeight={itemHeight}>
        <CustomScroller ref={customScrollerRef} onScroll={onCustomScrollerScrolled} onScrollStop={onCustomScrollerScrollStop}>
          <NavigationScroller ref={scrollerRef} {...navbar}>
            {items.map(mapItem)}
          </NavigationScroller>
        </CustomScroller>
      </CastListScrollerWrapper>
    </NavigationContainer>
  )
}

export const CastList = (props: CastListProps): JSX.Element => (
  <ContentNavigationProvider>
    <InnerCastList {...props} />
  </ContentNavigationProvider>
)

export interface CastListProps {
  readonly header: string
  readonly items: ReadonlyArray<GetMovieDetailsGetMovieDetailsCast | GetShowDetailsGetTvDetailsCast>
}

interface CastListScrollerWrapperProps {
  readonly itemHeight: number
}

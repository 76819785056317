import { QueryResult } from "@apollo/react-common"
import { useQuery } from "@apollo/react-hooks"
import { GetContentForGenre, GetContentForGenreVariables } from "typings"
import { MovieDbQueries } from "../graphql"

export const useContentForGenre = (genreId: number): QueryResult<GetContentForGenre> =>
  useQuery<GetContentForGenre, GetContentForGenreVariables>(MovieDbQueries.GetContentForGenre, {
    variables: {
      genreId,
    },
  })

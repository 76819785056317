import { ButtonProps, Typography } from "@material-ui/core"
import { Button } from "components/button"
import { Card } from "components/card"
import { FieldInput } from "components/field-input"
import { Logo } from "components/logo"
import { Body1 } from "components/typography"
import { routerActions } from "connected-react-router"
import { useHttpClient } from "domain/network/api-client"
import { register } from "domain/network/requests"
import React from "react"
import { Form } from "react-final-form"
import { useStore } from "react-redux"
import { Link } from "react-router-dom"
import { ThemeProps } from "style/theme"
import styled from "styled-components"
import { Nullable, RegisterRequest, RootState } from "typings"
import { LoginRoute } from "../../../constants"
import { validatePassword } from "../validators/validate-password"
import { validateUsername } from "../validators/validate-username"

const RegisterPage = styled.div`
  padding-top: 50px;
`

const StyledRegister = styled(Card)`
  background-color: ${({ theme }: ThemeProps): string => `${theme.surface.color}99`} !important;
  display: grid;
  justify-content: center;
  align-content: start;
  width: fit-content;
  height: fit-content;
  margin: 2rem auto;
  padding: 2rem;
  padding-bottom: 0.5rem;
`

const RegisterLogo = styled(Logo)`
  width: 225px;
  margin: 0 auto;
`

const StyledRegisterForm = styled.form`
  display: grid;
  gap: 15px;
  justify-content: stretch;
  padding: 0 1rem 1.5rem 1rem;
  width: 20rem;
`

const RegisterError = styled(Typography)`
  justify-self: center;
  color: ${({ theme }: ThemeProps): string => theme.primary.color};
  padding-top: 8px;
`

const RegisterButton = styled(Button).attrs<ButtonProps>({ variant: "contained" })`
  width: 125px;
  justify-self: center;
`

const LoginLink = styled(Link)`
  color: ${({ theme }: ThemeProps): string => theme.primary.color} !important;
  cursor: pointer;
  font-size: 0.85rem !important;
  justify-self: center;
`

const Requirements = styled(Body1)`
  margin: 0 auto;
  width: fit-content;
  line-height: 1.5;
`

const Register = (): JSX.Element => {
  const store = useStore<RootState>()
  const httpClient = useHttpClient()
  const [loginError, setRegisterError] = React.useState<Nullable<string>>(null)

  const onSubmit = async (formModel: RegisterFormModel): Promise<void> => {
    if (formModel.password !== formModel.confirmPassword) {
      setRegisterError("Passwords do not match")
      return
    }

    setRegisterError(null)
    const { success, error } = await register(httpClient, formModel)
    if (error) {
      setRegisterError(error)
    } else if (success) {
      store.dispatch(routerActions.push(`${LoginRoute}?p=1`))
    }
  }

  return (
    <RegisterPage>
      <StyledRegister elevation={8}>
        <RegisterLogo />
        <Form onSubmit={onSubmit}>
          {(props): React.ReactNode => {
            return (
              <StyledRegisterForm onSubmit={props.handleSubmit}>
                <RegisterError>{loginError}</RegisterError>
                <FieldInput name="username" label="Username" validate={validateUsername} />
                <FieldInput name="password" label="Password" type="password" validate={validatePassword} />
                <FieldInput name="confirmPassword" label="Confirm Password" type="password" validate={validatePassword} />
                <RegisterButton type="submit">Register</RegisterButton>
              </StyledRegisterForm>
            )
          }}
        </Form>
        <LoginLink to={LoginRoute}>Already have an account?</LoginLink>
      </StyledRegister>
      <Requirements>
        - Username must be at least 4 characters (letters, numbers)
        <br />- Password must be at least 6 characters (letters, numbers, symbols)
      </Requirements>
    </RegisterPage>
  )
}

export default Register

export interface RegisterFormModel extends RegisterRequest {
  readonly confirmPassword: string
}

import React from "react"
import styled from "styled-components"
import { ExploreRoute, HomeRoute, Levels, MoviesRoute, ShowsRoute, TrendingRoute } from "../../../constants"
import { useNavbar } from "../context/navbar-context"
import { NavbarLink, NavbarLinkBase } from "./navbar-link"

const StyledNavbar = styled.div<StyledNavbarProps>`
  align-content: start;
  background: linear-gradient(to right, black 0%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.2) 90%, rgba(0, 0, 0, 0) 100%);
  display: grid;
  overflow: hidden;
  transition: width 200ms;
  width: ${({ navbarWidth }: StyledNavbarProps): string => `${navbarWidth}px`};
  z-index: ${Levels.ElevatedContent};
`

const homeLink: NavbarLinkBase = {
  icon: "home",
  label: "Home",
  route: HomeRoute,
}

const moviesLink: NavbarLinkBase = {
  icon: "movie",
  label: "Movies",
  route: MoviesRoute,
}

const showsLink: NavbarLinkBase = {
  icon: "tv",
  label: "TV Shows",
  route: ShowsRoute,
}

const exploreLink: NavbarLinkBase = {
  icon: "explore",
  label: "Explore",
  route: ExploreRoute,
}

const trendingLink: NavbarLinkBase = {
  icon: "trending_up",
  label: "Trending",
  route: TrendingRoute,
}

const navbarLinks: ReadonlyArray<NavbarLinkBase> = [homeLink, moviesLink, showsLink, exploreLink, trendingLink]

export const Navbar = (props: NavbarProps): JSX.Element => {
  // const navbarMode = useSelector(navbarSelectors.getNavbarMode)
  const { navbarWidth, isExpanded } = useNavbar()
  // const isExpanded = navbarMode === "expanded"

  return (
    <StyledNavbar {...props} isExpanded={isExpanded} navbarWidth={navbarWidth}>
      {navbarLinks.map(link => (
        <NavbarLink key={link.label} isExpanded={isExpanded} {...link} />
      ))}
    </StyledNavbar>
  )
}

export type NavbarProps = React.HTMLAttributes<HTMLDivElement>

interface StyledNavbarProps {
  readonly isExpanded: boolean
  readonly navbarWidth: number
}

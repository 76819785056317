import { PublicBackdropsEndpoint } from "constants/endpoints"
import { PublicBackdropsResponse } from "typings"
import { HttpClient } from "../api-client"

export const getPublicBackdrops = async (httpClient: HttpClient): Promise<ReadonlyArray<string>> => {
  try {
    const response = await httpClient.get<PublicBackdropsResponse>(PublicBackdropsEndpoint)
    return response.data?.backdropUrls ?? []
  } catch (ex) {
    return []
  }
}

import React, { useContext } from "react"
import Scrollbars, { ScrollbarProps } from "react-custom-scrollbars"
import { ColorShift } from "style/color-shifting"
import { Theme } from "style/theme"
import { ThemeContext } from "styled-components"

export const CustomScroller = React.forwardRef<Scrollbars, CustomScrollerProps>(
  (props, ref): JSX.Element => {
    const scrollRef = (ref || React.createRef<Scrollbars>()) as React.RefObject<Scrollbars>
    const theme: Theme = useContext(ThemeContext)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const renderThumb = ({ style, ...thumbProps }: any): JSX.Element => {
      const customThumbStyle: React.CSSProperties = {
        backgroundColor: `${ColorShift.dark(theme.surface.color, theme.surface.textOn)}AA`,
        borderRadius: "4px",
      }
      return <div style={{ ...style, ...customThumbStyle }} {...thumbProps}></div>
    }

    return <Scrollbars autoHide ref={scrollRef} renderThumbVertical={renderThumb} renderThumbHorizontal={renderThumb} {...props} />
  },
)

export type CustomScrollerProps = Omit<ScrollbarProps, "children"> & {
  readonly children: JSX.Element
}

import { ContentGrid } from "components/content-list/content-grid"
import { ContentLoadingSpinner } from "components/loading-spinner"
import { PaddedPageContent } from "components/page"
import { Body1 } from "components/typography"
import { useShows } from "domain/content/hooks"
import React from "react"
import { UseEffectReturn } from "typings"
import { usePageHistory } from "util/hooks"
import { ShowsRoute } from "../../../../constants"

const Shows = (): JSX.Element => {
  const { pushHistory } = usePageHistory()
  const { data, loading, error } = useShows()

  React.useEffect((): UseEffectReturn => {
    pushHistory({
      title: "Shows",
      path: ShowsRoute,
    })
  }, [])

  if (loading) {
    return <ContentLoadingSpinner size="medium" />
  }

  if (error) {
    return (
      <PaddedPageContent>
        <Body1>An error occurred while loading shows</Body1>
      </PaddedPageContent>
    )
  }

  if (data?.shows) {
    return <ContentGrid id="shows_grid" items={data.shows} />
  }

  return (
    <PaddedPageContent>
      <Body1>An error occurred while loading shows</Body1>
    </PaddedPageContent>
  )
}

export default Shows

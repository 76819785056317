import { QueryResult } from "@apollo/react-common"
import { useQuery } from "@apollo/react-hooks"
import { GetPersonDetails, GetPersonDetailsVariables } from "typings/_graphql"
import { MovieDbQueries } from "../graphql"

export const usePersonDetails = (personId: number): QueryResult<GetPersonDetails> =>
  useQuery<GetPersonDetails, GetPersonDetailsVariables>(MovieDbQueries.GetPersonDetails, {
    variables: {
      personId,
    },
  })

import { Slider } from "@material-ui/core"
import { MdIcon, MdIconType } from "components/md-icon"
import { useWatchPlayer } from "domain/watch/watch-player-context"
import React from "react"
import { ThemeProps } from "style/theme"
import styled from "styled-components"

const VolumeControlSlider = styled(Slider)`
  position: absolute !important;
  left: 16px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  width: calc(100% - 55px) !important;

  color: ${({ theme }: ThemeProps): string => theme.surface.textOn} !important;

  .MuiSlider-thumb:hover {
    box-shadow: 0 0 0 8px ${({ theme }: ThemeProps): string => `${theme.surface.textOn}29`} !important;
  }

  .MuiSlider-thumb::after {
    content: initial !important;
  }
`

const VolumeControlContainer = styled.div`
  position: relative;

  background-color: transparent;
  border-radius: 16px;
  cursor: pointer;
  width: 32px;
  height: 28px;
  padding: 0;

  transition: all ease-in 0.25s;

  > ${VolumeControlSlider} {
    opacity: 0;
  }

  :hover {
    background-color: ${({ theme }: ThemeProps): string => `${theme.surface.color}88`};
    width: 120px;
    padding: 4px;

    > ${VolumeControlSlider} {
      opacity: 1;
    }
  }
`

const VolumeControlIcon = styled(MdIcon)`
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);

  color: ${({ theme }: ThemeProps): string => theme.surface.textOn};

  :hover {
    color: white;
  }
`

export const VolumeControl = ({ className }: React.HTMLAttributes<HTMLElement>): JSX.Element => {
  const { volume, setVolume, mute, unmute } = useWatchPlayer()
  const iconClicked = React.useCallback((): void => {
    if (volume > 0) {
      mute()
    } else {
      unmute()
    }
  }, [mute, unmute])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onVolumeChanged: any = React.useCallback(
    (_e: React.ChangeEvent<{}>, newVolume: number): void => {
      setVolume(newVolume)
    },
    [setVolume],
  )

  const icon: MdIconType = volume > 0.6 ? "volume_up" : volume > 0 ? "volume_down" : "volume_mute"

  return React.useMemo(
    () => (
      <VolumeControlContainer className={className}>
        <VolumeControlSlider value={volume} max={1} step={0.01} onChange={onVolumeChanged} tabIndex={-1} />
        <VolumeControlIcon icon={icon} onClick={iconClicked} tabIndex={-1} />
      </VolumeControlContainer>
    ),
    [volume, setVolume, mute, unmute],
  )
}

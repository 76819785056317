import { NavbarContextState } from "domain/navbar/context/navbar-context"
import styled, { css } from "styled-components"

export const fullscreenGridAreas = {
  poster: "poster",
  header: "header",
  details: "details",
  scroller1: "scroller1",
  scroller2: "scroller2",
}

export const FullscreenContainer = styled.div`
  display: grid;
  gap: 24px;
`

const fullscreenOverviewGrid = css<NavbarContextState>`
  @media screen and (max-width: 800px) {
    grid-template-areas:
      ". ${fullscreenGridAreas.poster}"
      ". ${fullscreenGridAreas.header}"
      ". ${fullscreenGridAreas.details}";
    grid-template-columns: ${({ navbarWidth }) => `${navbarWidth}px minmax(auto, calc(100vw - ${navbarWidth * 2}px)) 1fr`};
    justify-content: start;
  }

  @media screen and (min-width: 801px) {
    grid-template-areas:
      ". ${fullscreenGridAreas.poster} ${fullscreenGridAreas.header}"
      ". ${fullscreenGridAreas.poster} ${fullscreenGridAreas.details}";
      grid-template-columns: ${({ navbarWidth }) => `${navbarWidth}px`} auto 1fr;
      grid-template-rows: auto minmax(auto, 1fr);
      justify-content: start;
  }
`

export const FullscreenOverview = styled.div<NavbarContextState>`
  display: grid;
  place-content: stretch;
  ${fullscreenOverviewGrid};
  gap: 24px 16px;
  padding-top: 32px;
`

export const FullscreenScrollers = styled.div`
  display: grid;
  gap: 36px;
  justify-content: stretch;
`

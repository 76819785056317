import { QueryResult } from "@apollo/react-common"
import { useQuery } from "@apollo/react-hooks"
import { GetRecentlyAdded, GetRecentlyAddedVariables } from "typings/_graphql"
import { HomeQueries } from "../graphql"

export const useRecentlyAdded = (): QueryResult<GetRecentlyAdded> =>
  useQuery<GetRecentlyAdded, GetRecentlyAddedVariables>(HomeQueries.GetRecentlyAdded, {
    variables: {
      maxAgeDays: 60,
    },
  })

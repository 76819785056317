import { getType } from "typesafe-actions"
import { NavbarAction, navbarActions } from "./actions"

const initialState: NavbarState = {
  navbarMode: "expanded",
}

export const navbarReducer = (state: NavbarState = initialState, action: NavbarAction): NavbarState => {
  switch (action.type) {
    case getType(navbarActions.toggleNavbarMode): {
      const navbarMode = state.navbarMode === "collapsed" ? "expanded" : "collapsed"

      return {
        ...state,
        navbarMode,
      }
    }

    default: {
      return state
    }
  }
}

export type NavbarMode = "expanded" | "collapsed"

export interface NavbarState {
  readonly navbarMode: NavbarMode
}

import { SubtitlesEndpoint } from "constants/endpoints"
import { ParsedUrlQueryInput, stringify } from "querystring"
import { GetMovieDetailsGetMovieDetails, Nullable } from "typings"
import { detectEnvironment, Environment } from "./environment"
import { removeLeadingSlash } from "./string"

const getApiPort = (): number => {
  switch (detectEnvironment()) {
    case Environment.Production:
      return 8443 // https

    case Environment.Local:
    default:
      return 3001
  }
}

const getHostname = (environment: Environment = detectEnvironment()): string => {
  switch (environment) {
    case Environment.Production:
      return `mattflix.watch`
    case Environment.Local:
    default:
      return location.hostname
  }
}

const getHttpBase = (environment: Environment = detectEnvironment()): string => {
  switch (environment) {
    case Environment.Production:
      return `https://${getHostname(environment)}`
    case Environment.Local:
    default:
      return `https://${getHostname(environment)}`
  }
}

const getWebSocketBase = (environment: Environment = detectEnvironment()): string => {
  switch (environment) {
    case Environment.Production:
      return `wss://${getHostname(environment)}`
    case Environment.Local:
    default:
      return `wss://${getHostname(environment)}`
  }
}

const getBaseApiUrl = (environment: Environment = detectEnvironment()): string => {
  switch (environment) {
    case Environment.Production:
      return ""
    case Environment.Local:
    default:
      return `${getHttpBase()}:${getApiPort()}`
  }
}

export const getRawUrl = (endpoint: string): string => `${getBaseApiUrl()}/${removeLeadingSlash(endpoint)}`

export const getApiUrl = (endpoint: string, args?: Nullable<ParsedUrlQueryInput>, environment?: Environment): string =>
  `${getBaseApiUrl(environment)}/api/${removeLeadingSlash(endpoint)}${args ? `?${stringify(args)}` : ""}`

export const getGraphqlUrl = (): string => `${getBaseApiUrl()}/api/graphql`

export const getGraphqlSubscriptionsUrl = (): string => `${getWebSocketBase()}:${getApiPort()}/subscriptions`

export const getSubtitlesUrl = (movieDetails: GetMovieDetailsGetMovieDetails): string =>
  `${getHttpBase(Environment.Production)}/subtitles/${movieDetails.title}%20(${new Date(movieDetails.released).getFullYear()}).vtt`

export const getMovieSubtitlesUrl = (movieDbId: number): string => getApiUrl(SubtitlesEndpoint, { movieDbId })

export const getTvSubtitlesUrl = (episodeId: number): string => getApiUrl(SubtitlesEndpoint, { episodeId })

import React from "react"
import ReactPlayer, { Config } from "react-player"
import { ThemeProps } from "style/theme"
import styled from "styled-components"
import { Func0, Func1, MovieDbMediaType, UseEffectReturn } from "typings"
import { getMovieSubtitlesUrl, getTvSubtitlesUrl } from "util/url"
import { useCastManager } from "./cast/use-cast-manager"
import { WatchPlayerOverlay } from "./player/watch-player-overlay"
import { useWatchPlayer, WatchPlayerProvider } from "./watch-player-context"
import { WatchPlayerMediaIdentifier } from "./watch-player-media-identifier"
import { getVideoFromReactPlayerRef } from "./watch-player-utils"

const WatchPlayerWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  place-content: stretch;
  background: ${({ theme }: ThemeProps): string => theme.background.color};
  box-shadow: 0 0 6px 6px black;
`

const InnerWatchPlayer = ({ type, movieDbId, episodeId, url, initialTime, onTimeChanged, onVideoEnded }: WatchPlayerProps): JSX.Element => {
  const watchPlayerContext = useWatchPlayer()
  const castManager = useCastManager()
  castManager.setWatchPlayerContext(watchPlayerContext)

  const { reactPlayerRef, wrapperRef, setCurrentTime, play } = watchPlayerContext
  const lastSeenTime = React.useRef<number>(0)

  React.useEffect((): UseEffectReturn => {
    const video = getVideoFromReactPlayerRef(reactPlayerRef)
    if (video) {
      video.oncanplay = async (): Promise<void> => {
        setCurrentTime(initialTime)
        try {
          await play()
        } catch (ex) {
          video.muted = true
          await play()
        }
        video.oncanplay = null
      }

      const interval = setInterval(() => {
        if (lastSeenTime.current !== video.currentTime) {
          lastSeenTime.current = video.currentTime
          onTimeChanged(video.currentTime)
        }
      }, 5000)

      return (): void => {
        video.oncanplay = null
        clearInterval(interval)
      }
    } else {
      console.error("Video element not found")
    }
  }, [url, initialTime])

  React.useEffect((): UseEffectReturn => {
    if (castManager.isAvailable) {
      if (castManager.isConnectedToReceiver()) {
        const video = getVideoFromReactPlayerRef(reactPlayerRef)
        if (video) {
          video.oncanplay = null
        }
        castManager.loadCurrentVideo(initialTime)
      }
    }
  }, [castManager.isAvailable, url])

  React.useEffect((): UseEffectReturn => {
    const video = getVideoFromReactPlayerRef(reactPlayerRef)
    if (video) {
      if (castManager.isAvailable) {
        castManager.setOnPlayerStateChanged((previousState, currentState): void => {
          if (previousState === chrome.cast.media.PlayerState.PLAYING && currentState === chrome.cast.media.PlayerState.IDLE) {
            if (watchPlayerContext.duration - watchPlayerContext.currentTime < 10) {
              onVideoEnded?.()
            } else {
              console.error(`Playing -> Idle, but we aren't done with the episode. Error?`)
            }
          }
        })
      }

      video.onended = (): void => {
        onVideoEnded?.()
      }

      return (): void => {
        video.onended = null
        castManager.setOnPlayerStateChanged(null)
      }
    }
  }, [onVideoEnded, watchPlayerContext.duration, watchPlayerContext.currentTime])

  const subtitlesTrack = {
    kind: "subtitles",
    src: type === MovieDbMediaType.Movie ? getMovieSubtitlesUrl(movieDbId) : getTvSubtitlesUrl(episodeId!),
    srcLang: "en",
    label: "",
    default: true,
  }

  const config: Config | undefined = {
    file: {
      attributes: { crossOrigin: "use-credentials" },
      tracks: [subtitlesTrack],
    },
  }

  return (
    <WatchPlayerWrapper id="watch-wrapper" ref={wrapperRef}>
      <WatchPlayerOverlay />
      <ReactPlayer ref={reactPlayerRef} url={url} width="100%" height="100%" config={config} />
    </WatchPlayerWrapper>
  )
}

export const WatchPlayer = (props: WatchPlayerProps): JSX.Element => {
  const reactPlayerRef = React.useRef<ReactPlayer>(null)
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  const overlayRef = React.useRef<HTMLDivElement>(null)

  return (
    <WatchPlayerProvider
      type={props.type}
      movieDbId={props.movieDbId}
      episodeId={props.episodeId}
      watchToken={props.watchToken}
      reactPlayerRef={reactPlayerRef}
      wrapperRef={wrapperRef}
      overlayRef={overlayRef}
    >
      <InnerWatchPlayer {...props} />
    </WatchPlayerProvider>
  )
}

export interface WatchPlayerProps extends WatchPlayerMediaIdentifier {
  readonly url: string
  readonly watchToken: string
  readonly initialTime: number
  readonly onTimeChanged: Func1<number, void>
  readonly onVideoEnded?: Func0<void>
}

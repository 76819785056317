import { QueryResult } from "@apollo/react-common"
import { useQuery } from "@apollo/react-hooks"
import { GetMovies, GetMoviesVariables, MovieSearchInputType } from "typings/_graphql"
import { MovieDbQueries } from "../graphql"

export const useMovies = (searchOptions: MovieSearchInputType): QueryResult<GetMovies, GetMoviesVariables> =>
  useQuery<GetMovies, GetMoviesVariables>(MovieDbQueries.GetMovies, {
    variables: {
      searchOptions,
    },
  })

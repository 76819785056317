import { getType } from "typesafe-actions"
import { UserAction, userActions } from "./actions"

const initialState: UserState = {
  username: "",
}

export const userReducer = (state: UserState = initialState, action: UserAction): UserState => {
  switch (action.type) {
    case getType(userActions.setUsername): {
      return {
        ...state,
        username: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export interface UserState {
  readonly username: string
}

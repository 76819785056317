import { routerMiddleware } from "connected-react-router"
import { applyMiddleware, createStore, Store } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import { RootState } from "typings/root-state"
import { detectEnvironment, Environment } from "util/environment"
import { getHistory } from "./history"
import { createRootReducer } from "./root-reducer"

export const configureStore = (): Store<RootState> => {
  const middlewares = [routerMiddleware(getHistory())]
  const appliedMiddlewares = applyMiddleware(...middlewares)
  const composed =
    window.devToolsExtension && detectEnvironment() === Environment.Local ? composeWithDevTools({ latency: 0 })(appliedMiddlewares) : appliedMiddlewares

  const store = createStore(createRootReducer(), composed)

  return store
}


    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ContentFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ContentBaseResultType"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"posterPath"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"backdropPath"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"availabilityStatus"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":121}};
    doc.loc.source = {"body":"fragment ContentFields on ContentBaseResultType {\n  id\n  type\n  title\n  posterPath\n  backdropPath\n  availabilityStatus\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    

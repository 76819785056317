import { ContentGrid } from "components/content-list/content-grid"
import { ContentLoadingSpinner } from "components/loading-spinner"
import { PaddedPageContent } from "components/page"
import { Body1 } from "components/typography"
import { useContentForCast, usePersonDetails } from "domain/content/hooks"
import React from "react"
import { Redirect, useParams } from "react-router"
import { UseEffectReturn } from "typings"
import { usePageHistory } from "util/hooks"
import { HomeRoute, PersonRoute } from "../../../../constants"

const Person = (): JSX.Element => {
  const { personId } = useParams<PersonRouteParams>()
  if (personId == null) {
    return <Redirect to={HomeRoute} />
  }

  const { data, loading, error } = useContentForCast(+personId)
  const { data: personDetailsData } = usePersonDetails(+personId)
  const { pushHistory } = usePageHistory()

  React.useEffect((): UseEffectReturn => {
    if (personDetailsData?.getPersonDetails) {
      pushHistory({
        title: `Search Results for '${personDetailsData.getPersonDetails.name}'`,
        path: PersonRoute(personId),
      })
    }
  }, [personDetailsData?.getPersonDetails])

  if (error) {
    return (
      <PaddedPageContent>
        <Body1>An error occurred while loading person details</Body1>
      </PaddedPageContent>
    )
  }

  if (loading) {
    return <ContentLoadingSpinner size="medium" />
  }

  if (data?.contentForCast) {
    return (
      <ContentGrid
        id={`person_grid_${personId}`}
        header={`Search Results${personDetailsData?.getPersonDetails ? ` for ${personDetailsData.getPersonDetails.name}` : ""}`}
        items={data.contentForCast}
      />
    )
  }

  return (
    <PaddedPageContent>
      <Body1>An error occurred while loading person details</Body1>
    </PaddedPageContent>
  )
}

export default Person

interface PersonRouteParams {
  readonly personId: string
}

import { FieldValidator } from "final-form"
import { RegisterFormModel } from "../components/register"

const validPasswordPattern = String.raw`^[a-zA-Z0-9!@#\$%\^&]{6,}$`

export const validatePassword: FieldValidator<string> = (value: string, allValues: object): string | undefined => {
  const formModel = allValues as RegisterFormModel
  const passwordsMatch = formModel.password === formModel.confirmPassword
  if (!passwordsMatch) {
    return "Passwords do not match"
  }

  const matchesPattern = new RegExp(validPasswordPattern).test(value)
  if (!matchesPattern) {
    return "Password must be at least 6 characters"
  }

  return undefined
}

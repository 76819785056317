import { ThemeProps } from "style/theme"
import styled from "styled-components"

export const Separator = styled.span<SeparatorProps>`
  background-color: ${({ theme }: ThemeProps): string => theme.surface.color};
  width: ${({ radius }: SeparatorProps): string => `${radius}px`};
  height: ${({ radius }: SeparatorProps): string => `${radius}px`};
  border-radius: 50%;
`

interface SeparatorProps {
  readonly radius: number
}

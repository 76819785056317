import { AuthBoundary } from "auth-provider"
import { BackgroundSlideshow } from "components/background-slideshow"
import { LoadingSpinner } from "components/loading-spinner"
import { ModalPortal } from "components/modal/modal"
import { useBackdropUrls } from "domain/content/hooks"
import { AuthedRouter } from "domain/router/components/authed-router"
import { unauthedRoutes } from "domain/router/components/unauthed-routes"
import React from "react"
import { Switch } from "react-router"
import { ThemeProps } from "style/theme"
import styled from "styled-components"

const SuspenseLoadingSpinner = styled(LoadingSpinner)`
  margin: 8rem auto;
`

const StyledApp = styled.div`
  color: ${({ theme }: ThemeProps): string => theme.surface.textOn};
  height: 100%;
  border-radius: 0 !important;
  position: relative;
  z-index: 2;
`

export const App = (): JSX.Element => {
  const { backdropUrls } = useBackdropUrls()

  return (
    <>
      <BackgroundSlideshow backgroundImages={backdropUrls} />
      <ModalPortal />
      <StyledApp>
        <React.Suspense fallback={<SuspenseLoadingSpinner size="medium" />}>
          <Switch>
            {unauthedRoutes}
            <AuthBoundary>
              <AuthedRouter />
            </AuthBoundary>
          </Switch>
        </React.Suspense>
      </StyledApp>
    </>
  )
}

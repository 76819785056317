import React, { CSSProperties } from "react"
import Select, { ActionMeta, InputActionMeta, OptionsType, Styles, ValueType } from "react-select"
import { ColorShift } from "style/color-shifting"
import { Theme } from "style/theme"
import { ThemeContext } from "styled-components"

export interface OptionType {
  readonly value: string
  readonly label: string
}

const SelectStyles = (rootStyle: CSSProperties, theme: Theme): Partial<Styles> => ({
  clearIndicator: (provided: CSSProperties) => ({
    ...provided,
    color: theme.surface.color,

    ":hover": {
      color: ColorShift.light(theme.surface.color, theme.surface.textOn),
    },
  }),
  control: () => ({
    ...rootStyle,
    display: "grid",
    gridAutoFlow: "column",
    gridGap: "4px",
    gridTemplateColumns: "1fr auto",
    background: "transparent",
    border: `1px solid ${ColorShift.light(theme.surface.color, theme.surface.textOn)}`,
    borderColor: ColorShift.light(theme.surface.color, theme.surface.textOn),
    borderRadius: "4px",
    boxShadow: "none",
    height: "100%",

    ":hover": {
      borderColor: theme.surface.textOn,
    },
  }),
  dropdownIndicator: (provided: CSSProperties) => ({
    ...provided,
    color: ColorShift.medium(theme.surface.color, theme.surface.textOn),
    padding: "4px",

    ":hover": {
      color: theme.surface.textOn,
    },
  }),
  menu: (provided: CSSProperties) => ({
    ...provided,
    ...(rootStyle || {}),
    background: theme.surface.color,
    border: `1px solid ${ColorShift.medium(theme.surface.color, theme.surface.textOn)}`,
    zIndex: 999,
  }),
  singleValue: (provided: CSSProperties) => ({
    ...provided,
    background: "transparent",
    color: theme.surface.textOn,
    fontSize: "0.9em",
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: (provided: CSSProperties, { isFocused, isSelected }: any) => ({
    ...provided,
    background: isFocused || isSelected ? ColorShift.medium(theme.surface.color, theme.surface.textOn) : theme.surface.color,
    color: theme.surface.textOn,
    fontSize: "0.9rem",
    height: "fit-content",
    padding: "4px",

    "&:hover": {
      color: ColorShift.light(theme.surface.color, theme.surface.textOn),
    },
  }),
})

export const SingleSelect = <T extends OptionType>({ className, style, placeholder, value, parseOption, onChange, options }: SelectProps<T>): JSX.Element => {
  const [inputValue, setInputValue] = React.useState<string>("")
  const theme: Theme = React.useContext(ThemeContext)

  const onInputChange = (newInputValue: string, { action }: InputActionMeta): void => {
    switch (action) {
      case "input-change": {
        setInputValue(newInputValue)
        break
      }
      case "menu-close": {
        setInputValue("")
        break
      }
    }
  }

  return (
    <Select
      className={className}
      isSearchable={false}
      styles={SelectStyles(style || {}, theme)}
      placeholder={placeholder}
      inputValue={inputValue}
      value={value}
      getOptionLabel={parseOption}
      onInputChange={onInputChange}
      onChange={onChange}
      options={options}
    />
  )
}

export interface SelectProps<T extends OptionType> {
  readonly className?: string
  readonly style?: React.CSSProperties
  readonly options: OptionsType<T>
  readonly value: ValueType<T>
  readonly placeholder: string
  readonly parseOption?: (option: T) => string
  readonly onChange: (value: ValueType<T>, action: ActionMeta) => void
}

import { QueryResult } from "@apollo/react-common"
import { useQuery } from "@apollo/react-hooks"
import { GetContentForCast, GetContentForCastVariables } from "typings"
import { MovieDbQueries } from "../graphql"

export const useContentForCast = (castId: number): QueryResult<GetContentForCast> =>
  useQuery<GetContentForCast, GetContentForCastVariables>(MovieDbQueries.GetContentForCast, {
    variables: {
      castId,
    },
  })

import React from "react"
import { Field, FieldProps, FieldRenderProps } from "react-final-form"
import { Input } from "./input"

export const FieldInput = ({ gridArea, ...props }: FieldInputProps): JSX.Element => (
  <Field {...props}>
    {({ input, meta: { visited, error } }: FieldRenderProps<string>): JSX.Element => {
      return <Input style={{ gridArea }} {...props} {...input} error={visited && error} variant="outlined" />
    }}
  </Field>
)

interface FieldInputProps extends FieldProps<string, FieldRenderProps<string, HTMLElement>, HTMLInputElement> {
  readonly gridArea?: string
}

import { CustomScroller } from "components/custom-scroller"
import { NavigationArrows } from "components/navigation/navigation-arrows"
import { NavigationContainer, navigationGridAreas, NavigationHeader, NavigationScroller } from "components/navigation/navigation-container"
import { useNavbar } from "domain/navbar/context/navbar-context"
import React from "react"
import styled from "styled-components"
import { ContentBaseType, Func1, UseEffectReturn } from "typings"
import { ContentNavigationProvider, useContentNavigation } from "util/hooks"
import { useInfiniteScrolling } from "util/hooks/use-infinite-scrolling"
import { ContentListItem } from "./content-list-item"

const ContentListScrollerWrapper = styled.div.attrs((props: ContentListScrollerWrapperProps) => ({
  style: {
    height: `${props.itemHeight}px`,
  },
}))<ContentListScrollerWrapperProps>`
  grid-area: ${navigationGridAreas.scroller};
  overflow-x: hidden;
`

// eslint-disable-next-line functional/prefer-readonly-type
const scrollRestorationMap: Map<string, number> = new Map([])

const InnerContentList = ({ id, className, header, items, onPage, alwaysFullBrightness = false }: ContentListProps): JSX.Element => {
  const {
    scrollerRef,
    customScrollerRef,
    scrollerClientWidth,
    scrollerClientHeight,
    scrollerScrollWidth,
    onCustomScrollerScrolled,
    onCustomScrollerScrollStop,
  } = useContentNavigation()
  const navbar = useNavbar()

  const { page } = useInfiniteScrolling(customScrollerRef, {
    direction: "horizontal",
  })

  React.useEffect((): UseEffectReturn => {
    onPage?.(page)
  }, [page])

  const mapItem = (item: ContentBaseType): JSX.Element => <ContentListItem key={item.id} item={item} alwaysFullBrightness={alwaysFullBrightness} />

  const onScroll = (e: React.UIEvent): void => {
    onCustomScrollerScrolled(e)
    scrollRestorationMap.set(id, e.currentTarget.scrollLeft)
  }

  React.useEffect((): UseEffectReturn => {
    if (scrollRestorationMap.has(id)) {
      setTimeout(() => customScrollerRef.current?.scrollLeft(scrollRestorationMap.get(id) ?? 0), 0)
    }
  }, [])

  return (
    <NavigationContainer className={className}>
      <NavigationHeader {...navbar}>{header}</NavigationHeader>
      {!!scrollerClientWidth && !!scrollerScrollWidth && <NavigationArrows />}
      <ContentListScrollerWrapper itemHeight={scrollerClientHeight}>
        <CustomScroller ref={customScrollerRef} onScroll={onScroll} onScrollStop={onCustomScrollerScrollStop}>
          <NavigationScroller ref={scrollerRef} {...navbar}>
            {items.map(mapItem)}
          </NavigationScroller>
        </CustomScroller>
      </ContentListScrollerWrapper>
    </NavigationContainer>
  )
}

export const ContentList = (props: ContentListProps): JSX.Element => (
  <ContentNavigationProvider>
    <InnerContentList {...props} />
  </ContentNavigationProvider>
)

export interface ContentListProps {
  readonly id: string
  readonly className?: string
  readonly header: string
  readonly items: ReadonlyArray<ContentBaseType>
  readonly onPage?: Func1<number, void>
  readonly alwaysFullBrightness?: boolean
}

interface ContentListScrollerWrapperProps {
  readonly itemHeight: number
}

import { MdIcon } from "components/md-icon"
import { routerActions } from "connected-react-router"
import React from "react"
import { Field, Form, FormRenderProps, useForm } from "react-final-form"
import { useLocation } from "react-router"
import { ThemeProps } from "style/theme"
import styled from "styled-components"
import { UseEffectReturn } from "typings"
import { useFocus } from "util/hooks"
import { SearchRoute } from "../../../constants"
import { useStore } from "react-redux"

const searchFormFields: { [Key in keyof SearchFormModel]: Key } = {
  query: "query",
}

const transitionDelay = "300ms"

const moviePlaceholders = ["Search Movies...", "Search TV Shows...", "Search Actors..."]

const StyledSearchBar = styled.form<StyledSearchBarProps>`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  gap: 4px;
  align-items: center;
  cursor: text;

  border-radius: 4px;
  background-color: ${({ theme, isFocused }: ThemeProps & StyledSearchBarProps): string => (isFocused ? theme.surface.textOn : theme.surface.color)};
  color: ${({ theme, isFocused }: ThemeProps & StyledSearchBarProps): string => (isFocused ? theme.surface.color : theme.surface.textOn)};
  width: ${({ isFocused }: StyledSearchBarProps): string => (isFocused ? "300px" : "200px")};
  transition: background-color ${transitionDelay}, color ${transitionDelay}, width ${transitionDelay};

  padding: 4px;
  box-sizing: border-box;

  @media screen and (max-width: 550px) {
    width: 200px;
  }
`

const StyledSearchInput = styled.input`
  background: inherit;
  color: inherit;
`

const SearchInnerForm = ({ handleSubmit, form: _form, values: _values, ...props }: SearchInnerFormProps): JSX.Element => {
  const { onWrapperFocus, onWrapperBlur, isExpanded } = useFocus()
  const { pathname } = useLocation()
  const { reset } = useForm<SearchFormModel>()
  const placeholderIndex = React.useRef<number>(-1)
  const [placeholder, setPlaceholder] = React.useState<string>("")

  const onSubmit = (e: React.SyntheticEvent<HTMLFormElement, Event> | undefined): void => {
    e?.preventDefault()
    handleSubmit(e)
    reset()
  }

  const updatePlaceholder = (): void => {
    const arr = moviePlaceholders
    setPlaceholder(arr[(placeholderIndex.current = (placeholderIndex.current + 1) % arr.length)])
  }

  React.useEffect((): UseEffectReturn => {
    const interval = setInterval(updatePlaceholder, 10000)
    updatePlaceholder()

    return (): void => {
      clearInterval(interval)
    }
  }, [pathname])

  return (
    <StyledSearchBar onSubmit={onSubmit} {...props} isFocused={isExpanded} autoComplete="off" autoCapitalize="off">
      <MdIcon icon="search" />
      <Field name={searchFormFields.query}>
        {({ input }): JSX.Element => <StyledSearchInput {...input} onFocus={onWrapperFocus} onBlur={onWrapperBlur} placeholder={placeholder} />}
      </Field>
    </StyledSearchBar>
  )
}

export const SearchBar = (): JSX.Element => {
  const store = useStore()

  const onSearchSubmit = ({ query }: SearchFormModel): void => {
    const route = SearchRoute(query)
    store.dispatch(routerActions.push(route))
  }

  return <Form onSubmit={onSearchSubmit}>{(renderProps): JSX.Element => <SearchInnerForm {...renderProps} />}</Form>
}

interface StyledSearchBarProps {
  readonly isFocused: boolean
}

interface SearchFormModel {
  readonly query: string
}

type SearchInnerFormProps = FormRenderProps<SearchFormModel>

import { ButtonProps, Typography } from "@material-ui/core"
import { Button } from "components/button"
import { Card } from "components/card"
import { FieldInput } from "components/field-input"
import { Logo } from "components/logo"
import { Body1 } from "components/typography"
import { routerActions } from "connected-react-router"
import { useHttpClient } from "domain/network/api-client"
import { login } from "domain/network/requests"
import React from "react"
import { Form } from "react-final-form"
import { useStore } from "react-redux"
import { Link } from "react-router-dom"
import { ThemeProps } from "style/theme"
import styled from "styled-components"
import { LoginRequest, Nullable, RootState } from "typings"
import { NumberParam, StringParam, useQueryParam } from "use-query-params"
import { ForgotRoute, HomeRoute, RegisterRoute } from "../../../constants"
import { userActions } from "domain/user/store/actions"

const LoginPage = styled.div`
  padding-top: 50px;
`

const StyledLogin = styled(Card)`
  background-color: ${({ theme }: ThemeProps): string => `${theme.surface.color}99`} !important;
  display: grid;
  justify-content: center;
  align-content: start;
  width: fit-content;
  height: fit-content;
  margin: 2rem auto;
  padding: 2rem;
  padding-bottom: 0.5rem;
`

const gridAreas = {
  error: "error",
  username: "username",
  password: "password",
  login: "login",
  register: "register",
}

const LoginLogo = styled(Logo)`
  width: 225px;
  margin: 0 auto;
`

const StyledLoginForm = styled.form`
  display: grid;
  grid-template-areas:
    "${gridAreas.error} ${gridAreas.error}"
    "${gridAreas.username} ${gridAreas.username}"
    "${gridAreas.password} ${gridAreas.password}"
    "${gridAreas.login} ${gridAreas.register}";
  gap: 15px;
  justify-content: stretch;
  padding: 0 1rem 1.5rem 1rem;
  width: 20rem;
`

const LoginError = styled(Typography)`
  grid-area: ${gridAreas.error};
  justify-self: center;
  color: ${({ theme }: ThemeProps): string => theme.primary.color};
  padding-top: 8px;
`

const LoginButton = styled(Button).attrs<ButtonProps>({ variant: "contained" })`
  grid-area: ${gridAreas.login};
  width: 125px;
`

const RegisterButton = styled(Button).attrs<ButtonProps>({ variant: "contained" })`
  grid-area: ${gridAreas.register};
  width: 125px;
`
const ForgotLink = styled(Link)`
  color: ${({ theme }: ThemeProps): string => theme.primary.color} !important;
  cursor: pointer;
  font-size: 0.85rem !important;
  justify-self: center;
`

const ActivationWarning = styled(Body1)`
  margin: 0 auto;
  width: fit-content;
  padding: 0 2rem;
`

const Login = (): JSX.Element => {
  const store = useStore<RootState>()
  const httpClient = useHttpClient()
  const [loginError, setLoginError] = React.useState<Nullable<string>>(null)
  const [next] = useQueryParam("next", StringParam)
  const [p] = useQueryParam("p", NumberParam)
  const [f] = useQueryParam("f", NumberParam)

  const onSubmit = async (request: LoginRequest): Promise<void> => {
    const { username, success, error } = await login(httpClient, request)
    if (error) {
      setLoginError(error)
    } else if (success) {
      store.dispatch(userActions.setUsername(username))
      store.dispatch(routerActions.push(next || HomeRoute))
    }
  }

  const onRegisterClicked = (): void => {
    store.dispatch(routerActions.push(RegisterRoute))
  }

  return (
    <LoginPage>
      <StyledLogin elevation={8}>
        <LoginLogo />
        <Form onSubmit={onSubmit}>
          {(props): React.ReactNode => (
            <StyledLoginForm onSubmit={props.handleSubmit}>
              <LoginError>{loginError}</LoginError>
              <FieldInput name="username" label="Username" gridArea={gridAreas.username} />
              <FieldInput name="password" label="Password" gridArea={gridAreas.password} type="password" />
              <LoginButton type="submit">Login</LoginButton>
              <RegisterButton type="button" onClick={onRegisterClicked}>
                Register
              </RegisterButton>
            </StyledLoginForm>
          )}
        </Form>
        <ForgotLink to={ForgotRoute}>Forgot your password?</ForgotLink>
      </StyledLogin>
      {(p === 1 && <ActivationWarning>Your account requires activation. Please try logging in later.</ActivationWarning>) ||
        (f === 1 && <ActivationWarning>Reach out to me to let me know you need your request approved.</ActivationWarning>)}
    </LoginPage>
  )
}

export default Login

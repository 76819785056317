import { getVideoFromReactPlayerRef } from "domain/watch/watch-player-utils"
import React from "react"
import ReactPlayer from "react-player"
import { Func0, Func1, UseEffectReturn } from "typings"

export const useVideoPausePlay = (reactPlayerRef: React.RefObject<ReactPlayer>): UseVideoPausePlay => {
  const [isPaused, setIsPaused] = React.useState<boolean>(true)

  React.useEffect((): UseEffectReturn => {
    const video = getVideoFromReactPlayerRef(reactPlayerRef)
    if (video) {
      setIsPaused(video.paused)
      video.onpause = () => setIsPaused(true)
      video.onplay = () => setIsPaused(false)

      return (): void => {
        video.onpause = null
        video.onplay = null
      }
    } else {
      console.error("No video found")
    }
  }, [])

  const pause = React.useCallback((): void => {
    const video = getVideoFromReactPlayerRef(reactPlayerRef)
    if (video) {
      video.pause()
      setIsPaused(true)
    }
  }, [setIsPaused])

  const play = React.useCallback(async (): Promise<void> => {
    const video = getVideoFromReactPlayerRef(reactPlayerRef)
    if (video) {
      await video.play()
      setIsPaused(false)
    }
  }, [setIsPaused])

  return {
    isPaused,
    setIsPaused,
    pause,
    play,
  }
}

export interface UseVideoPausePlay {
  readonly isPaused: boolean
  readonly setIsPaused: Func1<boolean, void>
  readonly pause: Func0<void>
  readonly play: Func0<Promise<void>>
}

import { ButtonProps } from "@material-ui/core"
import { Button } from "components/button"
import { MdIcon, MdIconProps } from "components/md-icon"
import * as React from "react"
import { ColorShift } from "style/color-shifting"
import { ThemeProps } from "style/theme"
import styled from "styled-components"
import { useDropdown } from "./dropdown"

const DropdownButtonContent = styled.div`
  display: grid;
  gap: 8px;
  grid-auto-flow: column;
`

export const DropdownButton = ({ buttonType, children, ...htmlProps }: DropdownButtonProps): JSX.Element => {
  const { onExpandElementClick } = useDropdown()

  return (
    <Button {...htmlProps} onClick={onExpandElementClick} variant={buttonType || "contained"}>
      <DropdownButtonContent>{children}</DropdownButtonContent>
    </Button>
  )
}

interface DropdownButtonProps extends ButtonProps {
  readonly buttonType?: ButtonProps["variant"]
}

export interface IconDropdownButtonProps extends MdIconProps {}

export interface IconButtonProps {
  readonly disabled?: boolean
}

const IconButton = styled(MdIcon)<IconButtonProps>`
  cursor: ${({ disabled }: IconButtonProps): string => (disabled ? "not-allowed" : "pointer")};
  color: ${({ theme, disabled }: ThemeProps & IconButtonProps): string =>
    disabled ? ColorShift.medium(theme.surface.textOn, theme.surface.color) : theme.surface.textOn};

  :hover {
    color: ${({ theme, disabled }: ThemeProps & IconButtonProps): string =>
      disabled ? ColorShift.medium(theme.surface.textOn, theme.surface.color) : "white"};
  }
`

export const IconDropdownButton = (props: IconDropdownButtonProps): JSX.Element => {
  const { onExpandElementClick } = useDropdown()

  return <IconButton {...props} onClick={onExpandElementClick} />
}

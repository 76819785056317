import { logout } from "domain/network/requests/logout"
import React from "react"
import { Redirect } from "react-router"
import { UseEffectReturn } from "typings"
import { LoginRoute } from "../../../constants"
import { useHttpClient } from "domain/network/api-client"
import { useQueryParam, StringParam } from "use-query-params"

const Logout = (): JSX.Element => {
  const httpClient = useHttpClient()
  const [result, setResult] = React.useState<boolean>(false)
  const [next] = useQueryParam("next", StringParam)

  React.useEffect((): UseEffectReturn => {
    const doLogout = async (): Promise<void> => {
      await logout(httpClient)
      setResult(true)
    }

    doLogout()
  }, [])

  return result ? <></> : <Redirect to={`${LoginRoute}${next ? `?next=${next}` : ""}`} />
}

export default Logout

import { CheckSessionEndpoint } from "constants/endpoints"
import { LoginErrorResponse } from "typings"
import { isAxiosException, HttpClient } from "../api-client"

export const checkSession = async (httpClient: HttpClient): Promise<CheckSessionResult> => {
  /* eslint-disable functional/no-let */
  let statusCode: number = 200
  /* eslint-enable functional/no-let */

  // eslint-disable-next-line functional/no-let
  let username: string = ""
  try {
    const response = await httpClient.get<CheckSessionApiResponse>(CheckSessionEndpoint, null, false)
    username = response.data.username
    statusCode = response.status
  } catch (ex) {
    if (isAxiosException<LoginErrorResponse>(ex)) {
      statusCode = ex.response?.status ?? 400
    }
  }

  return {
    statusCode,
    username,
  }
}

interface CheckSessionApiResponse {
  readonly userId: number
  readonly username: string
}

interface CheckSessionResult {
  readonly statusCode: number
  readonly username: string
}

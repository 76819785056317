import { ContentLoadingSpinner } from "components/loading-spinner"
import { FullscreenRouteParams } from "domain/content/components/fullscreen/fullscreen-route-params"
import { useMovieDetails } from "domain/content/hooks"
import { NavbarContextState, useNavbar } from "domain/navbar/context/navbar-context"
import React from "react"
import { useParams } from "react-router"
import styled from "styled-components"
import { MovieDbMediaType } from "typings"
import { getApiUrl } from "util/url"
import { WatchEndpoint } from "../../../constants"
import { useWatchToken } from "../hooks/use-watch-token"
import { WatchPlayer } from "../watch-player"
import { useGetMoviePlaybackTime } from "./hooks/use-get-movie-playback-time"
import { useSetMoviePlaybackTime } from "./hooks/use-set-movie-playback-time"

const MoviePlayerContainer = styled.div<NavbarContextState>`
  display: grid;
  grid-template-rows: minmax(0, 1fr);
`

export const WatchMoviePlayer = (): JSX.Element => {
  const { movieDbId } = useParams<FullscreenRouteParams>()
  const { data, loading } = useGetMoviePlaybackTime(+movieDbId)
  const [setMoviePlaybackTime] = useSetMoviePlaybackTime()
  const { data: watchToken } = useWatchToken()
  const navbar = useNavbar()
  const { data: movieDetails } = useMovieDetails(+movieDbId, "cache-first")

  const args = {
    type: MovieDbMediaType.Movie,
    movieDbId,
    token: watchToken?.watchToken,
  }

  const url = getApiUrl(WatchEndpoint, args)

  const onTimeChanged = (playbackTime: number): void => {
    setMoviePlaybackTime({
      variables: {
        movieDbId: +movieDbId,
        playbackTime,
      },
    })
  }

  return (
    <MoviePlayerContainer {...navbar}>
      {!watchToken || !movieDetails || loading ? (
        <ContentLoadingSpinner />
      ) : (
        <WatchPlayer
          type={MovieDbMediaType.Movie}
          movieDbId={+movieDbId}
          url={url}
          watchToken={watchToken.watchToken}
          initialTime={data?.getMoviePlaybackTime ?? 0}
          onTimeChanged={onTimeChanged}
        />
      )}
    </MoviePlayerContainer>
  )
}

import { getPublicBackdrops } from "domain/network/requests/get-public-backdrops"
import { useAsync } from "react-async"
import { useHttpClient } from "domain/network/api-client"
import { useMemo } from "react"

export const useBackdropUrls = (): UseBackdropUrls => {
  const httpClient = useHttpClient()

  const getPublicBackdropsFn = useMemo(() => () => getPublicBackdrops(httpClient), [])

  const { data: backdropUrls } = useAsync({
    promiseFn: getPublicBackdropsFn,
  })

  const result: UseBackdropUrls = {
    backdropUrls: backdropUrls ?? [],
  }

  return result
}

export interface UseBackdropUrls {
  readonly backdropUrls: ReadonlyArray<string>
}

import { titled } from "components/titled"
import Explore from "domain/content/components/explore/explore"
import Fullscreen from "domain/content/components/fullscreen/fullscreen"
import Movies from "domain/content/components/movies/movies"
import Person from "domain/content/components/person/person"
import Search from "domain/content/components/search/search"
import Shows from "domain/content/components/shows/shows"
import Trending from "domain/content/components/trending/trending"
import { Header } from "domain/header/components/header"
import Home from "domain/home/components/home"
import { Navbar } from "domain/navbar/components/navbar"
import { WatchMoviePlayer } from "domain/watch/movie/watch-movie-player"
import { WatchShowPlayer } from "domain/watch/show/watch-show-player"
import React from "react"
import { Redirect, Route, Switch } from "react-router"
import styled from "styled-components"
import {
  ExploreRoute,
  FullscreenRoutePath,
  HomeRoute,
  MoviesRoute,
  PersonRoutePath,
  SearchRouteBase,
  ShowsRoute,
  TrendingRoute,
  WatchMoviePath,
  WatchShowPath,
  LinkRoute,
} from "../../../constants"
import Link from "domain/link/components/link"

// const MoviesAsync = React.lazy(() => import(/* webpackChunkname: "movies" */ "domain/content/components/movies/movies"))
// const ShowsAsync = React.lazy(() => import(/* webpackChunkname: "shows" */ "domain/content/components/shows/shows"))
// const ExploreAsync = React.lazy(() => import(/* webpackChunkname: "explore" */ "domain/content/components/explore/explore"))
// const HomeAsync = React.lazy(() => import(/* webpackChunkname: "home" */ "domain/home/components/home"))

const gridAreas = {
  header: "header",
  navbar: "navbar",
  content: "content",
}

const AuthedAppContainer = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  grid-template-rows: 50px minmax(0, 1fr);
  grid-template-areas:
    "${gridAreas.header} ${gridAreas.header}"
    "${gridAreas.content} ${gridAreas.content}";
  height: 100%;
`

const HeaderArea = styled(Header)`
  grid-area: ${gridAreas.header};
`

const NavbarArea = styled(Navbar)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
`

const AppArea = styled.div`
  position: relative;

  grid-area: ${gridAreas.content};
  display: grid;
  place-content: stretch;
  grid-template-rows: minmax(0, 1fr);
`

const FullscreenContentContainer = styled.div`
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  height: 100%;
`

export const AuthedRouter = (): JSX.Element => (
  <Switch>
    <Route
      path={LinkRoute}
      render={() =>
        titled(
          "Link",
          <FullscreenContentContainer>
            <Link />
          </FullscreenContentContainer>,
        )
      }
    />
    <Route
      path={WatchMoviePath}
      render={() => (
        <FullscreenContentContainer>
          <WatchMoviePlayer />
        </FullscreenContentContainer>
      )}
    />
    <Route
      path={WatchShowPath}
      render={() => (
        <FullscreenContentContainer>
          <WatchShowPlayer />
        </FullscreenContentContainer>
      )}
    />

    <AuthedAppContainer>
      <HeaderArea />
      <AppArea>
        <>
          <NavbarArea />
          <Switch>
            <Route path={FullscreenRoutePath} render={() => <Fullscreen />} />
            <Route path={PersonRoutePath} render={() => titled("Person", <Person />)} />
            <Route path={SearchRouteBase} render={() => titled("Search", <Search />)} />
            <Route path={MoviesRoute} render={() => titled("Movies", <Movies />)} />
            <Route path={ShowsRoute} render={() => titled("Shows", <Shows />)} />
            <Route path={ExploreRoute} render={() => titled("Explore", <Explore />)} />
            <Route path={TrendingRoute} render={() => titled("Trending", <Trending />)} />
            <Route path={HomeRoute} exact render={() => titled("Home", <Home />)} />
            <Redirect to={HomeRoute} />
          </Switch>
        </>
      </AppArea>
    </AuthedAppContainer>
  </Switch>
)

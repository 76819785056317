import { Logo, LogoIcon } from "components/logo"
import { routerActions } from "connected-react-router"
import { SearchBar } from "domain/search/components/searchbar"
import React from "react"
import { useStore } from "react-redux"
import { directionalBoxShadow } from "style/directional-dropshadow"
import { ThemeProps } from "style/theme"
import styled from "styled-components"
import { RootState } from "typings"
import { HomeRoute, Levels } from "../../../constants"
import { Button } from "@material-ui/core"
import { MdIcon } from "components/md-icon"
import { useCookies } from "react-cookie"

const StyledLogo = styled(Logo)`
  cursor: pointer;
  padding: 0 8px;
  width: 150px;
  height: 100%;
`

const StyledLogoIcon = styled(LogoIcon)`
  cursor: pointer;
  padding: 0 8px;
  height: 100%;
`

const BetaButton = styled(Button)`
  color: ${({ theme }: ThemeProps): string => theme.primary.color} !important;

  .MuiButton-label {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`

const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  gap: 8px;
  padding: 8px;
  align-items: center;
  background: ${({ theme }: ThemeProps): string => `${theme.background.color}77`};
  z-index: ${Levels.ElevatedContent};
  ${directionalBoxShadow("bottom", "large")}

  ${StyledLogoIcon} {
    display: none;
  }

  @media screen and (max-width: 550px) {
    ${StyledLogoIcon} {
      display: initial;
    }
    ${StyledLogo} {
      display: none;
    }
  }
`

// const StyledCollapseButton = styled(MdIcon).attrs({ icon: "menu" })`
//   color: ${({ theme }: ThemeProps): string => theme.surface.textOn};
//   cursor: pointer;
//   padding: 4px;

//   :hover {
//     color: white;
//   }
// `

export const Header = (props: HeaderProps): JSX.Element => {
  const store = useStore<RootState>()
  const setCookie = useCookies(["beta"])[1]
  // const onCollapseClicked = (): void => {
  //   store.dispatch(navbarActions.toggleNavbarMode())
  // }

  const onLogoClicked = (): void => {
    store.dispatch(routerActions.push(HomeRoute))
  }

  const onBetaClicked = (): void => {
    setCookie("beta", true, { domain: "mattflix.watch" })
    window.location.href = "https://beta.mattflix.watch"
  }

  return (
    <StyledHeader {...props}>
      {/* <StyledCollapseButton onClick={onCollapseClicked} /> */}
      <StyledLogo onClick={onLogoClicked} />
      <StyledLogoIcon onClick={onLogoClicked} />
      <SearchBar />
      <span />

      <BetaButton variant="text" onClick={onBetaClicked}>
        <MdIcon icon="exit_to_app" />
        Try the Beta
      </BetaButton>
    </StyledHeader>
  )
}

export type HeaderProps = React.HTMLAttributes<HTMLDivElement>

import { TextField } from "@material-ui/core"
import { FieldMetaState } from "react-final-form"
import { ColorShift } from "style/color-shifting"
import { ThemeProps } from "style/theme"
import styled from "styled-components"

export const Input = styled(TextField)<FieldMetaState<string>>`
  input {
    color: ${({ theme }: ThemeProps): string => theme.surface.textOn};
  }

  &.MuiFormControl-root {
    .MuiInputLabel-animated {
      color: yellow;
      color: ${({ theme }: ThemeProps): string => ColorShift.dark(theme.surface.textOn, theme.surface.color)} !important;
      &.Mui-error {
        color: red !important;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }: ThemeProps): string => ColorShift.medium(theme.surface.textOn, theme.surface.color)} !important;

      &.Mui-error {
        border-color: red !important;
      }
    }

    .MuiOutlinedInput-root {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }: ThemeProps): string => ColorShift.dark(theme.surface.textOn, theme.surface.color)} !important;
      }
      &.Mui-error {
        .MuiOutlinedInput-notchedOutline {
          border-color: red !important;
        }
      }

      &:not(.Mui-error) {
        :hover {
          .MuiOutlinedInput-notchedOutline {
            border-color: ${({ theme }: ThemeProps): string => ColorShift.full(theme.surface.textOn, theme.surface.color)} !important;
          }
        }
      }
    }
  }
`

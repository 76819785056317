import * as React from "react"
import { Func0, Func1 } from "typings"
import { useBoolean } from "./use-boolean"

export const useExpandable = (): UseExpandableResult => {
  const { value, setValue, toggle } = useBoolean()

  return {
    isExpanded: value,
    onWrapperBlur: (): void => setValue(false),
    onExpandElementClick: (e): void => {
      e.stopPropagation()
      toggle()
    },
    onContentMouseDown: (e): void => e.preventDefault(),
    onExpandedElementClick: (): void => setValue(false),
    expand: (): void => setValue(true),
    collapse: (): void => setValue(false),
  }
}

export interface UseExpandableResult {
  readonly isExpanded: boolean
  readonly onWrapperBlur: Func0<void>
  readonly onExpandElementClick: Func1<React.MouseEvent, void>
  readonly onContentMouseDown: Func1<React.MouseEvent, void>
  readonly onExpandedElementClick: Func0<void>
  readonly expand: Func0<void>
  readonly collapse: Func0<void>
}

import { getVideoFromReactPlayerRef } from "domain/watch/watch-player-utils"
import clamp from "lodash-es/clamp"
import React from "react"
import { useCookies } from "react-cookie"
import ReactPlayer from "react-player"
import { Func0, Func1, UseEffectReturn } from "typings"

const VOLUME_COOKIE = "volume"

const clampVolume = (volume: number): number => clamp(volume, 0, 1)

export const useVideoVolume = (reactPlayerRef: React.RefObject<ReactPlayer>): UseVideoVolume => {
  const [volume, _setVolume] = React.useState<number>(0.75)
  const volumeBeforeMuting = React.useRef<number>(0)
  const [cookies, setCookie] = useCookies([VOLUME_COOKIE])

  React.useEffect((): UseEffectReturn => {
    const video = getVideoFromReactPlayerRef(reactPlayerRef)
    if (video) {
      const initialVolume = video.muted ? 0 : clampVolume(cookies.volume ?? 1)
      _setVolume(initialVolume)
      video.volume = initialVolume
      video.onvolumechange = () => _setVolume(video.muted ? 0 : video.volume)

      return (): void => {
        video.onvolumechange = null
      }
    } else {
      console.error("No video found")
    }
  }, [])

  const setVolume = React.useCallback((newVolume: number): void => {
    const video = getVideoFromReactPlayerRef(reactPlayerRef)
    if (video) {
      const clampedVolume = clampVolume(newVolume)
      video.muted = false
      video.volume = clampedVolume
      const date = new Date()
      date.setFullYear(2050)
      setCookie(VOLUME_COOKIE, clampedVolume, { expires: date })
      _setVolume(clampedVolume)
    }
  }, [])

  const mute = React.useCallback((): void => {
    volumeBeforeMuting.current = volume
    setVolume(0)
  }, [setVolume])

  const unmute = React.useCallback((): void => {
    setVolume(volumeBeforeMuting.current)
  }, [setVolume])

  return {
    volume,
    setVolume,
    mute,
    unmute,
  }
}

export interface UseVideoVolume {
  readonly volume: number
  readonly setVolume: Func1<number, void>
  readonly mute: Func0<void>
  readonly unmute: Func0<void>
}

import React from "react"
import { Nullable } from "typings"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BackgroundSlideshowContext = React.createContext(undefined as any)

export const BackgroundSlideshowProvider = ({ children }: React.HTMLAttributes<HTMLElement>): JSX.Element => {
  const [backgroundImageOverride, setBackgroundImageOverride] = React.useState<Nullable<string>>(null)

  const value: BackgroundSlideshowContext = {
    backgroundImageOverride,
    setBackgroundImageOverride,
  }

  return <BackgroundSlideshowContext.Provider value={value}>{children}</BackgroundSlideshowContext.Provider>
}

export const useBackgroundSlideshow = (): BackgroundSlideshowContext => React.useContext(BackgroundSlideshowContext)

export interface BackgroundSlideshowContext {
  readonly backgroundImageOverride: Nullable<string>
  readonly setBackgroundImageOverride: React.Dispatch<React.SetStateAction<Nullable<string>>>
}

import { ContentLoadingSpinner } from "components/loading-spinner"
import { useNextEpisode } from "domain/content/hooks"
import { NavbarContextState, useNavbar } from "domain/navbar/context/navbar-context"
import React from "react"
import { useStore } from "react-redux"
import { useLocation, useParams } from "react-router"
import styled from "styled-components"
import { MovieDbMediaType, RootState } from "typings"
import { getApiUrl } from "util/url"
import { WatchEndpoint } from "../../../constants"
import { useWatchToken } from "../hooks/use-watch-token"
import { WatchPlayer } from "../watch-player"
import { useGetTvEpisodePlaybackTime, useSetTvEpisodePlaybackTime } from "./hooks"
import { navigateToNextEpisode } from "./hooks/watch-show-player-utils"
import { WatchEpisodeRouteParams } from "./watch-episode-route-params"

const EpisodePlayerContainer = styled.div<NavbarContextState>`
  display: grid;
  grid-template-rows: minmax(0, 1fr);
`

export const WatchShowPlayer = (): JSX.Element => {
  const { movieDbId, episodeId } = useParams<WatchEpisodeRouteParams>()
  const { data, loading } = useGetTvEpisodePlaybackTime(+episodeId)
  const [setTvEpisodePlaybackTime] = useSetTvEpisodePlaybackTime()
  const { data: nextEpisode } = useNextEpisode(+episodeId, "cache-first")
  const { pathname } = useLocation()
  const navbar = useNavbar()
  const { data: watchToken } = useWatchToken()
  const initialPath = React.useRef<string>(pathname)
  const store = useStore<RootState>()

  const args = {
    type: MovieDbMediaType.Tv,
    movieDbId,
    episodeId,
    token: watchToken?.watchToken,
  }

  const url = getApiUrl(WatchEndpoint, args)

  const onTimeChanged = (playbackTime: number): void => {
    setTvEpisodePlaybackTime({
      variables: {
        episodeId: +episodeId,
        playbackTime,
      },
    })
  }

  const onVideoEnded = (): void => {
    if (nextEpisode?.getNextEpisode?.id != null) {
      navigateToNextEpisode(pathname, +episodeId, nextEpisode.getNextEpisode.id, store)
    } else {
      console.log(`Next episode id is null: `, nextEpisode)
    }
  }

  const initialTime = initialPath.current === pathname ? data?.getTvEpisodePlaybackTime ?? 0 : 0
  return (
    <EpisodePlayerContainer {...navbar}>
      {!watchToken || loading ? (
        <ContentLoadingSpinner />
      ) : (
        <WatchPlayer
          type={MovieDbMediaType.Tv}
          movieDbId={+movieDbId}
          episodeId={+episodeId}
          url={url}
          watchToken={watchToken.watchToken}
          initialTime={initialTime}
          onTimeChanged={onTimeChanged}
          onVideoEnded={onVideoEnded}
        />
      )}
    </EpisodePlayerContainer>
  )
}

import { AvailabilityOverlay } from "components/availability-overlay"
import { FallbackAvatar, StyledFallbackAvatar } from "components/fallback-cast-image"
import { format } from "date-fns"
import React from "react"
import { fadeAnimationCss } from "style/fade-animation"
import { ThemeProps } from "style/theme"
import styled, { css } from "styled-components"
import {
  ApolloRefetchMethod,
  AvailabilityStatus,
  Func2,
  GetShowSeasonDetails,
  GetShowSeasonDetailsGetTvSeasonDetailsEpisodes,
  GetShowSeasonDetailsVariables,
  SubscribeSeasonDownloadStatusSeasonTorrentStatusEpisodeProgresses,
  UseEffectReturn,
  VirtualizationState,
} from "typings"
import { useContentNavigation } from "util/hooks"
import { Levels } from "../../constants"
import { Body1, Subtitle2 } from "../typography"
import { isContentItemRendered } from "./episode-list-calculations"

export const fallbackImgSrc =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAYAAAADYCAQAAABIZPwaAAABrklEQVR42u3TAQ0AAAzCsOPf9H1AK2HJcjAsEmAAMAAYAAwABgADgAHAAGAAMAAYAAwABgADgAHAAGAAMAAYAAwABgADgAHAAGAAMAAYAAwABgADgAHAAGAAMAAYAAwABgADgAHAAGAAMAAYAAwABgADgAHAAGAAMAAYAAOAAcAAYAAwABgADAAGAAOAAcAAYAAwABgADAAGAAOAAcAAYAAwABgADAAGAAOAAcAAYAAwABgADAAGAAOAAcAAYAAwABgADAAGAAOAAcAAYAAwABgADAAGAAOAAcAAYAAMAAYAA4ABwABgADAAGAAMAAYAA4ABwABgADAAGAAMAAYAA4ABwABgADAAGAAMAAYAA4ABwABgADAAGAAMAAYAA4ABwABgADAAGAAMAAYAA4ABwABgADAAGAAMAAYAA2AAMAAYAAwABgADgAHAAGAAMAAYAAwABgADgAHAAGAAMAAYAAwABgADgAHAAGAAMAAYAAwABgADgAHAAGAAMAAYAAwABgADgAHAAGAAMAAYAAwABgADgAHAAGAAMAAYAAyAAcAAYAAwABgADAAGAANApweYnwDZyc++zgAAAABJRU5ErkJggg=="

const episodeListItemSizeCss = css`
  @media screen and (max-width: 799px) {
    width: 50%;
  }

  @media screen and (min-width: 800px) and (max-width: 1099px) {
    width: 33.33333%;
  }

  @media screen and (min-width: 1100px) and (max-width: 1399px) {
    width: 25%;
  }

  @media screen and (min-width: 1400px) and (max-width: 1699px) {
    width: 20%;
  }

  @media screen and (min-width: 1700px) {
    width: 16.66667%;
  }
`

const EpisodeListItemContainer = styled.div`
  display: inline-block;
  ${episodeListItemSizeCss};

  > * {
    margin-right: 6px;
  }
`

const EpisodeListItemPosterWrapper = styled.div<EpisodeListItemPosterWrapperProps>`
  position: relative;
  background-color: ${({ theme }: ThemeProps): string => `${theme.surface.color}88`};
  overflow: hidden;
  box-sizing: border-box;
  display: grid;
  place-content: center;
  ${fadeAnimationCss};

  :hover {
    ${StyledFallbackAvatar} {
      opacity: 0;
    }

    img {
      filter: brightness(0.4);
    }
  }

  :after {
    content: "${({ episodeNumber }: EpisodeListItemPosterWrapperProps): number => episodeNumber}";
    font-size: 32px;
    color: white;
    text-shadow: black 2px 2px;
    position: absolute;
    bottom: 4px;
    left: 6px;
    z-index: ${Levels.Content};
  }

  :before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: ${({ percentWatched }: EpisodeListItemPosterWrapperProps): string => `${percentWatched}%`};
    background-color: ${({ theme }: ThemeProps): string => theme.primary.color};
    z-index: ${Levels.Content};
  }
`

const EpisodeFallbackWrapper = styled(FallbackAvatar)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const EpisodeListItemPoster = styled.img`
  height: auto;
  cursor: pointer;
  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.4);
  width: 100%;
`

const EpisodeListItemTitle = styled(Subtitle2)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const EpisodeListItemReleased = styled(Body1).attrs({ variant: "medium" })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const EpisodeListItemLabels = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0 16px 0;

  @media screen and (max-width: 600px) {
    ${EpisodeListItemReleased} {
      display: none;
    }
  }
`

export const EpisodeListItem = ({ item, refetch, onItemClicked, downloadProgress }: EpisodeListItemProps): JSX.Element => {
  const { name, stillPath, episodeNumber, airDate, availabilityStatus, playbackTime, runtime } = item
  const itemRef = React.useRef<HTMLDivElement>(null)

  const { scrollerScrollLeft, scrollerClientWidth } = useContentNavigation()
  const [virtualizationState, setVirtualizationState] = React.useState<VirtualizationState>("none")

  const isRendered = virtualizationState === "rendered"

  React.useEffect((): UseEffectReturn => {
    const { current } = itemRef
    if (current) {
      setVirtualizationState(isContentItemRendered(current, scrollerScrollLeft, scrollerClientWidth) ? "rendered" : "none")
    }
  }, [scrollerScrollLeft, scrollerClientWidth])

  const isDone = downloadProgress.some(dp => !dp.more)
  React.useEffect((): UseEffectReturn => {
    if (isDone) {
      refetch()
    }
  }, [isDone])

  const releaseDate = airDate ? format(new Date(airDate), "MMM d, yyyy") : ""
  const percentWatched = (playbackTime && runtime && runtime !== 0 ? playbackTime / runtime : 0) * 100

  const downloadProgressToUse = downloadProgress.find(dp => dp.more)
  const primaryEpisodeText = (!isDone && downloadProgressToUse?.progress) ?? name
  const secondaryEpisodeText = !isDone && downloadProgressToUse?.progress ? null : releaseDate

  return (
    <EpisodeListItemContainer ref={itemRef} data-episodenumber={item.episodeNumber}>
      <EpisodeListItemPosterWrapper
        episodeNumber={episodeNumber}
        percentWatched={percentWatched}
        onClick={onItemClicked ? () => onItemClicked(item, availabilityStatus.availabilityStatus) : undefined}
      >
        <AvailabilityOverlay availabilityStatus={availabilityStatus.availabilityStatus} />
        {isRendered && (
          <>
            <EpisodeListItemPoster src={stillPath || fallbackImgSrc} />
            {!stillPath && <EpisodeFallbackWrapper name={name} />}
          </>
        )}
      </EpisodeListItemPosterWrapper>
      {isRendered && (
        <EpisodeListItemLabels>
          <EpisodeListItemTitle>{primaryEpisodeText}</EpisodeListItemTitle>
          {secondaryEpisodeText && <EpisodeListItemReleased>{secondaryEpisodeText}</EpisodeListItemReleased>}
        </EpisodeListItemLabels>
      )}
    </EpisodeListItemContainer>
  )
}

export interface EpisodeListItemProps {
  readonly item: GetShowSeasonDetailsGetTvSeasonDetailsEpisodes
  readonly refetch: ApolloRefetchMethod<GetShowSeasonDetails, GetShowSeasonDetailsVariables>
  readonly onItemClicked?: Func2<GetShowSeasonDetailsGetTvSeasonDetailsEpisodes, AvailabilityStatus, void>
  readonly downloadProgress: ReadonlyArray<SubscribeSeasonDownloadStatusSeasonTorrentStatusEpisodeProgresses>
}

interface EpisodeListItemPosterWrapperProps {
  readonly episodeNumber: number
  readonly percentWatched: number
}

import * as React from "react"
import styled from "styled-components"
import { useExpandable, UseExpandableResult } from "util/hooks"

export type ContentAlignment = "right" | "left"

export const DropdownWrapper = styled.div`
  position: relative;
  display: inline-grid;
`

export type DropdownContext = UseExpandableResult

const DropdownContext = React.createContext<DropdownContext | undefined>(undefined)

export const useDropdown = (): DropdownContext => {
  const context = React.useContext(DropdownContext)
  if (!context) throw new Error("Must use useDropdown inside of DropdownContext.Provider")

  return context
}

export const Dropdown = ({ children, tabIndex, ...htmlProps }: DropdownProps): JSX.Element => {
  const expandable = useExpandable()

  return (
    <DropdownContext.Provider value={{ ...expandable }}>
      <DropdownWrapper tabIndex={tabIndex || 0} onBlur={expandable.onWrapperBlur} {...htmlProps}>
        {children}
      </DropdownWrapper>
    </DropdownContext.Provider>
  )
}
export interface DropdownProps extends React.HTMLAttributes<HTMLDivElement> {}

import * as React from "react"
import { useHistory } from "react-router"
import { ColorShift } from "style/color-shifting"
import { ThemeProps } from "style/theme"
import styled from "styled-components"
import { routeToLocation } from "util/routing"

const DropdownItemOption = styled.div`
  display: block;
  white-space: nowrap;
  padding: 12px 16px;
  color: ${(props: ThemeProps): string => props.theme.surface.textOn};
  font-weight: normal;
  cursor: pointer;
  &:hover {
    color: ${(props: ThemeProps): string => props.theme.surface.textOn};
    background-color: ${({ theme }: ThemeProps): string => ColorShift.lightest(theme.surface.textOn, theme.surface.color)};
  }
`

const DisabledDropdownItemOption = styled.div`
  display: block;
  white-space: nowrap;
  padding: 12px 16px;
  color: ${({ theme }: ThemeProps): string => ColorShift.light(theme.surface.textOn, theme.surface.color)};
  font-weight: normal;
  cursor: not-allowed;
  &:hover {
    color: ${({ theme }: ThemeProps): string => ColorShift.light(theme.surface.textOn, theme.surface.color)};
    background-color: transparent;
  }
`

const noop = (e: React.MouseEvent): void => {
  e.stopPropagation()
}

export const DropdownLinkItem = ({ to, openInNewTab, disabled, ...props }: DropdownLinkItemProps): JSX.Element => {
  const history = useHistory()

  return disabled ? (
    <DisabledDropdownItemOption {...props} onClick={noop} />
  ) : (
    <DropdownItemOption {...props} onClick={routeToLocation(to, !!openInNewTab, history)} />
  )
}

interface DropdownLinkItemProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "onClick"> {
  readonly disabled?: boolean
  readonly openInNewTab?: boolean
  readonly to: string
}

export const DropdownButtonItem = ({ disabled, ...props }: DropdownButtonItemProps): JSX.Element =>
  disabled ? <DisabledDropdownItemOption {...props} onClick={noop} /> : <DropdownItemOption {...props} />

interface DropdownButtonItemProps extends React.HTMLAttributes<HTMLDivElement> {
  readonly disabled?: boolean
}

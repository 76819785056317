const getAllItems = (scrollingContainer: HTMLDivElement): ReadonlyArray<HTMLElement> =>
  (Array.from(scrollingContainer.children) as unknown) as ReadonlyArray<HTMLElement>

const getVisibleItems = (scrollingContainer: HTMLDivElement, paddingLeft?: number, paddingRight?: number): ReadonlyArray<HTMLElement> => {
  const { scrollLeft } = scrollingContainer.parentElement!
  const { clientWidth } = scrollingContainer

  const children = getAllItems(scrollingContainer)

  return children.filter(item => {
    const { offsetLeft, offsetWidth } = item
    const offsetRight = offsetLeft + offsetWidth
    const scrollRight = scrollLeft + clientWidth

    return offsetRight > scrollLeft + (paddingLeft || 0) && offsetLeft < scrollRight + (paddingRight || 0)
  })
}

export const getNavigateLeftScrollLeft = (scrollingContainer: HTMLDivElement, paddingLeft?: number): number => {
  const allItems = getAllItems(scrollingContainer)
  const visibleItems = getVisibleItems(scrollingContainer)

  const leftmostIndexOnScreen = allItems.indexOf(visibleItems[0])
  const leftmostItemToScrollTo = allItems[Math.max(0, leftmostIndexOnScreen - (visibleItems.length - 2))]
  return leftmostItemToScrollTo.offsetLeft - (paddingLeft || 0)
}

export const getNavigateRightScrollLeft = (scrollingContainer: HTMLDivElement, paddingLeft?: number): number => {
  const visibleItems = getVisibleItems(scrollingContainer)
  const rightmostItemToScrollTo = visibleItems.slice(-1)[0]

  return rightmostItemToScrollTo.offsetLeft - (paddingLeft || 0)
}

import { QueryResult } from "@apollo/react-common"
import { useQuery } from "@apollo/react-hooks"
import { GetShowSeasonDetails, GetShowSeasonDetailsVariables } from "typings"
import { MovieDbQueries } from "../graphql"

export const useShowSeasonDetails = (movieDbId: number, seasonNumber: number): QueryResult<GetShowSeasonDetails> =>
  useQuery<GetShowSeasonDetails, GetShowSeasonDetailsVariables>(MovieDbQueries.GetShowSeasonDetails, {
    variables: {
      movieDbId,
      seasonNumber,
    },
  })

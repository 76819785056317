import * as React from "react"
import { Func0, Func1 } from "typings"

export const useFocus = (): UseFocusResult => {
  const [value, setValue] = React.useState<boolean>(false)

  return {
    isExpanded: value,
    onWrapperBlur: (): void => setValue(false),
    onWrapperFocus: (): void => setValue(true),
    onExpandElementClick: (e): void => {
      e.stopPropagation()
      setValue(!value)
    },
    onContentMouseDown: (e): void => e.preventDefault(),
    onExpandedElementClick: (): void => setValue(false),
    expand: (): void => setValue(true),
    collapse: (): void => setValue(false),
  }
}

export interface UseFocusResult {
  readonly isExpanded: boolean
  readonly onWrapperBlur: Func0<void>
  readonly onWrapperFocus: Func0<void>
  readonly onExpandElementClick: Func1<React.MouseEvent, void>
  readonly onContentMouseDown: Func1<React.MouseEvent, void>
  readonly onExpandedElementClick: Func0<void>
  readonly expand: Func0<void>
  readonly collapse: Func0<void>
}

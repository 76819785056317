import { ColorShift, ColorShiftKeys } from "style/color-shifting"
import { ThemeProps } from "style/theme"
import styled, { css } from "styled-components"
import { FontWeight } from "../constants/font-weight"

const typographyBase = css<TypographyProps & ThemeProps>`
  text-decoration: inherit;
  text-transform: inherit;

  color: ${({ variant, theme }): string => (variant ? ColorShift[variant](theme.surface.textOn, theme.surface.color) : "inherit")};
`

export const Headline1 = styled.h1<TypographyProps>`
  ${typographyBase};

  font-size: 6rem;
  letter-spacing: -0.09375rem;

  font-weight: ${FontWeight.light};
`

export const Headline2 = styled.h2<TypographyProps>`
  ${typographyBase};

  font-weight: ${FontWeight.light};
  font-size: 3.75rem;
  letter-spacing: -0.03125rem;
`

export const Headline3 = styled.h3<TypographyProps>`
  ${typographyBase};

  font-weight: ${FontWeight.regular};

  font-size: 3rem;
  letter-spacing: 0;
`

export const Headline4 = styled.h4<TypographyProps>`
  ${typographyBase};

  font-size: 2.125rem;
  letter-spacing: 0.015625rem;
  font-weight: ${FontWeight.regular};
`

export const Headline5 = styled.h5<TypographyProps>`
  ${typographyBase};

  font-size: 1.5rem;
  font-weight: ${FontWeight.medium};
  letter-spacing: 0.03125rem;
`

export const Headline6 = styled.h6<TypographyProps>`
  ${typographyBase};

  font-size: 1.25rem;
  font-weight: ${FontWeight.medium};
  letter-spacing: 0.03125rem;
`

export const Subtitle1 = styled.h6<TypographyProps>`
  ${typographyBase};

  font-size: 1.1rem;
  font-weight: ${FontWeight.medium};
  letter-spacing: 0.01875rem;
`

export const Subtitle2 = styled.h6<TypographyProps>`
  ${typographyBase};

  font-size: 0.95rem;
  font-weight: ${FontWeight.medium};
  letter-spacing: 0.00625rem;
`

export const Body1 = styled.p<TypographyProps>`
  ${typographyBase};

  font-size: 0.925rem;
  font-weight: ${FontWeight.regular};
  letter-spacing: 0;
`

export const Body2 = styled.p<TypographyProps>`
  ${typographyBase};

  font-size: 0.8725rem;
  letter-spacing: 0;
  font-weight: ${FontWeight.regular};
`

export const Caption = styled.span<TypographyProps>`
  ${typographyBase};

  font-size: 0.6875rem;
  letter-spacing: 0;
  font-weight: ${FontWeight.regular};
`

export const Overline = styled.span<TypographyProps>`
  ${typographyBase};

  font-weight: ${FontWeight.regular};
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.125rem;
`

// tslint:disable-next-line: interface-over-type-literal
type TypographyProps = {
  readonly variant?: ColorShiftKeys
}

import { QueryResult } from "@apollo/react-common"
import { useQuery } from "@apollo/react-hooks"
import { GetCollection, GetCollectionVariables } from "typings/_graphql"
import { MovieDbQueries } from "../graphql"

export const useCollection = (collectionId: number): QueryResult<GetCollection, GetCollectionVariables> =>
  useQuery<GetCollection, GetCollectionVariables>(MovieDbQueries.GetCollection, {
    variables: {
      collectionId,
    },
  })

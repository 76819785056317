import Forgot from "domain/login/components/forgot"
import Login from "domain/login/components/login"
import Logout from "domain/login/components/logout"
import Register from "domain/login/components/register"
import React from "react"
import { Route } from "react-router"
import { ForgotRoute, LoginRoute, LogoutRoute, RegisterRoute } from "../../../constants"
import { titled } from "components/titled"

export const unauthedRoutes: ReadonlyArray<JSX.Element> = [
  <Route key={LoginRoute} path={LoginRoute} render={() => titled("Login", <Login />)} />,
  <Route key={RegisterRoute} path={RegisterRoute} render={() => titled("Register", <Register />)} />,
  <Route key={ForgotRoute} path={ForgotRoute} render={() => titled("Forgot Password", <Forgot />)} />,
  <Route key={LogoutRoute} path={LogoutRoute} render={() => <Logout />} />,
]

import inRange from "lodash-es/inRange"

export const castListItemMinWidth = 130

export const getEffectiveItemSize = (scrollerClientWidth: number): readonly [number, number] => {
  const numPostersToFit = Math.floor(scrollerClientWidth / castListItemMinWidth)
  const posterSize = scrollerClientWidth / numPostersToFit

  return [posterSize, posterSize]
}

export const isContentItemRendered = (item: HTMLDivElement, scrollerScrollLeft: number, scrollerClientWidth: number): boolean => {
  const { offsetLeft } = item
  const minOffsetLeft = scrollerScrollLeft - scrollerClientWidth
  const maxOffsetLeft = scrollerScrollLeft + scrollerClientWidth * 2

  return inRange(offsetLeft, minOffsetLeft, maxOffsetLeft)
}

import { QueryResult } from "@apollo/react-common"
import { useQuery } from "@apollo/react-hooks"
import { FetchPolicy } from "apollo-client"
import { GetMovieDetails, GetMovieDetailsVariables } from "typings/_graphql"
import { MovieDbQueries } from "../graphql"

export const useMovieDetails = (movieDbId: number, fetchPolicy?: FetchPolicy): QueryResult<GetMovieDetails> =>
  useQuery<GetMovieDetails, GetMovieDetailsVariables>(MovieDbQueries.GetMovieDetails, {
    fetchPolicy,
    variables: {
      movieDbId,
    },
  })

import { MdIcon, MdIconType } from "components/md-icon"
import { Body1 } from "components/typography"
import { routerActions } from "connected-react-router"
import { routerSelectors } from "domain/router/store/selectors"
import React from "react"
import { useSelector, useStore } from "react-redux"
import { ThemeProps } from "style/theme"
import styled from "styled-components"
import { HomeRoute } from "../../../constants"
import { RootState } from "typings"

const getNavbarTemplateColumns = (isExpanded: boolean): string => (isExpanded ? "auto 1fr" : "minmax(0, 1fr)")

const StyledNavbarLink = styled.div<StyledNavbarLinkProps>`
  display: grid;
  grid-template-columns: ${({ isExpanded }: StyledNavbarLinkProps): string => getNavbarTemplateColumns(isExpanded)};
  align-items: center;
  gap: 8px;
  padding: 6px 16px;

  border-left: 4px solid ${({ theme, isActive }: ThemeProps & StyledNavbarLinkProps): string => (isActive ? theme.primary.color : "transparent")};
  color: ${({ theme, isActive }: ThemeProps & StyledNavbarLinkProps): string => (isActive ? "white" : theme.surface.textOn)};
  cursor: pointer;
  white-space: nowrap;
  transition: background-color 150ms;

  :hover {
    color: white;
  }
`

const StyledMdIcon = styled(MdIcon)`
  color: inherit;
  cursor: inherit;
`

const StyledLabel = styled(Body1)`
  color: inherit;
  cursor: inherit;
`

export const NavbarLink = ({ icon, label, route, isExpanded }: NavbarLinkProps): JSX.Element => {
  const store = useStore<RootState>()
  const pathname = useSelector(routerSelectors.getPathname)
  const isActive = route === HomeRoute ? pathname === route : pathname.startsWith(route)

  const navigateTo = (): void => {
    store.dispatch(routerActions.push(route))
  }

  return (
    <StyledNavbarLink isExpanded={isExpanded} isActive={isActive} title={(!isExpanded && label) || undefined} onClick={navigateTo}>
      <StyledMdIcon icon={icon} />
      {isExpanded && <StyledLabel>{label}</StyledLabel>}
    </StyledNavbarLink>
  )
}

export interface NavbarLinkBase {
  readonly icon: MdIconType
  readonly label: string
  readonly route: string
}

export interface NavbarLinkProps extends NavbarLinkBase {
  readonly isExpanded: boolean
}

interface StyledNavbarLinkProps {
  readonly isExpanded: boolean
  readonly isActive: boolean
}

import { ApolloProvider } from "@apollo/react-hooks"
import { BackgroundSlideshowProvider } from "components/background-slideshow"
import { ConnectedRouter } from "connected-react-router"
import { NavbarProvider } from "domain/navbar/context/navbar-context"
import { graphqlClient } from "domain/network/graphql-client"
import { CastManagerProvider } from "domain/watch/cast/use-cast-manager"
import { History } from "history"
import React, { useEffect, useState } from "react"
import { Provider } from "react-redux"
import { Route } from "react-router"
import { configureStore } from "setup/configure-store"
import { createHistory } from "setup/history"
import { defaultTheme } from "style/theme"
import { ThemeProvider } from "styled-components"
import { QueryParamProvider } from "use-query-params"
import { PageHistoryProvider } from "util/hooks/use-page-history"

export const AppProviders = ({ children }: AppProviders): JSX.Element => {
  const [history, setHistory] = useState<History>()

  useEffect(() => {
    switch (window.location.pathname) {
      case "/link":
        window.location.replace(`/#/link${window.location.search}`)
        break
      case "/tv":
        window.location.replace("/static/tv/index.html")
        break
      case "/tesla":
        window.location.replace("https://mattflix.watch:3000")
        break
    }
  }, [window.location.pathname])

  const readyToRender = window.location.pathname === "/"

  useEffect(() => {
    if (readyToRender && !history) {
      setHistory(createHistory())
    }
  }, [readyToRender, history])

  return readyToRender && history ? (
    <ThemeProvider theme={defaultTheme}>
      <ApolloProvider client={graphqlClient}>
        <Provider store={configureStore()}>
          <ConnectedRouter history={history}>
            <QueryParamProvider ReactRouterRoute={Route}>
              <NavbarProvider>
                <BackgroundSlideshowProvider>
                  <PageHistoryProvider>
                    <CastManagerProvider>{children}</CastManagerProvider>
                  </PageHistoryProvider>
                </BackgroundSlideshowProvider>
              </NavbarProvider>
            </QueryParamProvider>
          </ConnectedRouter>
        </Provider>
      </ApolloProvider>
    </ThemeProvider>
  ) : (
    <></>
  )
}

interface AppProviders {
  readonly children: React.ReactNode
}

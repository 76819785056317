import * as React from "react"
import { ThemeProps } from "style/theme"
import styled from "styled-components"
import { ElevationShadow } from "../../constants"
import { ContentAlignment, useDropdown } from "./dropdown"

const dropdownContentElevation = ElevationShadow.low

const borderRadius = "4px"

export const DropdownContent = styled.div<{ readonly alignment?: ContentAlignment }>`
  z-index: 1000;
  min-width: 160px;
  color: ${({ theme }: ThemeProps): string => theme.surface.textOn};
  background: ${({ theme }: ThemeProps): string => theme.surface.color};
  position: absolute;
  box-shadow: ${dropdownContentElevation};
  top: calc(100% + 2px);
  ${({ alignment }: { readonly alignment?: ContentAlignment }): string => (alignment === "left" ? "left: 0" : "right: 0")};
  border-radius: 4px;
  display: grid;

  & > *:first-child {
    border-radius: ${borderRadius} ${borderRadius} 0 0;
  }

  & > *:last-child {
    border-radius: 0 0 ${borderRadius} ${borderRadius};
  }

  & > *:last-child:first-child {
    border-radius: ${borderRadius};
  }
`

export interface DropDownItemsProps extends React.HTMLAttributes<HTMLDivElement> {
  readonly alignment?: ContentAlignment
  readonly children: ReadonlyArray<React.ReactNode> | React.ReactNode
}

export const DropdownItems = ({ children, alignment, ...htmlProps }: DropDownItemsProps): JSX.Element => {
  const { isExpanded, onContentMouseDown, onExpandedElementClick } = useDropdown()

  return isExpanded ? (
    <DropdownContent alignment={alignment} onMouseDown={onContentMouseDown} onClick={onExpandedElementClick} {...htmlProps}>
      {children}
    </DropdownContent>
  ) : (
    <React.Fragment />
  )
}

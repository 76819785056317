import { routerActions } from "connected-react-router"
import React from "react"
import { fadeAnimationCss } from "style/fade-animation"
import { ThemeProps } from "style/theme"
import styled, { css } from "styled-components"
import { AvailabilityStatus, ContentBaseType, RootState } from "typings"
import { usePageHistory } from "util/hooks"
import { FullscreenRoute } from "../../constants"
import { useStore } from "react-redux"

export const itemTitleHeight = 25

const contentListItemSizeCss = css`
  @media screen and (max-width: 499px) {
    width: 50%;
  }

  @media screen and (min-width: 500px) and (max-width: 799px) {
    width: 33.333333%;
  }

  @media screen and (min-width: 800px) and (max-width: 1099px) {
    width: 25%;
  }

  @media screen and (min-width: 1100px) and (max-width: 1399px) {
    width: 20%;
  }

  @media screen and (min-width: 1400px) and (max-width: 1799px) {
    width: 16.6666667%;
  }

  @media screen and (min-width: 1800px) {
    width: 14.2857%;
  }
`

const ContentListItemContainer = styled.div`
  display: inline-block;
  ${contentListItemSizeCss};

  > * {
    margin: 0 6px 6px 0;
  }
`

const ContentListItemPosterWrapper = styled.div<ContentListItemPosterWrapperProps>`
  position: relative;
  border: 2px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden;
  ${fadeAnimationCss};

  filter: ${({ isAvailable, alwaysFullBrightness }: ContentListItemPosterWrapperProps): string =>
    `brightness(${alwaysFullBrightness || isAvailable ? "100%" : "50%"})`};

  :hover {
    border: 2px solid ${({ theme }: ThemeProps): string => theme.primary.color};

    img {
      filter: brightness(40%);
    }
  }
`

export const ContentListItemPoster = styled.img`
  cursor: ${({ onClick }): string => (onClick ? "pointer" : "")};
  border-radius: 4px;
  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.4);
  width: 100%;
`

export const ContentListItem = ({
  item: { title, posterPath, type, id, availabilityStatus },
  alwaysFullBrightness = false,
}: ContentListItemProps): JSX.Element | null => {
  const itemRef = React.useRef<HTMLDivElement>(null)
  const { pushHistory } = usePageHistory()
  const store = useStore<RootState>()

  // const contentNavigation = useContentNavigation()
  // const [virtualizationState, setVirtualizationState] = React.useState<VirtualizationState>("none")

  const onItemClicked = (): void => {
    const path = FullscreenRoute(type, id)
    pushHistory({
      title,
      path,
    })
    store.dispatch(routerActions.push(path))
  }

  const isRendered = true
  // virtualizationState === "rendered"

  // React.useEffect((): UseEffectReturn => {
  //   if (contentNavigation) {
  //     const { scrollerScrollLeft, scrollerClientWidth } = contentNavigation
  //     const { current } = itemRef

  //     if (current) {
  //       setVirtualizationState(isContentItemRendered(current, scrollerScrollLeft, scrollerClientWidth) ? "rendered" : "none")
  //     }
  //   } else {
  //     setVirtualizationState("rendered")
  //   }
  // }, [contentNavigation?.scrollerScrollLeft, contentNavigation?.scrollerClientWidth])

  return posterPath ? (
    <ContentListItemContainer ref={itemRef}>
      <ContentListItemPosterWrapper isAvailable={availabilityStatus === AvailabilityStatus.Available} alwaysFullBrightness={alwaysFullBrightness}>
        {isRendered && (
          <>
            {/* {availabilityStatus && <AvailabilityBanner availabilityStatus={availabilityStatus} />} */}
            <ContentListItemPoster src={posterPath!} onClick={onItemClicked} />
          </>
        )}
      </ContentListItemPosterWrapper>
      {/* {isRendered && <ContentListItemTitle>{title}</ContentListItemTitle>} */}
    </ContentListItemContainer>
  ) : null
}

export interface ContentListItemProps {
  readonly item: ContentBaseType
  readonly alwaysFullBrightness?: boolean
}

interface ContentListItemPosterWrapperProps {
  readonly isAvailable: boolean
  readonly alwaysFullBrightness: boolean
}

import { useBackgroundSlideshow } from "components/background-slideshow"
import { CastList } from "components/cast-list"
import { CustomScroller } from "components/custom-scroller"
import { ContentLoadingSpinner } from "components/loading-spinner"
import { PaddedPageContent } from "components/page"
import { ReturnToLink } from "components/return-to-link"
import { Body1 } from "components/typography"
import { useMovieDetails, useMovieDownloadProgress } from "domain/content/hooks"
import { useNavbar } from "domain/navbar/context/navbar-context"
import React from "react"
import Helmet from "react-helmet"
import { AvailabilityStatus, Nullable, SubscribeMovieDownloadStatusMovieTorrentStatus, UseEffectReturn } from "typings"
import { usePageHistory } from "util/hooks"
import { FullscreenContainer, FullscreenOverview, FullscreenScrollers } from "../fullscreen-container"
import { FullscreenDetails } from "../fullscreen-details"
import { FullscreenMovieCollection } from "./fullscreen-movie-collection"
import { FullscreenMovieHeader } from "./fullscreen-movie-header"
import { FullscreenMoviePoster } from "./fullscreen-movie-poster"
import { FullscreenMovieTrailer } from "./fullscreen-movie-trailer"
import { FullscreenSimilarMovies } from "./fullscreen-similar-movies"

export const FullscreenMovie = ({ movieDbId }: FullscreenMovieProps): JSX.Element => {
  const navbar = useNavbar()
  const { data, loading, error } = useMovieDetails(movieDbId)
  const { setBackgroundImageOverride } = useBackgroundSlideshow()
  const [downloadProgress, setDownloadProgress] = React.useState<Nullable<SubscribeMovieDownloadStatusMovieTorrentStatus>>(null)

  const { previousPage } = usePageHistory()

  React.useEffect((): UseEffectReturn => {
    if (data?.getMovieDetails?.backdropPath) {
      setBackgroundImageOverride(data.getMovieDetails.backdropPath)
    }

    return (): void => {
      setBackgroundImageOverride(null)
    }
  }, [data?.getMovieDetails?.backdropPath])

  React.useEffect((): UseEffectReturn => {
    if (data?.getMovieDetails?.availabilityStatus.availabilityStatus !== AvailabilityStatus.DownloadInProgress) {
      setDownloadProgress(null)
    } else if (data.getMovieDetails.availabilityStatus.availabilityStatus === AvailabilityStatus.DownloadInProgress && !downloadProgress) {
      setDownloadProgress({
        __typename: "DownloadProgressType",
        more: true,
        movieDbId,
        percentDone: 0,
        progress: `Initializing...`,
      })
    }
  }, [data?.getMovieDetails?.availabilityStatus.availabilityStatus])

  useMovieDownloadProgress(movieDbId, {
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.error || !subscriptionData.data?.movieTorrentStatus?.more) {
        setDownloadProgress(null)
      } else if (!!subscriptionData.data.movieTorrentStatus.progress) {
        setDownloadProgress(subscriptionData.data.movieTorrentStatus)
      }
    },
  })

  if (error) {
    return (
      <PaddedPageContent>
        <Body1>Failed to load movie details</Body1>
      </PaddedPageContent>
    )
  }

  if (loading) {
    return <ContentLoadingSpinner size="medium" />
  }

  if (data?.getMovieDetails) {
    const {
      getMovieDetails: { title, description, released, cast, collectionId, embedUrl },
    } = data

    return (
      <CustomScroller>
        <>
          <Helmet>
            <title>Mattflix - {title}</title>
          </Helmet>
          {previousPage && (
            <PaddedPageContent>
              <ReturnToLink page={previousPage} />
            </PaddedPageContent>
          )}
          <FullscreenContainer>
            <FullscreenOverview {...navbar}>
              <FullscreenMovieHeader title={title} releaseDate={released} info="" />
              <FullscreenDetails overview={description} />
              <FullscreenMoviePoster
                movie={data.getMovieDetails}
                downloadProgress={
                  data.getMovieDetails.availabilityStatus.availabilityStatus === AvailabilityStatus.DownloadInProgress ? downloadProgress : undefined
                }
              />
            </FullscreenOverview>
            <FullscreenScrollers>
              <CastList header="Cast" items={cast} />
              {collectionId != null && <FullscreenMovieCollection collectionId={collectionId} />}
              <FullscreenSimilarMovies movieDbId={movieDbId} />
            </FullscreenScrollers>
            {embedUrl && <FullscreenMovieTrailer embedUrl={embedUrl} />}
          </FullscreenContainer>
        </>
      </CustomScroller>
    )
  }

  return (
    <PaddedPageContent>
      <Body1>Failed to load movie details</Body1>
    </PaddedPageContent>
  )
}

export interface FullscreenMovieProps {
  readonly movieDbId: number
}

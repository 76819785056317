import { Button } from "components/button"
import { EpisodeList } from "components/episode-list"
import { OptionType, SelectProps, SingleSelect } from "components/select"
import { routerActions } from "connected-react-router"
import { useRequestTvSeason, useSeasonDownloadProgress, useShowSeasonDetails, useUnrequestTvSeason } from "domain/content/hooks"
import React from "react"
import { useParams } from "react-router"
import { ValueType } from "react-select"
import styled from "styled-components"
import {
  AvailabilityStatus,
  Func0,
  GetShowDetailsGetTvDetailsNextEpisode,
  GetShowDetailsGetTvDetailsSeasons,
  GetShowSeasonDetailsGetTvSeasonDetailsEpisodes,
  Nullable,
  SubscribeSeasonDownloadStatusSeasonTorrentStatus,
  RootState,
} from "typings"
import { WatchShowRoute } from "../../../../../constants"
import { FullscreenRouteParams } from "../fullscreen-route-params"
import { useStore } from "react-redux"

const seasonHeaderHeight = 35

const SeasonSelect = styled(SingleSelect)<SelectProps<SeasonOptionType>>`
  width: 200px;
  height: ${seasonHeaderHeight}px;
`

const SeasonHeaderContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 12px;
  justify-content: start;
`

const SeasonRequestButton = styled(Button)`
  height: ${seasonHeaderHeight}px;
  padding: 0 12px !important;
  width: 210px;
`

export const FullscreenShowEpisodeScroller = ({ seasons, currentEpisode }: FullscreenShowEpisodeScrollerProps): JSX.Element => {
  const store = useStore<RootState>()
  const { movieDbId } = useParams<FullscreenRouteParams>()
  const [requestTvSeason, { loading: requestLoading }] = useRequestTvSeason()
  const [unrequestTvSeason, { loading: unrequestLoading }] = useUnrequestTvSeason()
  const [downloadProgress, setDownloadProgress] = React.useState<Nullable<SubscribeSeasonDownloadStatusSeasonTorrentStatus>>(null)

  const mapSeasonToSeasonOption = (season: GetShowDetailsGetTvDetailsSeasons): SeasonOptionType => ({
    value: season.seasonNumber.toString(),
    label: `Season ${season.seasonNumber}`,
    season,
  })

  const initialSeason = currentEpisode?.tvSeason
    ? seasons.find(s => s.seasonNumber === currentEpisode.tvSeason.seasonNum)!
    : seasons.find(s => s.seasonNumber > 0)!

  const [selectedSeason, setSelectedSeason] = React.useState<SeasonOptionType>(mapSeasonToSeasonOption(initialSeason))
  const { data, loading, refetch } = useShowSeasonDetails(+movieDbId, selectedSeason.season.seasonNumber)
  const seasonOptions = seasons
    .filter(s => s.seasonNumber > 0)
    .sort((a, b) => a.seasonNumber - b.seasonNumber)
    .map(mapSeasonToSeasonOption)

  const onSelectedSeasonChanged = (value: ValueType<SeasonOptionType>): void => {
    setSelectedSeason(value as SeasonOptionType)
  }

  const onEpisodeClicked = (episode: GetShowSeasonDetailsGetTvSeasonDetailsEpisodes, availabilityStatus: AvailabilityStatus): void => {
    if (availabilityStatus === AvailabilityStatus.Available) {
      store.dispatch(routerActions.push(WatchShowRoute(+movieDbId, episode.id)))
    }
  }

  const allEpisodesAvailable = data?.getTvSeasonDetails?.episodes.every(e => e.availabilityStatus.availabilityStatus === AvailabilityStatus.Available)
  const someEpisodesRequested = data?.getTvSeasonDetails?.episodes.some(e =>
    [AvailabilityStatus.Requested, AvailabilityStatus.DownloadInProgress].includes(e.availabilityStatus.availabilityStatus),
  )

  const getRequestButtonText = (): string => {
    if (someEpisodesRequested) {
      return "Cancel Request"
    }

    return "Request Season"
  }

  const getRequestButtonAction = (): Func0<Promise<void>> => {
    if (someEpisodesRequested) {
      return async (): Promise<void> => {
        await unrequestTvSeason({
          variables: {
            movieDbId: +movieDbId,
            seasonNumber: selectedSeason.season.seasonNumber,
          },
        })
        setDownloadProgress(null)
      }
    }

    return async (): Promise<void> => {
      await requestTvSeason({
        variables: {
          movieDbId: +movieDbId,
          seasonNumber: selectedSeason.season.seasonNumber,
        },
      })
    }
  }

  useSeasonDownloadProgress(+movieDbId, selectedSeason.season.seasonNumber, {
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.error || !subscriptionData.data?.seasonTorrentStatus?.episodeProgresses.some(ep => ep.more)) {
        setDownloadProgress(null)
      } else if (!!subscriptionData.data.seasonTorrentStatus) {
        setDownloadProgress(subscriptionData.data.seasonTorrentStatus)
      }
    },
  })

  const SeasonHeader = (): JSX.Element => (
    <SeasonHeaderContainer>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <SeasonSelect options={seasonOptions} value={selectedSeason} placeholder="Season" onChange={onSelectedSeasonChanged as any} />
      {data?.getTvSeasonDetails?.episodes && !allEpisodesAvailable && (
        <SeasonRequestButton onClick={getRequestButtonAction()} isLoading={requestLoading || unrequestLoading}>
          {getRequestButtonText()}
        </SeasonRequestButton>
      )}
    </SeasonHeaderContainer>
  )

  return (
    <EpisodeList
      header={<SeasonHeader />}
      items={data?.getTvSeasonDetails?.episodes || []}
      refetch={refetch}
      onItemClicked={onEpisodeClicked}
      loading={loading}
      scrollToEpisodeNumber={selectedSeason.season.seasonNumber === currentEpisode?.tvSeason.seasonNum ? currentEpisode?.episodeNum : undefined}
      downloadProgress={downloadProgress}
    />
  )
}

export interface FullscreenShowEpisodeScrollerProps {
  readonly seasons: ReadonlyArray<GetShowDetailsGetTvDetailsSeasons>
  readonly currentEpisode: Nullable<GetShowDetailsGetTvDetailsNextEpisode>
}

interface SeasonOptionType extends OptionType {
  readonly season: GetShowDetailsGetTvDetailsSeasons
}

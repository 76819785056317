import { CircularProgress } from "@material-ui/core"
import { ThemeProps } from "style/theme"
import styled from "styled-components"

const getSize = (size: LoadingSpinnerSize): string => {
  switch (size) {
    case "tiny":
      return "10px"

    case "extrasmall":
      return "15px"

    case "small":
      return "20px"

    case "medium":
      return "50px"

    case "large":
      return "100px"
  }
}

export const LoadingSpinner = styled(CircularProgress)<LoadingSpinnerProps>`
  display: block !important;
  margin: 0 auto;
  color: ${({ theme }: ThemeProps): string => theme.primary.color} !important;
  width: ${({ size = "small" }: LoadingSpinnerProps): string => getSize(size)};
  height: ${({ size = "small" }: LoadingSpinnerProps): string => getSize(size)};

  svg {
    color: inherit;
  }
`

export const ContentLoadingSpinner = styled(LoadingSpinner)`
  margin-top: 5rem;
`

export type LoadingSpinnerSize = "tiny" | "extrasmall" | "small" | "medium" | "large"

export interface LoadingSpinnerProps {
  readonly size?: LoadingSpinnerSize
}

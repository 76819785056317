import { Headline6 } from "components/typography"
import { NavbarContextState } from "domain/navbar/context/navbar-context"
import styled from "styled-components"

export const navigationGridAreas = {
  header: "header",
  navigation: "navigation",
  scroller: "scroller",
}

export const NavigationContainer = styled.div`
  display: grid;
  grid-template-areas:
    "${navigationGridAreas.header} ${navigationGridAreas.navigation}"
    "${navigationGridAreas.scroller} ${navigationGridAreas.scroller}";
`

export const NavigationHeader = styled(Headline6)<NavbarContextState>`
  grid-area: ${navigationGridAreas.header};
  padding-left: ${({ navbarWidth }) => `${navbarWidth}px`};
  align-self: end;
  white-space: nowrap;
`

export const NavigationScroller = styled.div<NavbarContextState>`
  position: relative;
  padding: 8px 0 0 ${({ navbarWidth }) => `${navbarWidth}px`};
  white-space: nowrap;

  :before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }
`

import { Headline4, Headline6 } from "components/typography"
import { format } from "date-fns"
import { useCurrentUser } from "domain/reference_data/hooks"
import React from "react"
import { lightRuleColor, ThemeProps } from "style/theme"
import styled from "styled-components"
import { DateFormat } from "../../../../../constants"
import { fullscreenGridAreas } from "../fullscreen-container"
import { FullscreenMovieHeaderOverflowMenu } from "./fullscreen-movie-header-overflow-menu"

const HeaderContainer = styled.div`
  grid-area: ${fullscreenGridAreas.header};

  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  align-items: start;
  border-bottom: 1px solid ${({ theme }: ThemeProps): string => lightRuleColor(theme, "AA")};
  padding: 8px 16px;
`

const HeaderDetailsContainer = styled.div`
  display: grid;
  gap: 4px;
`

export const FullscreenMovieHeader = ({ title, releaseDate, info }: FullscreenHeaderProps): JSX.Element => {
  const { data: referenceData } = useCurrentUser()
  const formattedDate = releaseDate ? format(new Date(releaseDate), DateFormat.LongMonthYear) : ""

  return (
    <HeaderContainer>
      <HeaderDetailsContainer>
        <Headline4>{title}</Headline4>
        <Headline6>{formattedDate}</Headline6>
        {info}
      </HeaderDetailsContainer>
      {referenceData?.currentUser?.isAdmin && <FullscreenMovieHeaderOverflowMenu />}
    </HeaderContainer>
  )
}

export interface FullscreenHeaderProps {
  readonly title: string
  readonly releaseDate: string
  readonly info: React.ReactNode
}

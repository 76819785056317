import { ColorShift } from "./color-shifting"

export const defaultTheme: Theme = {
  background: {
    color: "#000000",
    textOn: "#CCCCCC",
  },
  surface: {
    color: "#333333",
    textOn: "#CCCCCC",
  },
  primary: {
    color: "#FF0000",
    textOn: "#EEEEEE",
  },
}

export const lightRuleColor = (theme: Theme, alpha: string = "FF"): string => `${ColorShift.light(theme.surface.textOn, theme.surface.color)}${alpha}`

interface BaseColor {
  readonly color: string
  readonly textOn: string
}

export interface Theme {
  readonly surface: BaseColor
  readonly background: BaseColor
  readonly primary: BaseColor
}

export interface ThemeProps {
  readonly theme: Theme
}

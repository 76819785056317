import { ContentGrid } from "components/content-list/content-grid"
import { ContentLoadingSpinner } from "components/loading-spinner"
import { PaddedPageContent } from "components/page"
import { Body1 } from "components/typography"
import { useMovies } from "domain/content/hooks"
import uniqBy from "lodash-es/uniqBy"
import React from "react"
import { GetMovies, GetMoviesVariables, Nullable, UseEffectReturn } from "typings"
import { usePageHistory } from "util/hooks"
import { MoviesRoute } from "../../../../constants"

const PAGE_SIZE = 50

const mergeMoviesResults = (currentMovies: Nullable<GetMovies>, newMovies: Nullable<GetMovies>): GetMovies => {
  if (currentMovies && newMovies) {
    return {
      ...currentMovies,
      movies: {
        __typename: "GetMoviesResponseType",
        resultCount: newMovies.movies.resultCount,
        movies: uniqBy([...currentMovies.movies.movies, ...newMovies.movies.movies], "id"),
      },
    }
  }

  return {
    movies: {
      __typename: "GetMoviesResponseType",
      resultCount: currentMovies?.movies.resultCount ?? 0,
      movies: currentMovies?.movies.movies ?? [],
    },
  }
}

const Movies = (): JSX.Element => {
  const { pushHistory } = usePageHistory()
  const { data, loading, error, fetchMore } = useMovies({
    take: 0,
    skip: 0,
  })

  React.useEffect((): UseEffectReturn => {
    pushHistory({
      title: "Movies",
      path: MoviesRoute,
    })
  }, [])

  const onPage = (nextPage: number): void => {
    const variables: GetMoviesVariables = {
      searchOptions: {
        take: PAGE_SIZE,
        skip: PAGE_SIZE * (nextPage - 1),
      },
    }

    fetchMore({
      variables,
      updateQuery: (currentMovies: GetMovies, { fetchMoreResult }): GetMovies => {
        if (!currentMovies) {
          return currentMovies
        }

        return mergeMoviesResults(currentMovies, fetchMoreResult)
      },
    })
  }

  if (loading) {
    return <ContentLoadingSpinner size="medium" />
  }

  if (error) {
    return (
      <PaddedPageContent>
        <Body1>An error occurred while loading movies</Body1>
      </PaddedPageContent>
    )
  }

  if (data?.movies) {
    return <ContentGrid id="movies_grid" items={data.movies.movies} onPage={onPage} />
  }

  return (
    <PaddedPageContent>
      <Body1>An error occurred while loading movies</Body1>
    </PaddedPageContent>
  )
}

export default Movies

import { NavbarContextState, useNavbar } from "domain/navbar/context/navbar-context"
import React from "react"
import styled from "styled-components"

const StyledPaddedPageContent = styled.div<NavbarContextState>`
  padding-left: ${({ navbarWidth }) => `${navbarWidth}px`};
`

export const PaddedPageContent = (props: React.HTMLAttributes<HTMLDivElement>): JSX.Element => {
  const navbar = useNavbar()

  return <StyledPaddedPageContent {...navbar} {...props} />
}

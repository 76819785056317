import DeleteMovie from "./delete-movie.graphql"
import GetCollection from "./get-collection.graphql"
import GetComingSoon from "./get-coming-soon.graphql"
import GetContentForCast from "./get-content-for-cast-id.graphql"
import GetContentForGenre from "./get-content-for-genre-id.graphql"
import GetGenreWatchTimes from "./get-genre-watchtimes.graphql"
import GetMovieDetails from "./get-movie-details.graphql"
import GetMovies from "./get-movies.graphql"
import GetNextEpisode from "./get-next-episode.graphql"
import GetPersonDetails from "./get-person-details.graphql"
import GetShowDetails from "./get-show-details.graphql"
import GetShowEpisodeDetails from "./get-show-episode-details.graphql"
import GetShowSeasonDetails from "./get-show-season-details.graphql"
import GetShows from "./get-shows.graphql"
import GetSimilarMovies from "./get-similar-movies.graphql"
import GetSimilarShows from "./get-similar-shows.graphql"
import GetTrending from "./get-trending.graphql"
import ImportMovie from "./import-movie.graphql"
import RequestMovie from "./request-movie.graphql"
import RequestTvSeason from "./request-tv-season.graphql"
import Search from "./search.graphql"
import SubscribeMovieDownloadStatus from "./subscribe-movie-download-status.graphql"
import SubscribeSeasonDownloadStatus from "./subscribe-season-download-status.graphql"
import UnrequestMovie from "./unrequest-movie.graphql"
import UnrequestTvSeason from "./unrequest-tv-season.graphql"
import GetTvEpisodeDetails from "./get-tv-episode-details.graphql"

export const MovieDbQueries = {
  GetMovieDetails,
  GetMovies,
  GetShows,
  GetShowDetails,
  GetSimilarMovies,
  GetSimilarShows,
  GetShowSeasonDetails,
  GetShowEpisodeDetails,
  GetContentForGenre,
  GetContentForCast,
  GetGenreWatchTimes,
  GetTrending,
  Search,
  GetCollection,
  GetPersonDetails,
  GetComingSoon,
  GetNextEpisode,
  GetTvEpisodeDetails,
}

export const MovieDbMutations = {
  RequestTvSeason,
  UnrequestTvSeason,
  RequestMovie,
  UnrequestMovie,
  DeleteMovie,
  ImportMovie,
}

export const MovieDbSubscriptions = {
  SubscribeMovieDownloadStatus,
  SubscribeSeasonDownloadStatus,
}

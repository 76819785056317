import { CustomScroller } from "components/custom-scroller"
import { ContentLoadingSpinner } from "components/loading-spinner"
import React from "react"
import Scrollbars from "react-custom-scrollbars"
import styled from "styled-components"
import { Func0, GetGenreWatchTimesGenreWatchTimes, UseEffectReturn } from "typings"
import { useInfiniteScrolling } from "util/hooks/use-infinite-scrolling"
import { Levels } from "../../../../constants"
import { ExploreGenreList } from "./explore-genre-list"

const ExploreContainer = styled.div`
  display: grid;
  place-content: stretch;
  overflow: hidden;
  z-index: ${Levels.Content};
  gap: 32px;
  margin-bottom: 32px;
`

export const ExploreContent = ({ genres }: ExploreContentProps): JSX.Element => {
  const containerRef = React.useRef<Scrollbars>(null)

  const [genresToLoad, setGenresToLoad] = React.useState<number>(3)
  const { page } = useInfiniteScrolling(containerRef)

  const [loadedGenres, setLoadedGenres] = React.useState<ReadonlyArray<number>>([])

  const onLoaded = (genreId: number): Func0<void> => (): void => {
    setLoadedGenres([...loadedGenres, genreId])
  }

  React.useEffect((): UseEffectReturn => {
    setGenresToLoad(genresToLoad * page)
  }, [page])

  return (
    <CustomScroller ref={containerRef}>
      <ExploreContainer>
        {!loadedGenres.length && <ContentLoadingSpinner size="medium" />}
        {genres.slice(0, genresToLoad).map(({ genreId }) => (
          <ExploreGenreList key={genreId} genreId={genreId} onLoaded={onLoaded(genreId)} />
        ))}
      </ExploreContainer>
    </CustomScroller>
  )
}

interface ExploreContentProps {
  readonly genres: ReadonlyArray<GetGenreWatchTimesGenreWatchTimes>
}

import { ContentList } from "components/content-list"
import { useSimilarMovies } from "domain/content/hooks"
import React from "react"

export const FullscreenSimilarMovies = ({ className, movieDbId }: FullscreenSimilarMoviesProps): JSX.Element | null => {
  const { data } = useSimilarMovies(movieDbId)

  return data?.similarMovies.length ? (
    <ContentList id={`similar_movies_${movieDbId}`} className={className} header="Similar Movies" items={data.similarMovies} />
  ) : null
}

interface FullscreenSimilarMoviesProps {
  readonly className?: string
  readonly movieDbId: number
}

import { InMemoryCache } from "apollo-cache-inmemory"
import ApolloClient from "apollo-client"
import { split } from "apollo-link"
import { HttpLink } from "apollo-link-http"
import { WebSocketLink } from "apollo-link-ws"
import { getMainDefinition } from "apollo-utilities"
import { getGraphqlSubscriptionsUrl, getGraphqlUrl } from "util/url"

const httpLink = new HttpLink({
  uri: getGraphqlUrl(),
  credentials: "include",
})

const wsLink = new WebSocketLink({
  uri: getGraphqlSubscriptionsUrl(),
  options: {
    reconnect: true,
  },
})

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === "OperationDefinition" && definition.operation === "subscription"
  },
  wsLink,
  httpLink,
)

export const graphqlClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
})

import { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { Func1 } from "typings"

const SUBTITLES_COOKIE = "subtitles"

export const useSubtitles = (): UseSubtitles => {
  const [cookies, setCookie] = useCookies([SUBTITLES_COOKIE])
  const [isSubtitlesEnabled, _setIsSubtitlesEnabled] = useState<boolean>(false)

  const setIsSubtitlesEnabled = (isEnabled: boolean): void => {
    const date = new Date()
    date.setFullYear(2050)
    setCookie(SUBTITLES_COOKIE, isEnabled, { expires: date })
    _setIsSubtitlesEnabled(isEnabled)
  }

  useEffect(() => {
    const subtitlesCookie = cookies.subtitles === "true"
    setIsSubtitlesEnabled(subtitlesCookie)
  }, [])

  return {
    isSubtitlesEnabled,
    setIsSubtitlesEnabled,
  }
}

export interface UseSubtitles {
  readonly isSubtitlesEnabled: boolean
  readonly setIsSubtitlesEnabled: Func1<boolean, void>
}

import { ContentList } from "components/content-list"
import { useCollection } from "domain/content/hooks"
import React from "react"
import { useParams } from "react-router"
import { GetCollectionCollectionMovies } from "typings"
import { FullscreenRouteParams } from "../fullscreen-route-params"

const isWorthRenderingCollection = (originMovieDbId: number, collectionMovies: ReadonlyArray<GetCollectionCollectionMovies>): boolean => {
  return collectionMovies.filter(m => m.posterPath && m.backdropPath && m.id !== originMovieDbId).length > 0
}

export const FullscreenMovieCollection = ({ collectionId }: FullscreenMovieCollectionProps): JSX.Element | null => {
  const { data } = useCollection(collectionId)
  const { movieDbId } = useParams<FullscreenRouteParams>()

  if (data?.collection && isWorthRenderingCollection(+movieDbId, data.collection.movies)) {
    return <ContentList id={`movie_collection_${collectionId}`} header={data.collection.name} items={data.collection.movies} />
  }

  return null
}

export interface FullscreenMovieCollectionProps {
  readonly collectionId: number
}

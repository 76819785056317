import React from "react"
import styled, { css } from "styled-components"

const avatarSize = "60px"

const avatarBaseCss = css`
  border-radius: 50%;
  width: ${avatarSize};
  height: ${avatarSize};
`

export const StyledFallbackAvatar = styled.span<FallbackAvatarProps>`
  ${avatarBaseCss};
  color: white;
  display: grid;
  place-content: center;
  cursor: pointer;
  opacity: 0.5;

  > * {
    cursor: inherit;
    font-family: Arial;
    font-size: 2rem;
  }
`

export const FallbackAvatar = ({ className, name }: FallbackAvatarProps): JSX.Element => {
  // Split on space, remove whitespace, take first and last parts, join together
  const initials = name
    .split(" ")
    .map(part => part[0]?.toUpperCase().replace(/[^\w]/g, ""))
    .filter(s => !!s)
    .filter((_, i, arr) => i === 0 || i === arr.length - 1)
    .join("")

  return (
    <StyledFallbackAvatar className={className} name={name}>
      <span>{initials}</span>
    </StyledFallbackAvatar>
  )
}

interface FallbackAvatarProps {
  readonly className?: string
  readonly name: string
}

import { QueryResult } from "@apollo/react-common"
import { useQuery } from "@apollo/react-hooks"
import { FetchPolicy } from "apollo-client"
import { GetNextEpisode, GetNextEpisodeVariables } from "typings"
import { MovieDbQueries } from "../graphql"

export const useNextEpisode = (episodeId: number, fetchPolicy?: FetchPolicy): QueryResult<GetNextEpisode, GetNextEpisodeVariables> =>
  useQuery<GetNextEpisode, GetNextEpisodeVariables>(MovieDbQueries.GetNextEpisode, {
    fetchPolicy,
    variables: {
      episodeId,
    },
  })

import { Button as MaterialButton, ButtonProps as MaterialButtonProps } from "@material-ui/core"
import React from "react"
import { ThemeProps } from "style/theme"
import styled from "styled-components"
import { LoadingSpinner } from "./loading-spinner"

export const ButtonLoadingSpinner = styled(LoadingSpinner)`
  color: ${({ theme }: ThemeProps): string => theme.primary.textOn} !important;
`

const StyledButton = styled(MaterialButton)`
  background: ${({ theme }: ThemeProps): string => theme.primary.color} !important;
  color: ${({ theme }: ThemeProps): string => theme.primary.textOn} !important;
  border: 2px solid !important;
  border-color: transparent !important;
  font-size: 1rem !important;
  transition: all 100ms !important;
  text-transform: none !important;

  > span.MuiButton-label {
    display: grid;
    gap: 16px;
    grid-auto-flow: column;
    white-space: nowrap;
  }

  :hover {
    background: transparent !important;
    border-color: ${({ theme }: ThemeProps): string => theme.primary.color} !important;

    ${ButtonLoadingSpinner} {
      color: ${({ theme }: ThemeProps): string => theme.primary.color} !important;
    }
  }
`

export const Button = ({ children, isLoading, ...props }: ButtonProps): JSX.Element => {
  return <StyledButton {...props}>{isLoading ? <ButtonLoadingSpinner size="small" /> : children}</StyledButton>
}

export interface ButtonProps extends MaterialButtonProps {
  readonly isLoading?: boolean
}

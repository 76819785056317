import { ContentLoadingSpinner } from "components/loading-spinner"
import { PaddedPageContent } from "components/page"
import { Body1 } from "components/typography"
import { useGenreWatchtimes } from "domain/content/hooks/use-genre-watchtimes"
import React from "react"
import { UseEffectReturn } from "typings"
import { usePageHistory } from "util/hooks"
import { ExploreRoute } from "../../../../constants"
import { ExploreContent } from "./explore-content"

const Explore = (): JSX.Element => {
  const { data, loading, error } = useGenreWatchtimes()
  const { pushHistory } = usePageHistory()

  React.useEffect((): UseEffectReturn => {
    pushHistory({
      title: "Explore",
      path: ExploreRoute,
    })
  }, [])

  if (loading) {
    return <ContentLoadingSpinner size="medium" />
  }

  if (error) {
    return (
      <PaddedPageContent>
        <Body1>Failed to load Explore content</Body1>
      </PaddedPageContent>
    )
  }

  if (data?.genreWatchTimes.length) {
    return <ExploreContent genres={data.genreWatchTimes} />
  }

  return (
    <PaddedPageContent>
      <Body1>Failed to load Explore content</Body1>
    </PaddedPageContent>
  )
}

export default Explore

import { MdIcon, MdIconType } from "components/md-icon"
import { useWatchPlayer } from "domain/watch/watch-player-context"
import React from "react"
import { ColorShift } from "style/color-shifting"
import { ThemeProps } from "style/theme"
import styled from "styled-components"

const FullscreenIcon = styled(MdIcon)<FullscreenIconProps>`
  color: ${({ theme, disabled }: ThemeProps & FullscreenIconProps): string =>
    disabled ? ColorShift.medium(theme.surface.textOn, theme.surface.color) : theme.surface.textOn};
  cursor: ${({ disabled }: FullscreenIconProps): string => (disabled ? "not-allowed" : "pointer")};
  font-size: 28px;
  :hover {
    color: ${({ disabled }: FullscreenIconProps): string => (disabled ? "" : "white")};
  }
`

export const FullscreenControl = ({ className }: React.HTMLAttributes<HTMLElement>): JSX.Element => {
  const { isFullscreen, isFullscreenAllowed, enterFullscreen, exitFullscreen } = useWatchPlayer()

  const icon: MdIconType = isFullscreen ? "fullscreen_exit" : "fullscreen"
  const title = icon === "fullscreen" ? "Fullscreen" : "Exit Fullscreen"

  const onFullscreenClicked = async (): Promise<void> => {
    if (isFullscreen) {
      await exitFullscreen()
    } else {
      await enterFullscreen()
    }
  }

  return React.useMemo(
    () => (
      <FullscreenIcon
        icon={icon}
        title={title}
        className={className}
        disabled={!isFullscreenAllowed}
        tabIndex={-1}
        onClick={(isFullscreenAllowed && onFullscreenClicked) || undefined}
      />
    ),
    [isFullscreen, isFullscreenAllowed, enterFullscreen, exitFullscreen],
  )
}

interface FullscreenIconProps {
  readonly disabled: boolean
}

import { FallbackAvatar } from "components/fallback-cast-image"
import { routerActions } from "connected-react-router"
import React from "react"
import { fadeAnimationCss } from "style/fade-animation"
import { ThemeProps } from "style/theme"
import styled from "styled-components"
import { GetMovieDetailsGetMovieDetailsCast, GetShowDetailsGetTvDetailsCast, UseEffectReturn, VirtualizationState, RootState } from "typings"
import { useContentNavigation } from "util/hooks"
import { PersonRoute } from "../../constants"
import { Body1 } from "../typography"
import { isContentItemRendered } from "./cast-list-calculations"
import { useStore } from "react-redux"

const CastListItemContainer = styled.div`
  display: inline-block;

  > * {
    margin-right: 24px;
  }
`

const CastListItemPosterWrapper = styled.div.attrs((props: CastListItemPosterWrapperProps) => ({
  style: {
    width: `${props.itemWidth}px`,
    height: `${props.itemHeight}px`,
  },
}))<CastListItemPosterWrapperProps>`
  position: relative;
  background-color: ${({ theme }: ThemeProps): string => theme.surface.color};
  border: 2px solid transparent;
  border-radius: 50%;
  overflow: hidden;
  box-sizing: border-box;
  display: grid;
  place-content: center;
  ${fadeAnimationCss};

  :hover {
    border: 2px solid ${({ theme }: ThemeProps): string => theme.primary.color};
  }
`

export const CastListItemPoster = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.4);

  :hover {
    filter: brightness(40%);
  }
`

const CastListItemLabels = styled.div`
  display: grid;
  align-items: center;
`

const CastListItemTitle = styled(Body1)`
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 4px;
`

export const CastListItem = ({ item: { castId, name, imageUrl }, itemWidth, itemHeight }: CastListItemProps): JSX.Element => {
  const itemRef = React.useRef<HTMLDivElement>(null)
  const store = useStore<RootState>()

  const { scrollerScrollLeft, scrollerClientWidth } = useContentNavigation()
  const [virtualizationState, setVirtualizationState] = React.useState<VirtualizationState>("none")

  const onItemClicked = (): void => {
    store.dispatch(routerActions.push(PersonRoute(castId)))
  }

  const isRendered = virtualizationState === "rendered"

  React.useEffect((): UseEffectReturn => {
    const { current } = itemRef
    if (current) {
      setVirtualizationState(isContentItemRendered(current, scrollerScrollLeft, scrollerClientWidth) ? "rendered" : "none")
    }
  }, [scrollerScrollLeft, scrollerClientWidth])

  return (
    <CastListItemContainer ref={itemRef}>
      <CastListItemPosterWrapper itemWidth={itemWidth} itemHeight={itemHeight}>
        {isRendered && ((imageUrl && <CastListItemPoster src={imageUrl} onClick={onItemClicked} />) || <FallbackAvatar name={name} />)}
      </CastListItemPosterWrapper>
      {isRendered && (
        <CastListItemLabels>
          <CastListItemTitle>{name}</CastListItemTitle>
        </CastListItemLabels>
      )}
    </CastListItemContainer>
  )
}

export interface CastListItemProps {
  readonly item: GetMovieDetailsGetMovieDetailsCast | GetShowDetailsGetTvDetailsCast
  readonly itemWidth: number
  readonly itemHeight: number
}

interface CastListItemPosterWrapperProps {
  readonly itemWidth: number
  readonly itemHeight: number
}

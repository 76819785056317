import { SubscriptionHookOptions, useSubscription } from "@apollo/react-hooks"
import { SubscribeMovieDownloadStatus, SubscribeMovieDownloadStatusVariables } from "typings"
import { MovieDbSubscriptions } from "../graphql"

export const useMovieDownloadProgress = (
  movieDbId: number,
  options: Omit<SubscriptionHookOptions<SubscribeMovieDownloadStatus, SubscribeMovieDownloadStatusVariables>, "variables">,
): void => {
  useSubscription<SubscribeMovieDownloadStatus, SubscribeMovieDownloadStatusVariables>(MovieDbSubscriptions.SubscribeMovieDownloadStatus, {
    variables: {
      movieDbId,
    },
    ...options,
  })
}

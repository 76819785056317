import { CustomScroller } from "components/custom-scroller"
import { Subtitle1 } from "components/typography"
import { NavbarContextState, useNavbar } from "domain/navbar/context/navbar-context"
import React from "react"
import styled from "styled-components"
import { ContentBaseType, Func1, UseEffectReturn } from "typings"
import { ContentNavigationProvider, useContentNavigation } from "util/hooks"
import { useInfiniteScrolling } from "util/hooks/use-infinite-scrolling"
import { ContentListItem } from "./content-list-item"

const GridContainer = styled.div<NavbarContextState>`
  overflow: hidden;
  padding: 32px 32px 0 ${({ navbarWidth }) => `${navbarWidth}px`};
  margin-bottom: 32px;
`

const GridHeader = styled(Subtitle1)<NavbarContextState>`
  padding: 8px 0;
`

// eslint-disable-next-line functional/prefer-readonly-type
const scrollRestorationMap: Map<string, number> = new Map([])

const InnerContentGrid = ({ id, className, header, items, onPage }: ContentGridProps): JSX.Element => {
  const { customScrollerRef, scrollerRef } = useContentNavigation()
  const navbar = useNavbar()

  const { page } = useInfiniteScrolling(customScrollerRef)
  React.useEffect((): void => onPage?.(page), [page])

  const onScroll = (e: React.UIEvent): void => {
    scrollRestorationMap.set(id, e.currentTarget.scrollTop)
  }

  React.useEffect((): UseEffectReturn => {
    if (scrollRestorationMap.has(id)) {
      customScrollerRef.current?.scrollTop(scrollRestorationMap.get(id) ?? 0)
    }
  }, [])

  return (
    <CustomScroller ref={customScrollerRef} onScroll={onScroll}>
      <GridContainer ref={scrollerRef} className={className} {...navbar}>
        {header && <GridHeader {...navbar}>{header}</GridHeader>}
        {items.map(item => (
          <ContentListItem key={item.id} item={item} />
        ))}
      </GridContainer>
    </CustomScroller>
  )
}

export const ContentGrid = (props: ContentGridProps): JSX.Element => (
  <ContentNavigationProvider>
    <InnerContentGrid {...props} />
  </ContentNavigationProvider>
)

export interface ContentGridProps {
  readonly id: string
  readonly className?: string
  readonly header?: string
  readonly items: ReadonlyArray<ContentBaseType>
  readonly onPage?: Func1<number, void>
}

import { getVideoFromReactPlayerRef } from "domain/watch/watch-player-utils"
import debounce from "lodash-es/debounce"
import React from "react"
import ReactPlayer from "react-player"
import { Nullable, UseEffectReturn } from "typings"

export const useVideoPlayback = (reactPlayerRef: React.RefObject<ReactPlayer>): UseVideoPlayback => {
  const [duration, setDuration] = React.useState<number>(0)
  const [currentTime, _setCurrentTime] = React.useState<number>(0)
  const [error, setError] = React.useState<Nullable<string | Event>>(null)
  const [seeking, setSeeking] = React.useState<boolean>(false)

  React.useEffect((): UseEffectReturn => {
    const video = getVideoFromReactPlayerRef(reactPlayerRef)
    if (video) {
      video.onloadeddata = () => setDuration(video.duration)
      video.ondurationchange = () => setDuration(video.duration)
      video.ontimeupdate = () => _setCurrentTime(video.currentTime)
      video.onseeking = () => setSeeking(true)
      video.onseeked = () => setSeeking(false)
      video.onerror = err => setError(err)

      return (): void => {
        video.onloadeddata = null
        video.ondurationchange = null
        video.ontimeupdate = null
        video.onerror = null
      }
    } else {
      console.error("No video found")
    }
  }, [])

  const debouncedSeek = React.useCallback(
    debounce((newCurrentTime: number) => {
      const reactPlayer = reactPlayerRef.current
      if (reactPlayer) {
        reactPlayer.seekTo(newCurrentTime, "seconds")
      }
    }, 200),
    [],
  )

  const setCurrentTime = React.useCallback(
    (newCurrentTime: number, seek: boolean = true): void => {
      _setCurrentTime(newCurrentTime)
      if (seek) {
        debouncedSeek(newCurrentTime)
      }
    },
    [_setCurrentTime],
  )

  return {
    duration,
    currentTime,
    setCurrentTime,
    seeking,
    error,
  }
}

export interface UseVideoPlayback {
  readonly duration: number
  readonly currentTime: number
  readonly setCurrentTime: (time: number, seek?: boolean) => void
  readonly seeking: boolean
  readonly error: Nullable<string | Event>
}

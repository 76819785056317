import { generatePath } from "react-router"
import { MovieDbMediaType, Nullable } from "typings"

export const typeMap: ReadonlyMap<MovieDbMediaType, string> = new Map([
  [MovieDbMediaType.Movie, "movies"],
  [MovieDbMediaType.Tv, "shows"],
])

export const getMovieDbMediaTypeFromRouteParam = (type: string): Nullable<MovieDbMediaType> => {
  return Array.from(typeMap.keys()).find(key => typeMap.get(key) === type)
}

const pathDigits = String.raw`(\d+)`
const pathShowOrMovie = `(${typeMap.get(MovieDbMediaType.Movie)}|${typeMap.get(MovieDbMediaType.Tv)})`

export const HomeRoute = "/"
export const LoginRoute = "/login"
export const LogoutRoute = "/logout"
export const ForgotRoute = "/forgot"
export const RegisterRoute = "/register"
export const MoviesRoute = "/movies"
export const ShowsRoute = "/shows"
export const ExploreRoute = "/explore"
export const TrendingRoute = "/trending"
export const LinkRoute = "/link"

const fullscreenTypeParam = "type"
const fullscreenIdParam = "movieDbId"
export const FullscreenRoutePath = `/:${fullscreenTypeParam}${pathShowOrMovie}/:${fullscreenIdParam}${pathDigits}`

const watchEpisodeIdParam = "episodeId"
export const WatchMoviePath = `/${typeMap.get(MovieDbMediaType.Movie)}/:${fullscreenIdParam}${pathDigits}/watch`
export const WatchShowPath = `/${typeMap.get(MovieDbMediaType.Tv)}/:${fullscreenIdParam}${pathDigits}/:${watchEpisodeIdParam}${pathDigits}/watch`

const personIdParam = "personId"
export const SearchRouteBase = "/search"
export const PersonRoutePath = `/person/:${personIdParam}`

export const FullscreenRoute = (type: MovieDbMediaType, movieDbId: number | string): string =>
  generatePath(FullscreenRoutePath, {
    [fullscreenTypeParam]: typeMap.get(type),
    [fullscreenIdParam]: movieDbId,
  })

export const PersonRoute = (personId: number | string): string =>
  generatePath(PersonRoutePath, {
    [personIdParam]: personId,
  })

export const SearchRoute = (query: string): string => `${SearchRouteBase}?q=${query}`

export const WatchMovieRoute = (movieDbId: number): string =>
  generatePath(WatchMoviePath, {
    [fullscreenIdParam]: movieDbId,
  })

export const WatchShowRoute = (movieDbId: number, episodeId: number): string =>
  generatePath(WatchShowPath, {
    [fullscreenIdParam]: movieDbId,
    [watchEpisodeIdParam]: episodeId,
  })

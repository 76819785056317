import { DropdownButtonItem, EllipsisMenu } from "components/dropdown"
import { LoadingSpinner } from "components/loading-spinner"
import { useDeleteMovie, useMovieDetails } from "domain/content/hooks"
import { useImportMovie } from "domain/content/hooks/use-import-movie"
import React from "react"
import { useParams } from "react-router"
import { AvailabilityStatus } from "typings"
import { FullscreenRouteParams } from "../fullscreen-route-params"

export const FullscreenMovieHeaderOverflowMenu = (): JSX.Element => {
  const { movieDbId } = useParams<FullscreenRouteParams>()
  const [deleteMovie, { loading: deleteLoading }] = useDeleteMovie()
  const [importMovie, { loading: importLoading }] = useImportMovie()
  const { data: movieDetails } = useMovieDetails(+movieDbId, "cache-only")

  const onDeleteClicked = (): void => {
    deleteMovie({
      variables: {
        movieDbId: +movieDbId,
      },
    })
  }

  const onImportClicked = (): void => {
    importMovie({
      variables: {
        movieDbId: +movieDbId,
      },
    })
  }

  const availabilityStatus = movieDetails?.getMovieDetails?.availabilityStatus.availabilityStatus
  const isAvailable = availabilityStatus === AvailabilityStatus.Available
  const isUnavailable = availabilityStatus === AvailabilityStatus.Unavailable
  const showLoading = deleteLoading || importLoading

  return showLoading ? (
    <LoadingSpinner size="tiny" />
  ) : (
    <EllipsisMenu>
      {isUnavailable && <DropdownButtonItem onClick={onImportClicked}>Import Movie</DropdownButtonItem>}
      {isAvailable && <DropdownButtonItem onClick={onDeleteClicked}>Delete Movie</DropdownButtonItem>}
    </EllipsisMenu>
  )
}

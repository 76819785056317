import { MdIcon } from "components/md-icon"
import React from "react"
import { ColorShift } from "style/color-shifting"
import { Theme, ThemeProps } from "style/theme"
import styled, { css, FlattenInterpolation, ThemedStyledProps } from "styled-components"
import { useContentNavigation } from "util/hooks"
import { navigationGridAreas } from "./navigation-container"

const chevronCss = (disabled: boolean): FlattenInterpolation<ThemedStyledProps<ThemeProps, Theme>> => css`
  color: ${({ theme }: ThemeProps): string => (disabled ? ColorShift.light(theme.surface.textOn, theme.surface.color) : theme.surface.textOn)};
  cursor: ${disabled ? "default" : "pointer"};
  pointer-events: ${disabled ? "none" : "all"};
  padding: 4px;
  font-size: 1.75rem;

  :hover {
    color: ${disabled ? "" : "white"};
  }
`

const NavigationArrowsContainer = styled.div`
  grid-area: ${navigationGridAreas.navigation};
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  justify-self: end;
  gap: 8px;
  padding-right: 12px;
`

const ChevronLeft = styled(MdIcon).attrs({ icon: "chevron_left" })<ChevronProps>`
  ${({ disabled }: ChevronProps) => chevronCss(disabled)};
`

const ChevronRight = styled(MdIcon).attrs({ icon: "chevron_right" })<ChevronProps>`
  ${({ disabled }: ChevronProps) => chevronCss(disabled)};
`

export const NavigationArrows = (): JSX.Element | null => {
  const { navigateLeft, navigateLeftDisabled, navigateRight, navigateRightDisabled } = useContentNavigation()

  return !navigateLeftDisabled || !navigateRightDisabled ? (
    <NavigationArrowsContainer>
      <ChevronLeft disabled={navigateLeftDisabled} onClick={navigateLeft} />
      <ChevronRight disabled={navigateRightDisabled} onClick={navigateRight} />
    </NavigationArrowsContainer>
  ) : null
}

interface ChevronProps {
  readonly disabled: boolean
}

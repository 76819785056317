import { ContentListItemPoster } from "components/content-list"
import { contentListItemAspectRatio } from "components/content-list/content-list-calculations"
import React from "react"
import styled from "styled-components"
import { fullscreenGridAreas } from "../fullscreen-container"

const posterWidth = 250
const posterHeight = 250 * contentListItemAspectRatio

const StyledFullscreenPoster = styled.div`
  grid-area: ${fullscreenGridAreas.poster};

  position: relative;
  width: ${posterWidth}px;
  height: ${posterHeight}px;
`

export const FullscreenShowPoster = ({ posterUrl }: FullscreenPosterProps): JSX.Element => {
  return (
    <StyledFullscreenPoster>
      <ContentListItemPoster src={posterUrl} />
    </StyledFullscreenPoster>
  )
}

interface FullscreenPosterProps {
  readonly posterUrl: string
}

import React from "react"
import { Redirect, useParams } from "react-router"
import { MovieDbMediaType } from "typings"
import { getMovieDbMediaTypeFromRouteParam, HomeRoute } from "../../../../constants"
import { FullscreenRouteParams } from "./fullscreen-route-params"
import { FullscreenMovie } from "./movie/fullscreen-movie"
import { FullscreenShow } from "./show/fullscreen-show"

const Fullscreen = (): JSX.Element => {
  const { type, movieDbId } = useParams<FullscreenRouteParams>()
  const movieDbMediaType = getMovieDbMediaTypeFromRouteParam(type)

  switch (movieDbMediaType) {
    case MovieDbMediaType.Movie:
      return <FullscreenMovie movieDbId={+movieDbId} />

    case MovieDbMediaType.Tv:
      return <FullscreenShow movieDbId={+movieDbId} />

    default: {
      return <Redirect to={HomeRoute} />
    }
  }
}

export default Fullscreen

import Color from "color"

const _cache = new Map<string, string>([])

enum ColorShiftVariant {
  faint = 0.96,
  lightest = 0.88,
  light = 0.8,
  medium = 0.4,
  dark = 0.2,
  full = 0,
}

const generateCacheKey = (colorCode: string, shift: ColorShiftVariant, shiftTarget: string): string => `${colorCode}-${shiftTarget}-${shift}`

const colorWithShift = (colorCode: string, shift: ColorShiftVariant, shiftTarget: string): string => {
  const cacheKey = generateCacheKey(colorCode, shift, shiftTarget)
  if (_cache.has(cacheKey)) {
    return _cache.get(cacheKey) as string
  }

  const shiftedColor = Color(colorCode)
    .mix(Color(shiftTarget), shift)
    .hex()
  _cache.set(cacheKey, shiftedColor)

  return shiftedColor
}

const colorShiftVariantKeys: ReadonlyArray<ColorShiftKeys> = Object.keys(ColorShiftVariant) as ReadonlyArray<ColorShiftKeys>

export const ColorShift: ColorShift = colorShiftVariantKeys.reduce<ColorShift>((result: ColorShift, current: ColorShiftKeys): ColorShift => {
  result[current] = (colorCode: string, shiftTarget: string): string => colorWithShift(colorCode, ColorShiftVariant[current], shiftTarget)
  return result
}, {} as ColorShift)

export type ColorShiftKeys = keyof typeof ColorShiftVariant

type ColorShift = {
  [key in ColorShiftKeys]: (colorCode: string, shiftTarget: string) => string
}

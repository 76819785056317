import { createHashHistory, History } from "history"

// eslint-disable-next-line functional/no-let
let _history: History = (null as unknown) as History

export const createHistory = (): History => {
  _history = createHashHistory()
  return _history
}

// eslint-disable-next-line functional/no-let
export const getHistory = (): History => _history

import { CompleteAuthTokenEndpoint } from "constants/endpoints"
import { CompleteAuthTokenRequest, CompleteAuthTokenResponse } from "typings"
import { HttpClient } from "../api-client"

export const completeAuthToken = async (httpClient: HttpClient, request: CompleteAuthTokenRequest): Promise<CompleteAuthTokenResponse> => {
  /* eslint-disable functional/no-let */
  let success: boolean = true
  let statusCode: number = 200
  let error: string | undefined = undefined
  /* eslint-enable functional/no-let */

  try {
    const response = await httpClient.post(CompleteAuthTokenEndpoint, null, request, false)
    statusCode = response.status
  } catch (ex) {
    error = ex.response.data
    success = false
  }

  const completeAuthTokenResponse: CompleteAuthTokenResponse = {
    success,
    statusCode,
    error,
  }

  return completeAuthTokenResponse
}

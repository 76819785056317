import { Body1 } from "components/typography"
import { useWatchPlayer } from "domain/watch/watch-player-context"
import React from "react"
import { formatPlaybackTime } from "util/string"

export const PlaybackLabels = ({ className }: React.HTMLAttributes<HTMLElement>): JSX.Element => {
  const { currentTime, duration } = useWatchPlayer()

  return React.useMemo(
    () => (
      <Body1 className={className}>
        {formatPlaybackTime(currentTime)} / {formatPlaybackTime(duration)}
      </Body1>
    ),
    [currentTime, duration],
  )
}

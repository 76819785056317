import { APP_ROOT_ID } from "index"
import { uniq } from "lodash-es"
import React from "react"
import { createPortal } from "react-dom"
import { fullSizeCss } from "style/css"
import { ThemeProps } from "style/theme"
import styled from "styled-components"
import { UseEffectReturn } from "typings"
import { useBackgroundSlideshow } from "./background-slideshow-context"

const imgSeconds = 12
const fadeSeconds = 2
// const scaleFactor = 1.1

const SlideshowContainer = styled.div<SlideshowContainerProps>`
  ${fullSizeCss};
  position: fixed;
  background: ${({ theme }: ThemeProps): string => theme.background.color};
  z-index: 1;
`

/* transform: ${({ isAnimating }: SlideshowImageProps): string => `scale(${isAnimating ? `${scaleFactor}` : "1"})`}; */
/* transform ${imgSeconds}s linear; */
const SlideshowImage = styled.div<SlideshowImageProps>`
  opacity: ${({ isActive }: SlideshowImageProps): number => (isActive ? 0.15 : 0)};

  transition: opacity ${fadeSeconds}s;

  ${fullSizeCss};

  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-repeat: no-repeat;
`

export const BackgroundSlideshow = ({ backgroundImages }: BackgroundSlideshowProps): JSX.Element => {
  const { backgroundImageOverride } = useBackgroundSlideshow()
  const [index, setIndex] = React.useState<number>(0)
  const [rendered, setRendered] = React.useState<boolean>(false)
  const [animating, setAnimating] = React.useState<boolean>(false)
  const [imageLoaded, setImageLoaded] = React.useState<boolean>(false)
  const appRoot = React.useMemo(() => document.querySelector(`#${APP_ROOT_ID}`) as HTMLElement, [])

  React.useEffect((): UseEffectReturn => {
    const incrementIndex = (): void => {
      if (!backgroundImageOverride) {
        setIndex((index + 1) % backgroundImages.length)
      }
    }

    const interval = setInterval(incrementIndex, imgSeconds * 1000)
    return (): void => {
      clearInterval(interval)
    }
  }, [index, backgroundImages, backgroundImageOverride])

  React.useEffect((): UseEffectReturn => {
    if (backgroundImages.length) {
      setTimeout(() => setRendered(true), 100)
    }
  }, [backgroundImages, backgroundImageOverride])

  React.useEffect((): UseEffectReturn => {
    if (backgroundImages.length > 0 && rendered) {
      setTimeout(() => setAnimating(true), 100)
    }
  }, [backgroundImages, backgroundImageOverride, rendered])

  const onImageLoaded = (): void => {
    if (!imageLoaded) {
      setImageLoaded(true)
    }
  }

  const imagesToRender = uniq([backgroundImageOverride || "", backgroundImages[index - 1], backgroundImages[index], backgroundImages[index + 1]]).filter(
    i => !!i,
  )

  return createPortal(
    <SlideshowContainer imageCount={backgroundImages.length}>
      {imagesToRender.map(img => (
        <SlideshowImage
          key={img}
          src={img}
          isActive={!!backgroundImageOverride ? img === backgroundImageOverride : img === imagesToRender[1]}
          isAnimating={!!backgroundImageOverride ? false : animating && (img === imagesToRender[0] || img === imagesToRender[1])}
          onLoad={onImageLoaded}
        />
      ))}
    </SlideshowContainer>,
    appRoot,
  )
}

export interface BackgroundSlideshowProps {
  readonly backgroundImages: ReadonlyArray<string>
}

interface SlideshowContainerProps {
  readonly imageCount: number
}

interface SlideshowImageProps {
  readonly src: string
  readonly isActive: boolean
  readonly isAnimating: boolean
}

import { useBackgroundSlideshow } from "components/background-slideshow"
import { CastList } from "components/cast-list"
import { CustomScroller } from "components/custom-scroller"
import { ContentLoadingSpinner } from "components/loading-spinner"
import { PaddedPageContent } from "components/page"
import { ReturnToLink } from "components/return-to-link"
import { Separator } from "components/separator"
import { Body1 } from "components/typography"
import { useShowDetails } from "domain/content/hooks"
import { useNavbar } from "domain/navbar/context/navbar-context"
import React from "react"
import Helmet from "react-helmet"
import { ColorShift } from "style/color-shifting"
import { ThemeProps } from "style/theme"
import styled from "styled-components"
import { UseEffectReturn } from "typings"
import { usePageHistory } from "util/hooks"
import { pluralize } from "util/string"
import { FullscreenContainer, FullscreenOverview, FullscreenScrollers } from "../fullscreen-container"
import { FullscreenDetails } from "../fullscreen-details"
import { FullscreenShowEpisodeScroller } from "./fullscreen-show-episode-scroller"
import { FullscreenShowHeader } from "./fullscreen-show-header"
import { FullscreenShowPoster } from "./fullscreen-show-poster"
import { FullscreenSimilarShows } from "./fullscreen-similar-shows"

const FullscreenInfo = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  column-gap: 8px;
  white-space: nowrap;
`

const InfoSeparator = styled(Separator)`
  background-color: ${({ theme }: ThemeProps): string => ColorShift.medium(theme.surface.textOn, theme.surface.color)};
`

export const FullscreenShow = ({ movieDbId }: FullscreenShowProps): JSX.Element => {
  const navbar = useNavbar()
  const { data, loading, error } = useShowDetails(movieDbId)
  const { setBackgroundImageOverride } = useBackgroundSlideshow()

  const { previousPage } = usePageHistory()

  React.useEffect((): UseEffectReturn => {
    if (data?.getTvDetails?.backdropPath) {
      setBackgroundImageOverride(data.getTvDetails.backdropPath)
    }

    return (): void => {
      setBackgroundImageOverride(null)
    }
  }, [data?.getTvDetails?.backdropPath])

  if (error) {
    console.error(error)
    return (
      <PaddedPageContent>
        <Body1>Failed to load show details</Body1>
      </PaddedPageContent>
    )
  }

  if (loading) {
    return <ContentLoadingSpinner size="medium" />
  }

  if (data?.getTvDetails) {
    const {
      getTvDetails: { title, released, posterPath, description, cast, numberOfSeasons, numberOfEpisodes, seasons, nextEpisode },
    } = data

    const seasonsLabel = pluralize("season", numberOfSeasons)
    const episodesLabel = pluralize("episode", numberOfEpisodes)

    const info = (
      <FullscreenInfo>
        <Body1 variant="medium">
          {numberOfSeasons} {seasonsLabel}
        </Body1>
        <InfoSeparator radius={4} />
        <Body1 variant="medium">
          {numberOfEpisodes} {episodesLabel}
        </Body1>
      </FullscreenInfo>
    )

    return (
      <CustomScroller>
        <>
          <Helmet>
            <title>Mattflix - {title}</title>
          </Helmet>
          {previousPage && (
            <PaddedPageContent>
              <ReturnToLink page={previousPage} />
            </PaddedPageContent>
          )}
          <FullscreenContainer>
            <FullscreenOverview {...navbar}>
              <FullscreenShowHeader title={title} releaseDate={released} info={info} />
              <FullscreenShowPoster posterUrl={posterPath || ""} />
              <FullscreenDetails overview={description} />
            </FullscreenOverview>
            <FullscreenScrollers>
              <FullscreenShowEpisodeScroller seasons={seasons} currentEpisode={nextEpisode} />
              {cast.length > 0 && <CastList header="Cast" items={cast} />}
              <FullscreenSimilarShows movieDbId={movieDbId} />
            </FullscreenScrollers>
          </FullscreenContainer>
        </>
      </CustomScroller>
    )
  }
  return (
    <PaddedPageContent>
      <Body1>Failed to load show details</Body1>
    </PaddedPageContent>
  )
}

export interface FullscreenShowProps {
  readonly movieDbId: number
}

import React from "react"
import { UseEffectReturn } from "typings"

export const useControlsVisibility = (overlayRef: React.RefObject<HTMLDivElement>): UseControlsVisibility => {
  const [isControlsVisible, setIsControlsVisible] = React.useState<boolean>(false)
  const timeout = React.useRef<number | null>(null)

  React.useEffect((): UseEffectReturn => {
    const overlay = overlayRef.current
    if (overlay) {
      const destroyTimeout = (): void => {
        if (timeout.current != null) {
          clearTimeout(timeout.current)
        }
      }

      const createTimeout = (): void => {
        timeout.current = setTimeout(() => setIsControlsVisible(false), 1500)
      }

      overlay.onmousemove = () => {
        if (!isControlsVisible) {
          setIsControlsVisible(true)
        }
        destroyTimeout()
        createTimeout()
      }

      overlay.onmouseleave = () => {
        destroyTimeout()
        setIsControlsVisible(false)
      }

      return (): void => {
        overlay.onmousemove = null
      }
    }
  }, [isControlsVisible])

  return {
    isControlsVisible,
  }
}

export interface UseControlsVisibility {
  readonly isControlsVisible: boolean
}

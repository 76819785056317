import { Slider } from "@material-ui/core"
import { useWatchPlayer } from "domain/watch/watch-player-context"
import React from "react"
import { ThemeProps } from "style/theme"
import styled from "styled-components"
import { UseEffectReturn } from "typings"

const LEFT_ARROW_KEYCODE = 37
const RIGHT_ARROW_KEYCODE = 39

const ProgressBarWrapper = styled.div`
  border-radius: 4px;
  cursor: pointer;

  .MuiSlider-colorPrimary {
    color: ${({ theme }: ThemeProps): string => theme.primary.color} !important;
  }

  .MuiSlider-thumb {
    transform: translateY(-1px);

    :hover {
      box-shadow: 0 0 0 8px ${({ theme }: ThemeProps): string => `${theme.primary.color}29`} !important;
    }
  }

  .MuiSlider-rail,
  .MuiSlider-track {
    transform: translateY(-50%);
  }

  :hover {
    .MuiSlider-rail,
    .MuiSlider-track {
      height: 4px !important;
    }

    .MuiSlider-thumb {
      width: 14px;
      height: 14px;
      transform: translateY(-2px);
    }
  }
`

export const PlaybackSlider = ({ className }: React.HTMLAttributes<HTMLElement>): JSX.Element => {
  const { duration, currentTime, setCurrentTime } = useWatchPlayer()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSliderValueChanged: any = (_e: React.ChangeEvent<{}>, newPlaybackTime: number): void => {
    setCurrentTime(newPlaybackTime)
  }

  React.useEffect((): UseEffectReturn => {
    const onArrowPressed = async (e: KeyboardEvent): Promise<void> => {
      switch (e.keyCode) {
        case LEFT_ARROW_KEYCODE:
          setCurrentTime(Math.max(currentTime - 10, 0))
          break
        case RIGHT_ARROW_KEYCODE:
          setCurrentTime(Math.min(currentTime + 10, duration))
          break
      }
    }

    document.body.addEventListener("keydown", onArrowPressed)

    return (): void => {
      document.body.removeEventListener("keydown", onArrowPressed)
    }
  }, [currentTime, setCurrentTime])

  return React.useMemo(
    () => (
      <ProgressBarWrapper className={className}>
        <Slider value={currentTime} max={duration} onChange={onSliderValueChanged} tabIndex={-1} />
      </ProgressBarWrapper>
    ),
    [duration, currentTime, setCurrentTime],
  )
}

import { Headline4, Headline6 } from "components/typography"
import { format } from "date-fns"
import React from "react"
import { lightRuleColor, ThemeProps } from "style/theme"
import styled from "styled-components"
import { DateFormat } from "../../../../../constants"
import { fullscreenGridAreas } from "../fullscreen-container"

const HeaderContainer = styled.div`
  grid-area: ${fullscreenGridAreas.header};

  display: grid;
  gap: 4px;
  border-bottom: 1px solid ${({ theme }: ThemeProps): string => lightRuleColor(theme, "AA")};
  padding: 8px 16px;
`

export const FullscreenShowHeader = ({ title, releaseDate, info }: FullscreenHeaderProps): JSX.Element => {
  const formattedDate = format(new Date(releaseDate), DateFormat.LongMonthYear)

  return (
    <HeaderContainer>
      <Headline4>{title}</Headline4>
      <Headline6>{formattedDate}</Headline6>
      {info}
    </HeaderContainer>
  )
}

export interface FullscreenHeaderProps {
  readonly title: string
  readonly releaseDate: string
  readonly info: React.ReactNode
}

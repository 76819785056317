import { SubscriptionHookOptions, useSubscription } from "@apollo/react-hooks"
import { SubscribeSeasonDownloadStatus, SubscribeSeasonDownloadStatusVariables } from "typings"
import { MovieDbSubscriptions } from "../graphql"

export const useSeasonDownloadProgress = (
  movieDbId: number,
  seasonNumber: number,
  options: Omit<SubscriptionHookOptions<SubscribeSeasonDownloadStatus, SubscribeSeasonDownloadStatusVariables>, "variables">,
): void => {
  useSubscription<SubscribeSeasonDownloadStatus, SubscribeSeasonDownloadStatusVariables>(MovieDbSubscriptions.SubscribeSeasonDownloadStatus, {
    variables: {
      movieDbId,
      seasonNumber,
    },
    ...options,
  })
}

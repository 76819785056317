import { Button } from "components/button"
import { routerActions } from "connected-react-router"
import { useRequestMovie, useUnrequestMovie } from "domain/content/hooks"
import React from "react"
import styled from "styled-components"
import { AvailabilityStatus, GetMovieDetailsGetMovieDetails, Nullable, SubscribeMovieDownloadStatusMovieTorrentStatus, RootState } from "typings"
import { WatchMovieRoute } from "../../../../../constants"
import { useStore } from "react-redux"

const MovieActionButtonText = styled.span`
  opacity: 1;
`
const MovieActionButtonHoveredText = styled.span`
  opacity: 0.9999;
`

const MovieActionButton = styled(Button)`
  height: 40px;
  width: 200px;
  padding: 0 24px !important;

  ${MovieActionButtonHoveredText} {
    display: none;
  }

  :hover {
    ${MovieActionButtonHoveredText} {
      display: initial;
    }

    ${MovieActionButtonText} {
      display: none;
    }
  }
`

export const FullscreenMovieActionButton = ({
  movie: {
    availabilityStatus: { availabilityStatus },
    id,
  },
  downloadProgress,
}: FullscreenMovieActionButtonProps): JSX.Element => {
  const store = useStore<RootState>()
  const isAvailable = availabilityStatus === AvailabilityStatus.Available
  const isRequested = [AvailabilityStatus.Requested, AvailabilityStatus.DownloadInProgress].includes(availabilityStatus)

  const [requestMovie, { loading: requestLoading }] = useRequestMovie()
  const [unrequestMovie, { loading: unrequestLoading }] = useUnrequestMovie()

  const getPrimaryButtonText = (): string => {
    if (isAvailable) {
      return "Watch"
    } else if (isRequested) {
      return "Cancel Request"
    } else {
      return "Request"
    }
  }

  const getSecondaryButtonText = (): string => {
    return downloadProgress?.progress ?? getPrimaryButtonText()
  }

  const onActionClicked = (): void => {
    if (isAvailable) {
      store.dispatch(routerActions.push(WatchMovieRoute(id)))
    } else if (isRequested) {
      unrequestMovie({
        variables: {
          movieDbId: id,
        },
      })
    } else {
      requestMovie({
        variables: {
          movieDbId: id,
        },
      })
    }
  }

  return (
    <MovieActionButton onClick={onActionClicked} isLoading={requestLoading || unrequestLoading}>
      <MovieActionButtonText>{downloadProgress ? getSecondaryButtonText() : getPrimaryButtonText()}</MovieActionButtonText>
      <MovieActionButtonHoveredText>{downloadProgress ? getPrimaryButtonText() : getSecondaryButtonText()}</MovieActionButtonHoveredText>
      {/* {downloadProgress && <ButtonLoadingSpinner size="extrasmall" variant="determinate" value={downloadProgress.percentDone * 100} />} */}
    </MovieActionButton>
  )
}

export interface FullscreenMovieActionButtonProps {
  readonly movie: GetMovieDetailsGetMovieDetails
  readonly downloadProgress: Nullable<SubscribeMovieDownloadStatusMovieTorrentStatus>
}

import { QueryResult } from "@apollo/react-common"
import { useQuery } from "@apollo/react-hooks"
import { WatchGraphqlQueries } from "domain/watch/graphql"
import { GetMoviePlaybackTime, GetMoviePlaybackTimeVariables } from "typings"

export const useGetMoviePlaybackTime = (movieDbId: number): QueryResult<GetMoviePlaybackTime, GetMoviePlaybackTimeVariables> =>
  useQuery<GetMoviePlaybackTime, GetMoviePlaybackTimeVariables>(WatchGraphqlQueries.GetMoviePlaybackTime, {
    variables: {
      movieDbId,
    },
  })

import { FieldValidator } from "final-form"

const validUsernamePattern = String.raw`^[a-zA-Z0-9]{4,255}$`

export const validateUsername: FieldValidator<string> = (username: string): string | undefined => {
  const matchesPattern = new RegExp(validUsernamePattern).test(username)
  if (!matchesPattern) {
    return "Username must be 4-255 characters"
  }

  return undefined
}

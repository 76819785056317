import { RegisterEndpoint } from "constants/endpoints"
import { RegisterErrorResponse, RegisterRequest, RegisterResponse } from "typings"
import { isAxiosException, HttpClient } from "../api-client"

export const register = async (httpClient: HttpClient, request: RegisterRequest): Promise<RegisterResponse> => {
  /* eslint-disable functional/no-let */
  let success: boolean = true
  let statusCode: number = 200
  let error: string | undefined = undefined
  /* eslint-enable functional/no-let */

  try {
    const response = await httpClient.post(RegisterEndpoint, null, request, false)
    statusCode = response.status
  } catch (ex) {
    if (isAxiosException<RegisterErrorResponse>(ex)) {
      statusCode = ex.response?.status ?? 400
      error = ex.response?.data.error
    }
    success = false
  }

  const registerResponse: RegisterResponse = {
    success,
    statusCode,
    error,
  }

  return registerResponse
}

import { ContentList } from "components/content-list"
import { useContentForGenre } from "domain/content/hooks"
import { useReferenceData } from "domain/reference_data/hooks"
import uniqBy from "lodash-es/uniqBy"
import React from "react"
import { Func0, GetContentForGenre, GetContentForGenreVariables, Nullable, UseEffectReturn } from "typings"

const mergeContentResults = (currentContent: Nullable<GetContentForGenre>, newContent: Nullable<GetContentForGenre>): GetContentForGenre => {
  if (currentContent && newContent) {
    return {
      ...currentContent,
      contentForGenre: uniqBy([...currentContent.contentForGenre, ...newContent.contentForGenre], "id"),
    }
  }

  return (
    currentContent || {
      contentForGenre: [],
    }
  )
}

export const ExploreGenreList = ({ genreId, onLoaded }: ExploreGenreListProps): JSX.Element | null => {
  const { data: referenceData } = useReferenceData()
  const { data, fetchMore } = useContentForGenre(genreId)

  React.useEffect((): UseEffectReturn => {
    if (!!data?.contentForGenre) {
      onLoaded()
    }
  }, [data?.contentForGenre])

  if (referenceData && data?.contentForGenre && data.contentForGenre.length > 5) {
    const genreName = referenceData.genres.find(g => g.id === genreId)?.name ?? "Other"

    const onGenreListPaged = (nextPage: number): void => {
      const variables: GetContentForGenreVariables = {
        genreId,
        page: nextPage,
      }

      fetchMore({
        variables,
        updateQuery: (currentContent: GetContentForGenre, { fetchMoreResult }): GetContentForGenre => {
          if (!currentContent) {
            return currentContent
          }

          return mergeContentResults(currentContent, fetchMoreResult)
        },
      })
    }

    return <ContentList id={`explore_genre_${genreId}`} header={genreName} items={data.contentForGenre} onPage={onGenreListPaged} />
  }

  return null
}

interface ExploreGenreListProps {
  readonly genreId: number
  readonly onLoaded: Func0<void>
}

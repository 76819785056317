import { LoadingSpinner } from "components/loading-spinner"
import { checkSession } from "domain/network/requests"
import React from "react"
import { Redirect, useLocation } from "react-router"
import styled from "styled-components"
import { Nullable } from "typings"
import { LoginRoute } from "./constants"
import { useHttpClient } from "domain/network/api-client"
import { useStore } from "react-redux"
import { userActions } from "domain/user/store/actions"

const AuthLoadingSpinner = styled(LoadingSpinner)`
  margin: 8rem auto;
`

export const AuthBoundary = ({ children }: React.HTMLAttributes<HTMLElement>): JSX.Element => {
  const httpClient = useHttpClient()
  const store = useStore()
  const { pathname, search } = useLocation()
  const [authResult, setAuthResult] = React.useState<Nullable<boolean>>(undefined)

  React.useEffect((): void => {
    const runEffect = async (): Promise<void> => {
      const result = await checkSession(httpClient)
      setAuthResult(result.statusCode === 200)
      store.dispatch(userActions.setUsername(result.username))
    }
    runEffect()
  }, [])

  if (authResult == null) {
    return <AuthLoadingSpinner size="medium" />
  }

  if (authResult) {
    return <>{children}</>
  }

  return <Redirect to={`${LoginRoute}?next=${encodeURIComponent(`${pathname}${search}`)}`} />
}

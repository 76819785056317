import { MdIcon } from "components/md-icon"
import { Separator } from "components/separator"
import { Headline6, Subtitle1 } from "components/typography"
import { routerActions } from "connected-react-router"
import { useNextEpisode, useShowEpisodeDetails } from "domain/content/hooks"
import React from "react"
import { useLocation, useParams } from "react-router"
import { ThemeProps } from "style/theme"
import styled from "styled-components"
import { Levels } from "../../../constants"
import { navigateToNextEpisode } from "./hooks/watch-show-player-utils"
import { WatchEpisodeRouteParams } from "./watch-episode-route-params"
import { useStore } from "react-redux"
import { RootState } from "typings"

const headerGridAreas = {
  back: "back",
  title: "title",
  episode: "episode",
  nextEpisode: "nextEpisode",
}

const headerGrid = `
  "${headerGridAreas.back} ${headerGridAreas.title} ${headerGridAreas.nextEpisode}" auto
  "${headerGridAreas.back} ${headerGridAreas.episode} ${headerGridAreas.nextEpisode}" auto / auto 1fr auto
`

const Header = styled.div`
  display: grid;
  align-content: start;
  align-items: center;
  grid: ${headerGrid};

  column-gap: 8px;
  transition: opacity 0.6s;

  z-index: ${Levels.ElevatedContent};
`

const CircledIconBase = styled(MdIcon)`
  font-size: 36px;

  border: 2px solid;
  border-radius: 50%;
  cursor: pointer;

  border-color: ${({ theme }: ThemeProps): string => theme.surface.textOn};
  color: ${({ theme }: ThemeProps): string => theme.surface.textOn};

  :hover {
    border-color: white;
    color: white;
  }
`

const BackIcon = styled(CircledIconBase)`
  grid-area: ${headerGridAreas.back};
`

const Title = styled(Headline6)`
  grid-area: ${headerGridAreas.title};
`

const Episode = styled(Subtitle1).attrs({ as: "span" })`
  grid-area: ${headerGridAreas.episode};
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  gap: 8px;
`

const EpisodeSeparator = styled(Separator)`
  background-color: ${({ theme }: ThemeProps): string => theme.surface.textOn};
`

const NextEpisodeContainer = styled.div`
  grid-area: ${headerGridAreas.nextEpisode};

  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  * {
    cursor: inherit;
  }

  :hover {
    ${Headline6}, ${CircledIconBase} {
      border-color: white;
      color: white;
    }
  }
`

export const WatchShowPlayerOverlayHeader = ({ className, isVisible }: WatchMoviePlayerOverlayHeaderProps): JSX.Element => {
  const { episodeId } = useParams<WatchEpisodeRouteParams>()
  const { data } = useShowEpisodeDetails(+episodeId, "cache-first")
  const { data: nextEpisode } = useNextEpisode(+episodeId, "cache-first")
  const { pathname } = useLocation()
  const store = useStore<RootState>()

  const onBackClicked = (): void => {
    const route = pathname.replace(/\/\d+\/watch/, "")
    store.dispatch(routerActions.push(route))
  }

  const onNextEpisodeClicked = (): void => {
    if (nextEpisode?.getNextEpisode?.id != null) {
      navigateToNextEpisode(pathname, +episodeId, nextEpisode.getNextEpisode.id, store)
    }
  }

  return (
    <Header className={className}>
      {isVisible && (
        <>
          <BackIcon icon="navigate_before" onClick={onBackClicked} title="Back" />
          {data?.getTvEpisodeDetails && <Title>{data.getTvEpisodeDetails.title}</Title>}
          {data?.getTvEpisodeDetails && (
            <Episode>
              Season {data.getTvEpisodeDetails.tvSeason.seasonNum}
              <EpisodeSeparator radius={4} />
              Episode {data.getTvEpisodeDetails.episodeNum}
            </Episode>
          )}
          {nextEpisode?.getNextEpisode != null && (
            <NextEpisodeContainer onClick={onNextEpisodeClicked}>
              <Headline6>Next Episode</Headline6>
              <CircledIconBase icon="navigate_next" title="Next Episode" />
            </NextEpisodeContainer>
          )}
        </>
      )}
    </Header>
  )
}

export interface WatchMoviePlayerOverlayHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  readonly isVisible: boolean
}

import { QueryResult } from "@apollo/react-common"
import { useQuery } from "@apollo/react-hooks"
import { GetShowDetails, GetShowDetailsVariables } from "typings/_graphql"
import { MovieDbQueries } from "../graphql"

export const useShowDetails = (movieDbId: number): QueryResult<GetShowDetails> =>
  useQuery<GetShowDetails, GetShowDetailsVariables>(MovieDbQueries.GetShowDetails, {
    variables: {
      movieDbId,
    },
  })

import { QueryResult } from "@apollo/react-common"
import { useQuery } from "@apollo/react-hooks"
import { Search, SearchVariables } from "typings"
import { MovieDbQueries } from "../graphql"

export const useSearch = (searchPhrase: string): QueryResult<Search> =>
  useQuery<Search, SearchVariables>(MovieDbQueries.Search, {
    variables: {
      searchPhrase,
    },
  })

import React from "react"
import Helmet from "react-helmet"
import { CastManager } from "./cast-manager"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CastManagerContext = React.createContext<CastManager>({} as any)

export const CastManagerProvider = (props: React.HTMLAttributes<HTMLElement>): JSX.Element => {
  const [isAvailable, setIsAvailable] = React.useState<boolean>(false)
  const castManager = new CastManager(isAvailable)

  window["__onGCastApiAvailable"] = castingIsAvailable => {
    setIsAvailable(castingIsAvailable)
  }

  return (
    <>
      <Helmet>
        <script src="//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1"></script>
      </Helmet>
      <CastManagerContext.Provider value={castManager} {...props} />
    </>
  )
}

export const useCastManager = (): CastManager => React.useContext(CastManagerContext)

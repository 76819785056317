import React from "react"
import { Helmet } from "react-helmet"
import { pageTitle } from "util/string"

export const titled = (title: string, component: JSX.Element): JSX.Element => (
  <>
    <Helmet>
      <title>{pageTitle(title)}</title>
    </Helmet>
    {component}
  </>
)

import { MdIcon } from "components/md-icon"
import { Headline6 } from "components/typography"
import { routerActions } from "connected-react-router"
import { FullscreenRouteParams } from "domain/content/components/fullscreen/fullscreen-route-params"
import { useMovieDetails } from "domain/content/hooks"
import React from "react"
import { useLocation, useParams } from "react-router"
import { ThemeProps } from "style/theme"
import styled from "styled-components"
import { Levels } from "../../../constants"
import { useStore } from "react-redux"
import { RootState } from "typings"

const headerGridAreas = {
  back: "back",
  title: "title",
  nextEpisode: "nextEpisode",
}

const headerGrid = `
  "${headerGridAreas.back} ${headerGridAreas.title} ${headerGridAreas.nextEpisode}" auto
  "${headerGridAreas.back} ${headerGridAreas.title} ." auto / auto 1fr auto
`

const Header = styled.div`
  display: grid;
  align-content: start;
  align-items: center;
  grid: ${headerGrid};

  column-gap: 8px;
  transition: opacity 0.6s;

  z-index: ${Levels.ElevatedContent};
`

const BackIcon = styled(MdIcon)`
  grid-area: ${headerGridAreas.back};
  font-size: 36px;

  border: 2px solid;
  border-radius: 50%;
  cursor: pointer;

  border-color: ${({ theme }: ThemeProps): string => theme.surface.textOn};
  color: ${({ theme }: ThemeProps): string => theme.surface.textOn};

  :hover {
    border-color: white;
    color: white;
  }
`

const Title = styled(Headline6)`
  grid-area: ${headerGridAreas.title};
`

export const WatchMoviePlayerOverlayHeader = ({ className, isVisible }: WatchMoviePlayerOverlayHeaderProps): JSX.Element => {
  const { movieDbId } = useParams<FullscreenRouteParams>()
  const { data } = useMovieDetails(+movieDbId, "cache-first")
  const { pathname } = useLocation()
  const store = useStore<RootState>()

  const onBackClicked = (): void => {
    const route = pathname.replace("/watch", "")
    store.dispatch(routerActions.push(route))
  }

  return (
    <Header className={className}>
      {isVisible && (
        <>
          <BackIcon icon="navigate_before" onClick={onBackClicked} />
          {data?.getMovieDetails && <Title>{data.getMovieDetails.title}</Title>}
        </>
      )}
    </Header>
  )
}

export interface WatchMoviePlayerOverlayHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  readonly isVisible: boolean
}

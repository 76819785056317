import { MdIconType } from "components/md-icon"
import React from "react"
import { Dropdown } from "./dropdown"
import { IconButtonProps, IconDropdownButton } from "./dropdown-button"
import { DropdownItems } from "./dropdown-items"

const iconHorizontal: MdIconType = "more_horiz"
const iconVertical: MdIconType = "more_vert"

const getMdIconForOrientation = (orientation: EllipsisMenuOrientation): MdIconType => (orientation === "horizontal" ? iconHorizontal : iconVertical)

export const EllipsisMenu = ({ className, children, orientation = "vertical" }: EllipsisMenuProps): JSX.Element => (
  <Dropdown className={className}>
    <IconDropdownButton icon={getMdIconForOrientation(orientation)} />
    <DropdownItems>{children}</DropdownItems>
  </Dropdown>
)

type EllipsisMenuOrientation = "horizontal" | "vertical"

export interface EllipsisMenuProps extends IconButtonProps {
  readonly children: React.ReactNode | ReadonlyArray<React.ReactNode>
  readonly className?: string
  readonly orientation?: EllipsisMenuOrientation
}

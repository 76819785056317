import { MdIcon, MdIconType } from "components/md-icon"
import { useWatchPlayer } from "domain/watch/watch-player-context"
import React from "react"
import { ThemeProps } from "style/theme"
import styled from "styled-components"
import { UseEffectReturn } from "typings"

const SPACEBAR_KEYCODE = 32

const PausePlayIcon = styled(MdIcon)`
  font-size: 28px;
  cursor: pointer;

  color: ${({ theme }: ThemeProps): string => theme.surface.textOn};

  :hover {
    color: white;
  }
`

export const PausePlayControl = ({ className }: React.HTMLAttributes<HTMLElement>): JSX.Element => {
  const { isPaused, pause, play } = useWatchPlayer()

  const onIconClicked = async (): Promise<void> => {
    if (isPaused) {
      await play()
    } else {
      pause()
    }
  }

  React.useEffect((): UseEffectReturn => {
    const onSpacePressed = async (e: KeyboardEvent): Promise<void> => {
      if (e.keyCode === SPACEBAR_KEYCODE) {
        await onIconClicked()
      }
    }
    document.body.addEventListener("keydown", onSpacePressed)

    return (): void => {
      document.body.removeEventListener("keydown", onSpacePressed)
    }
  }, [onIconClicked])

  const icon: MdIconType = isPaused ? "play_arrow" : "pause"
  const title = icon === "pause" ? "Pause" : "Play"

  return React.useMemo(() => <PausePlayIcon className={className} tabIndex={-1} icon={icon} title={title} onClick={onIconClicked} />, [isPaused, pause, play])
}

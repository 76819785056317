import React from "react"
import { NavigationState } from "./navigation-state"
import { useContentNavigationState } from "./use-content-navigation"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ContentNavigationContext = React.createContext<NavigationState>(undefined as any)

export const ContentNavigationProvider = ({ children }: ContentNavigationProviderProps): JSX.Element => (
  <ContentNavigationContext.Provider value={useContentNavigationState()}>{children}</ContentNavigationContext.Provider>
)

export const useContentNavigation = (): NavigationState => React.useContext(ContentNavigationContext)

export type ContentNavigationProviderProps = React.HTMLAttributes<HTMLElement>

import * as React from "react"
import { ThemeProps } from "style/theme"
import styled from "styled-components"

const StyledLogo = styled.svg`
  color: ${({ theme }: ThemeProps): string => theme.primary.color};
`

const logoIconBaseStyle: React.CSSProperties = {
  fillRule: "evenodd",
  clipRule: "evenodd",
}

const logoIconStyle0 = {
  ...logoIconBaseStyle,
  fill: "#B1060F",
}

const logoIconStyle1 = {
  ...logoIconBaseStyle,
  fill: "#9D030F",
}

const logoIconStyle2 = {
  ...logoIconBaseStyle,
  fill: "#E50914",
}

export const LogoIcon = (props: React.SVGAttributes<HTMLOrSVGElement>): JSX.Element => (
  <StyledLogo viewBox="0 0 1949.2 2578.1" {...props}>
    <g>
      <path
        style={logoIconStyle0}
        d="M41.4,39.8v1248.5c0,686.7,0.7,1249.2,1.6,1250.2c1,1,43.4-3.3,94.7-8.9c51.3-5.6,122-12.8,157.2-15.8
		c53.9-4.6,215.8-14.8,234.2-15.1c5.6,0,5.9-27.9,6.6-528.9l1-527.7l-0.3-0.9v0.7L41.4,39.8z"
      />
      <path style={logoIconStyle1} d="M41.4,39.8l494.8,1687.3l0.6-285l-0.3-0.9v0.7L41.4,39.8z" />
    </g>
    <g>
      <path
        style={logoIconStyle0}
        d="M1412.8,1441.9v-0.7l-0.3,0.9l1,527.7c0.7,501,1,528.9,6.6,528.9c18.4,0.3,180.2,10.5,234.2,15.1
		c35.2,3,105.9,10.2,157.2,15.8c51.3,5.6,93.7,9.9,94.7,8.9c1-1,1.6-563.4,1.6-1250.2V39.8L1412.8,1441.9z"
      />
      <path style={logoIconStyle1} d="M1412.8,1441.9v-0.7l-0.3,0.9l0.6,285L1907.8,39.8L1412.8,1441.9z" />
      <path
        style={logoIconStyle2}
        d="M1373.7,1551.7l39.1-110.5v0.7l495-1402l-246.3-0.3h-246.4l-3.3,9.2c-1.6,5.3-42.4,120-90.4,255.8
		c-178.6,504.3-208.2,588.1-252.6,713.3l-45.4,128.2v-0.3L974.6,1284l-48.7-138.3v0.3l-45.4-128.2c-44.4-125.2-74-209.1-252.6-713.3
		c-48-135.8-88.8-250.5-90.4-255.8l-3.3-9.2H287.8L41.4,39.8l495,1402v-0.7l39.1,110.5c58.2,164.6,396.1,949.7,399,949.6
		C977.5,2501.4,1315.5,1716.2,1373.7,1551.7z"
      />
    </g>
  </StyledLogo>
)

export const Logo = (props: React.SVGAttributes<HTMLOrSVGElement>): JSX.Element => (
  <StyledLogo viewBox="0 0 775.5 197.6" {...props}>
    <g>
      <path
        d="M132.4,7.6c-0.1,55.3-0.3,110.5-0.4,165.8c-9.2,1-18.4,2-27.6,3.1c0.1-38.6,0.2-77.2,0.3-115.8
			c-8.7,38.9-17.3,78.5-26,118.9c-6.4,0.8-12.8,1.7-19.2,2.5C51,143.5,42.5,104,33.9,63.7c0,40.7-0.1,81.5-0.1,122.2
			c-9,1.3-18,2.7-26.9,4.1c0-60.8,0-121.5,0-182.3c13,0,26,0,39.1,0c7.9,37.6,15.9,74.4,23.8,110.6C77.4,80.7,85.1,43.9,92.8,7.6
			C106,7.6,119.2,7.6,132.4,7.6z"
      />
      <path
        d="M210.2,131.2c-9.9,0.6-19.8,1.2-29.7,1.9c-2,12-4.1,24.1-6.1,36.2c-9.2,0.8-18.4,1.7-27.6,2.6
			C157,116.1,167.2,61.4,177.4,7.6c12.3,0,24.7,0,37,0c9.9,52.8,19.9,104.9,29.8,156.4c-9.4,0.6-18.8,1.2-28.2,1.9
			C214.1,154.4,212.1,142.8,210.2,131.2z M206.6,108.3c-3.7-22-7.3-44.1-11-66.4c-3.8,22.4-7.6,44.9-11.4,67.5
			C191.7,109,199.1,108.6,206.6,108.3z"
      />
      <path
        d="M250.6,7.6c29.3,0,58.7,0,88,0c0,7.3,0,14.5,0,21.8c-10.1,0-20.1,0.1-30.2,0.1c-0.1,43.8-0.1,87.7-0.2,131.5
			c-9.2,0.3-18.5,0.7-27.7,1.1c0.1-44.1,0.2-88.3,0.3-132.4c-10.1,0.1-20.1,0.1-30.2,0.2C250.6,22.5,250.6,15.1,250.6,7.6z"
      />
      <path
        d="M344.6,7.6c29.3,0,58.7,0,88,0c0,7.3,0,14.5,0,21.8c-10.1,0-20.1,0-30.2,0c0,43.4,0,86.9,0,130.3
			c-9.2,0-18.5,0-27.7,0c0-43.4,0-86.9,0-130.3c-10.1,0-20.1,0-30.2,0.1C344.6,22.2,344.6,14.9,344.6,7.6z"
      />
      <path
        d="M447.5,7.6c25.7,0,51.4,0,77.1,0c0,7.4,0,14.8,0.1,22.2c-16.5-0.1-33-0.2-49.5-0.3c0,15,0,30,0.1,44.9
			c13,0.2,26.1,0.4,39.1,0.7c0,7.4,0,14.8,0,22.2c-13-0.4-26.1-0.7-39.1-0.9c0,21.6,0.1,43.1,0.1,64.7c-9.2-0.3-18.5-0.6-27.7-0.8
			C447.6,109.4,447.6,58.5,447.5,7.6z"
      />
      <path
        d="M539.4,7.6c9.2,0,18.4,0,27.6,0c0.1,45.2,0.2,90.4,0.4,135.7c16.4,1,32.8,2.2,49.2,3.4c0,7.7,0,15.5,0.1,23.2
			c-25.6-2.3-51.2-4.3-76.9-5.8C539.7,111.9,539.6,59.8,539.4,7.6z"
      />
      <path
        d="M631.1,7.6c9.2,0,18.4,0,27.6,0c0.1,55.5,0.3,111,0.4,166.6c-9.2-1-18.4-2-27.6-2.9
			C631.4,116.8,631.3,62.2,631.1,7.6z"
      />
      <path
        d="M747.4,185.5c-7.8-21.8-15.6-43.2-23.4-64.1c-7.3,19.6-14.7,38.9-22,57.9c-9.5-1.2-19-2.4-28.6-3.5
			c12-27.6,24-55.8,35.9-84.8c-11.3-28.5-22.7-56.2-34-83.3c9.9,0,19.8,0,29.7,0c7.2,18.4,14.5,37,21.7,56c6.7-18.4,13.5-37,20.2-56
			c9.5,0,19.1,0,28.6,0c-11.5,29.7-22.9,58.6-34.3,86.7c11.9,30.9,23.8,62.7,35.6,95.6C767.1,188.4,757.3,186.9,747.4,185.5z"
      />
    </g>
  </StyledLogo>
)

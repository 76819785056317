/* eslint-disable functional/prefer-readonly-type */

import { Func0, Func1, Func2, Nullable } from "typings"
import { getActiveVideo, getActiveVideoSrc } from "util/dom"
import { WatchPlayerContext } from "../watch-player-context"
import { WatchPlayerMediaIdentifier } from "../watch-player-media-identifier"

const receiverApplicationId = "41507533" // PROD
// const receiverApplicationId = "C8F05466" // DEV

export class CastManager {
  public readonly isAvailable: boolean = false
  private watchPlayerContext: Nullable<WatchPlayerContext> = null

  private originalControlFns: Nullable<LocalPlaybackControlFns>

  private remotePlayer: cast.framework.RemotePlayer = (null as unknown) as cast.framework.RemotePlayer
  private remotePlayerController: cast.framework.RemotePlayerController = (null as unknown) as cast.framework.RemotePlayerController

  private onPlayerStateChanged: Nullable<Func2<Nullable<chrome.cast.media.PlayerState>, Nullable<chrome.cast.media.PlayerState>, void>> = null

  constructor(isAvailable: boolean) {
    this.isAvailable = isAvailable
    if (isAvailable) {
      if (!window.cast) {
        console.error(`Browser says casting is available, but window.cast is undefined`)
        return
      }

      this.getCastContext().setOptions({
        // receiverApplicationId: window.chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
        receiverApplicationId,
        autoJoinPolicy: window.chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
      })
      this.remotePlayer = new cast.framework.RemotePlayer()
      this.remotePlayerController = new cast.framework.RemotePlayerController(this.remotePlayer)

      this.initializeRemotePlayerController()
    } else {
      console.error(`Casting is not available`)
    }
  }

  public setWatchPlayerContext(context: WatchPlayerContext): void {
    this.watchPlayerContext = context
  }

  private cacheOriginalControlFns(): void {
    const video = getActiveVideo()
    if (video) {
      this.originalControlFns = {
        play: video.play,
        pause: video.pause,
        ontimeupdate: video.ontimeupdate,
        onvolumechanged: video.onvolumechange,
      }
    }
  }

  private restoreLocalVideoPlayer(): void {
    if (this.originalControlFns) {
      const video = getActiveVideo()
      if (video) {
        Object.keys(this.originalControlFns).forEach(key => {
          video[key] = this.originalControlFns?.[key]
        })
        video.currentTime = this.watchPlayerContext?.currentTime ?? this.remotePlayer.savedPlayerState?.currentTime ?? this.remotePlayer.currentTime
      }
    }
  }

  private getCastContext(): cast.framework.CastContext {
    return cast.framework.CastContext.getInstance()
  }

  private getCurrentCastSession(): Nullable<cast.framework.CastSession> {
    return this.getCastContext().getCurrentSession()
  }

  public isConnectedToReceiver(): boolean {
    return this.remotePlayer.isConnected ?? false
  }

  public loadCurrentVideo(initialTime: number = 0): void {
    const castSession = this.getCurrentCastSession()
    if (castSession) {
      const videoElement = getActiveVideo()
      const videoElementSrc = getActiveVideoSrc()
      if (videoElement && videoElementSrc) {
        const mediaInfo = new window.chrome.cast.media.MediaInfo(videoElementSrc, 'video/mp4; codecs="avc1.640029, mp4a.40.5')
        mediaInfo.streamType = window.chrome.cast.media.StreamType.BUFFERED

        const request = new window.chrome.cast.media.LoadRequest(mediaInfo)
        request.currentTime = initialTime || videoElement.currentTime
        request.autoplay = true
        if (this.watchPlayerContext) {
          const mediaIdentifier: WatchPlayerMediaIdentifier = {
            watchToken: this.watchPlayerContext.watchToken,
            type: this.watchPlayerContext.type,
            movieDbId: this.watchPlayerContext.movieDbId,
            episodeId: this.watchPlayerContext.episodeId,
          }

          request.customData = mediaIdentifier
        }

        // console.log(`Casting media: `, videoElementSrc)
        // console.log(`LoadRequest: `, request)
        castSession.loadMedia(request).then(
          () => {
            videoElement.pause()
            this.watchPlayerContext?.setIsPaused(this.remotePlayer.isPaused)
            this.linkRemotePlayer()
            this.seekTo(initialTime)
          },
          errorCode => {
            console.log("Casting error: " + errorCode)
          },
        )
      }
    }
  }

  private seekTo(time: number): void {
    if (this.remotePlayer && this.remotePlayerController) {
      this.remotePlayer.currentTime = time
      this.remotePlayerController.seek()
    }
  }

  private play(): void {
    if (this.remotePlayer && this.remotePlayerController) {
      if (this.remotePlayer.isPaused) {
        this.remotePlayerController.playOrPause()
      }
    }
  }

  private pause(): void {
    if (this.remotePlayer && this.remotePlayerController) {
      if (!this.remotePlayer.isPaused) {
        this.remotePlayerController.playOrPause()
      }
    }
  }

  private setVolume(volume: number): void {
    if (this.remotePlayer && this.remotePlayerController) {
      this.remotePlayer.volumeLevel = volume
      this.remotePlayerController.setVolumeLevel()
    }
  }

  public linkRemotePlayer(): void {
    const videoElement = getActiveVideo()
    if (videoElement) {
      this.cacheOriginalControlFns()

      videoElement.ontimeupdate = (): void => {
        this.seekTo(videoElement.currentTime)
      }

      videoElement.play = async (): Promise<void> => {
        this.play()
      }

      videoElement.pause = (): void => {
        this.pause()
      }

      videoElement.onvolumechange = (): void => {
        this.setVolume(videoElement.volume)
      }
    }
  }

  public unlinkRemotePlayer(): void {
    this.remotePlayerController.stop()
    this.restoreLocalVideoPlayer()
  }

  private initializeRemotePlayerController(): void {
    this.remotePlayerController.addEventListener(cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED, () => {
      this.watchPlayerContext?.setIsFullscreenAllowed(!this.remotePlayer.isConnected)
      if (this.remotePlayer.isConnected) {
        this.loadCurrentVideo()
      } else {
        this.unlinkRemotePlayer()
        if (!this.remotePlayer.savedPlayerState?.isPaused) {
          const videoElement = getActiveVideo()
          if (videoElement) {
            videoElement.play()
          }
        }
      }
    })

    this.remotePlayerController.addEventListener(cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED, () => {
      if (this.remotePlayer.isConnected) {
        const timeToSet = this.remotePlayer.savedPlayerState?.currentTime ?? this.remotePlayer.currentTime
        this.watchPlayerContext?.setCurrentTime(timeToSet, false)
        if (this.watchPlayerContext?.isPaused !== this.remotePlayer.isPaused) {
          this.watchPlayerContext?.setIsPaused(this.remotePlayer.isPaused)
        }
      }
    })

    this.remotePlayerController.addEventListener(cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED, () => {
      if (this.remotePlayer.isConnected) {
        this.watchPlayerContext?.setIsPaused(this.remotePlayer.isPaused)
      }
    })

    this.remotePlayerController.addEventListener(cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED, () => {
      if (this.remotePlayer.isConnected) {
        this.watchPlayerContext?.setVolume(this.remotePlayer.volumeLevel)
      }
    })

    // eslint-disable-next-line functional/no-let
    let previousPlayerState: Nullable<chrome.cast.media.PlayerState> = null
    this.remotePlayerController.addEventListener(cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED, e => {
      this.onPlayerStateChanged?.(previousPlayerState, e.value)
      previousPlayerState = e.value
    })

    // this.remotePlayerController.addEventListener(cast.framework.RemotePlayerEventType.ANY_CHANGE, e => {
    //   console.log(e)
    // })
  }

  public setOnPlayerStateChanged(fn: Nullable<Func2<Nullable<chrome.cast.media.PlayerState>, Nullable<chrome.cast.media.PlayerState>, void>>): void {
    this.onPlayerStateChanged = fn
  }
}

interface LocalPlaybackControlFns {
  readonly play: Func0<Promise<void>>
  readonly pause: Func0<void>
  readonly ontimeupdate: Func1<Event, void> | null
  readonly onvolumechanged: Func1<Event, void> | null
}

import { QueryResult } from "@apollo/react-common"
import { useQuery } from "@apollo/react-hooks"
import { GetTrending, GetTrendingVariables } from "typings"
import { MovieDbQueries } from "../graphql"

export const useTrending = (): QueryResult<GetTrending> =>
  useQuery<GetTrending, GetTrendingVariables>(MovieDbQueries.GetTrending, {
    variables: {
      page: 1,
    },
  })

export enum Environment {
  Local,
  Production,
}

export const detectEnvironment = (): Environment => {
  switch (location.hostname) {
    case "mweiss27.ddns.net":
    case "mattflix.watch":
    case "server":
    case "server.weiss":
      return Environment.Production

    case "localhost":
    default:
      return Environment.Production
  }
}

import throttle from "lodash-es/throttle"
import React from "react"
import { UseEffectReturn } from "typings"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NavbarContext = React.createContext<NavbarContextState>(undefined as any)

const NAVBAR_WIDTH_COLLAPSED = 75
const NAVBAR_WIDTH_EXPANDED = 200

const screenWidthThresholds = [600, 700]

const shouldBeExpanded = (isCurrentlyExpanded: boolean): boolean => {
  return isCurrentlyExpanded ? window.innerWidth > screenWidthThresholds[0] : window.innerWidth > screenWidthThresholds[1]
}

export const NavbarProvider = ({ children }: NavbarProviderProps): JSX.Element => {
  const [state, setState] = React.useState<NavbarContextState>({
    navbarWidth: 0,
    isExpanded: true,
  })

  React.useEffect((): UseEffectReturn => {
    const onResize = throttle((): void => {
      const newIsExpanded = shouldBeExpanded(state.isExpanded)
      if (state.navbarWidth === 0 || newIsExpanded !== state.isExpanded) {
        setState({
          isExpanded: newIsExpanded,
          navbarWidth: newIsExpanded ? NAVBAR_WIDTH_EXPANDED : NAVBAR_WIDTH_COLLAPSED,
        })
      }
    }, 10)

    window.addEventListener("resize", onResize)
    window.addEventListener("orientationchange", onResize)
    onResize()

    return (): void => {
      window.removeEventListener("resize", onResize)
      window.removeEventListener("orientationchange", onResize)
    }
  }, [state.isExpanded])

  return <NavbarContext.Provider value={state}>{children}</NavbarContext.Provider>
}

export const useNavbar = (): NavbarContextState => React.useContext(NavbarContext)

export type NavbarProviderProps = React.HTMLAttributes<HTMLElement>

export interface NavbarContextState {
  readonly navbarWidth: number
  readonly isExpanded: boolean
}

import { ContentGrid } from "components/content-list/content-grid"
import { ContentLoadingSpinner } from "components/loading-spinner"
import { PaddedPageContent } from "components/page"
import { Body1 } from "components/typography"
import { useTrending } from "domain/content/hooks"
import uniqBy from "lodash-es/uniqBy"
import React from "react"
import { GetTrending, GetTrendingVariables, Nullable, UseEffectReturn } from "typings"
import { usePageHistory } from "util/hooks"
import { TrendingRoute } from "../../../../constants"

const mergeTrendingResults = (currentTrending: Nullable<GetTrending>, newTrending: Nullable<GetTrending>): GetTrending => {
  if (currentTrending && newTrending) {
    return {
      ...currentTrending,
      trending: uniqBy([...currentTrending.trending, ...newTrending.trending], "id"),
    }
  }

  return (
    currentTrending || {
      trending: [],
    }
  )
}

const Trending = (): JSX.Element => {
  const { data, loading, error, fetchMore } = useTrending()
  const { pushHistory } = usePageHistory()

  React.useEffect((): UseEffectReturn => {
    pushHistory({
      title: "Trending",
      path: TrendingRoute,
    })
  }, [])

  const onPage = (nextPage: number): void => {
    const variables: GetTrendingVariables = {
      page: nextPage,
    }

    fetchMore({
      variables,
      updateQuery: (currentTrending: GetTrending, { fetchMoreResult }): GetTrending => {
        if (!currentTrending) {
          return currentTrending
        }

        return mergeTrendingResults(currentTrending, fetchMoreResult)
      },
    })
  }

  if (loading) {
    return <ContentLoadingSpinner size="medium" />
  }

  if (error) {
    return (
      <PaddedPageContent>
        <Body1>Failed to load Trending content</Body1>
      </PaddedPageContent>
    )
  }

  if (data?.trending) {
    return <ContentGrid id="trending_grid" items={data.trending} onPage={onPage} />
  }

  return (
    <PaddedPageContent>
      <Body1>Failed to load Trending content</Body1>
    </PaddedPageContent>
  )
}

export default Trending

import { useNavbar } from "domain/navbar/context/navbar-context"
import debounce from "lodash-es/debounce"
import throttle from "lodash-es/throttle"
import React from "react"
import Scrollbars from "react-custom-scrollbars"
import { ResizeObserver } from "resize-observer"
import { UseEffectReturn } from "typings"
import { getNavigateLeftScrollLeft, getNavigateRightScrollLeft } from "./content-navigation-calculations"
import { NavigationState } from "./navigation-state"

type NavigationDisabledState = {
  readonly navigateLeftDisabled: boolean
  readonly navigateRightDisabled: boolean
}

export const useContentNavigationState = (): NavigationState => {
  const { navbarWidth } = useNavbar()
  const scrollerRef = React.useRef<HTMLDivElement>(null)
  const customScrollerRef = React.useRef<Scrollbars>(null)

  const scrollerScrollWidth = React.useRef<number>(0)
  const scrollerClientWidth = React.useRef<number>(0)
  const scrollerClientHeight = React.useRef<number>(0)
  const [scrollerScrollLeft, setScrollerScrollLeft] = React.useState<number>(0)

  const [{ navigateLeftDisabled, navigateRightDisabled }, setNavigateDisabled] = React.useState<NavigationDisabledState>({
    navigateLeftDisabled: false,
    navigateRightDisabled: false,
  })

  const updateNavigation = (): void => {
    const newNavigateLeftDisabled = scrollerScrollLeft <= 0
    const newNavigateRightDisabled = scrollerScrollLeft + scrollerClientWidth.current >= scrollerScrollWidth.current

    if (newNavigateLeftDisabled !== navigateLeftDisabled || newNavigateRightDisabled !== navigateRightDisabled) {
      setNavigateDisabled({
        navigateLeftDisabled: newNavigateLeftDisabled,
        navigateRightDisabled: newNavigateRightDisabled,
      })
    }
  }

  const onCustomScrollerScrolled = throttle((): void => {
    const customScroller = customScrollerRef.current
    if (customScroller) {
      setScrollerScrollLeft(customScroller.getScrollLeft())
      updateNavigation()
    }
  }, 100)

  const onCustomScrollerScrollStop = (): void => {
    const customScroller = customScrollerRef.current
    if (customScroller) {
      setScrollerScrollLeft(customScroller.getScrollLeft())
      updateNavigation()
    }
  }

  const navigateLeft = (): void => {
    const scroller = scrollerRef.current
    const customScroller = customScrollerRef.current

    if (scroller && customScroller) {
      const nextScrollLeft = getNavigateLeftScrollLeft(scroller, navbarWidth)

      // @ts-ignore
      const view: HTMLDivElement = customScroller.view
      view.scrollTo({
        left: nextScrollLeft,
        behavior: "smooth",
      })
    }
  }

  const navigateRight = (): void => {
    const scroller = scrollerRef.current
    const customScroller = customScrollerRef.current

    if (scroller && customScroller) {
      const nextScrollRight = getNavigateRightScrollLeft(scroller, navbarWidth)

      // @ts-ignore
      const view: HTMLDivElement = customScroller.view
      view.scrollTo({
        left: nextScrollRight,
        behavior: "smooth",
      })
    }
  }

  React.useEffect((): UseEffectReturn => {
    const scroller = scrollerRef.current
    if (scroller) {
      scrollerClientWidth.current = scroller.clientWidth
      scrollerClientHeight.current = scroller.clientHeight
      scrollerScrollWidth.current = scroller.scrollWidth
      updateNavigation()

      const debouncedResizeCallback = debounce((): void => {
        scrollerClientWidth.current = scroller.clientWidth
        scrollerClientHeight.current = scroller.clientHeight
        scrollerScrollWidth.current = scroller.scrollWidth
        updateNavigation()
      }, 300)
      const resizeObserver = new ResizeObserver(debouncedResizeCallback)

      resizeObserver.observe(scroller)
      return (): void => {
        resizeObserver.unobserve(scroller)
      }
    }
  }, [])

  return {
    scrollerRef,
    customScrollerRef,
    scrollerScrollWidth: scrollerScrollWidth.current,
    scrollerClientHeight: scrollerClientHeight.current,
    scrollerClientWidth: scrollerClientWidth.current,
    scrollerScrollLeft,
    navigateLeft,
    navigateLeftDisabled,
    navigateRight,
    navigateRightDisabled,
    onCustomScrollerScrolled,
    onCustomScrollerScrollStop,
  }
}

import { Headline6 } from "components/typography"
import { NavbarContextState, useNavbar } from "domain/navbar/context/navbar-context"
import React from "react"
import ReactPlayer from "react-player"
import styled from "styled-components"

const TrailerContainer = styled.div<NavbarContextState>`
  display: grid;
  gap: 8px;
  margin: 0 0 32px ${({ navbarWidth }) => `${navbarWidth}px`};
`

export const FullscreenMovieTrailer = ({ embedUrl }: FullscreenMovieTrailerProps): JSX.Element => {
  const navbar = useNavbar()

  return (
    <TrailerContainer {...navbar}>
      <Headline6>Trailer</Headline6>
      <ReactPlayer url={embedUrl} style={{ maxWidth: "75vw", maxHeight: "42.1875vw" }} controls />
    </TrailerContainer>
  )
}

export interface FullscreenMovieTrailerProps {
  readonly embedUrl: string
}

import React from "react"
import { Func0, Func1, UseEffectReturn } from "typings"

export const useVideoFullscreen = (wrapperRef: React.RefObject<HTMLDivElement>): UseVideoFullscreen => {
  const [isFullscreen, setIsFullscreen] = React.useState<boolean>(false)
  const [isFullscreenAllowed, setIsFullscreenAllowed] = React.useState<boolean>(true)

  React.useEffect((): UseEffectReturn => {
    document.addEventListener("fullscreenchange", () => {
      setIsFullscreen(!!document.fullscreenElement && document.fullscreenElement.id === wrapperRef.current?.id)
    })
  }, [])

  const enterFullscreen = React.useCallback(async (): Promise<void> => {
    const wrapper = wrapperRef.current
    if (wrapper) {
      await wrapper.requestFullscreen()
      setIsFullscreen(true)
    }
  }, [setIsFullscreen])

  const exitFullscreen = React.useCallback(async (): Promise<void> => {
    await document.exitFullscreen()
    setIsFullscreen(false)
  }, [setIsFullscreen])

  return {
    isFullscreen,
    isFullscreenAllowed,
    setIsFullscreenAllowed,
    enterFullscreen,
    exitFullscreen,
  }
}

export interface UseVideoFullscreen {
  readonly isFullscreen: boolean
  readonly isFullscreenAllowed: boolean
  readonly setIsFullscreenAllowed: Func1<boolean, void>
  readonly enterFullscreen: Func0<Promise<void>>
  readonly exitFullscreen: Func0<Promise<void>>
}

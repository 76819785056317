import { css, FlattenSimpleInterpolation } from "styled-components"

export type ScrollIndicatorBoxShadowDirection = "top" | "bottom" | "left" | "right"
export type ScrollIndicatorShadowSize = "small" | "medium" | "large" | "huge" | "massive"

const sizeMap: ReadonlyMap<ScrollIndicatorShadowSize, string> = new Map([
  ["massive", "64px"],
  ["huge", "16px"],
  ["large", "8px"],
  ["medium", "5px"],
  ["small", "3px"],
])

const directionOpposites: ReadonlyMap<ScrollIndicatorBoxShadowDirection, ScrollIndicatorBoxShadowDirection> = new Map([
  ["left", "right"],
  ["right", "left"],
  ["top", "bottom"],
  ["bottom", "top"],
])

const getScrollIndicatorHeight = (position: ScrollIndicatorBoxShadowDirection, size: ScrollIndicatorShadowSize): string =>
  position === "top" || position === "bottom" ? sizeMap.get(size)! : ""

const getScrollIndicatorWidth = (position: ScrollIndicatorBoxShadowDirection, size: ScrollIndicatorShadowSize): string =>
  position === "left" || position === "right" ? sizeMap.get(size)! : ""

const getScrollIndicatorLeft = (position: ScrollIndicatorBoxShadowDirection, inset: boolean): string => {
  switch (position) {
    case "top":
    case "bottom":
      return "0"

    case "left":
      return inset ? "0" : ""

    case "right":
      return inset ? "" : "100%"
  }
}

const getScrollIndicatorRight = (position: ScrollIndicatorBoxShadowDirection, inset: boolean): string => {
  switch (position) {
    case "top":
    case "bottom":
      return "0"

    case "right":
      return inset ? "0" : ""

    case "left":
      return inset ? "" : "100%"
  }
}

const getScrollIndicatorTop = (position: ScrollIndicatorBoxShadowDirection, inset: boolean): string => {
  switch (position) {
    case "left":
    case "right":
      return "0"

    case "top":
      return inset ? "0" : ""

    case "bottom":
      return inset ? "" : "100%"
  }
}

const getScrollIndicatorBottom = (position: ScrollIndicatorBoxShadowDirection, inset: boolean): string => {
  switch (position) {
    case "left":
    case "right":
      return "0"

    case "bottom":
      return inset ? "0" : ""

    case "top":
      return inset ? "" : "100%"
  }
}

const getShadowDirection = (direction: ScrollIndicatorBoxShadowDirection, inset: boolean): string => (inset ? directionOpposites.get(direction)! : direction)

const getScrollIndicatorBackground = (direction: ScrollIndicatorBoxShadowDirection, inset: boolean): string => {
  const shadowStrength = inset ? 0.4 : 0.2
  const shadowStart = `rgba(0, 0, 0, ${shadowStrength}) 0%`
  const shadowEnd = "rgba(0, 0, 0, 0) 100%"

  return `linear-gradient(to ${getShadowDirection(direction, inset)}, ${shadowStart}, ${shadowEnd})`
}

export const directionalBoxShadow = (
  direction: ScrollIndicatorBoxShadowDirection,
  size: ScrollIndicatorShadowSize = "medium",
  inset: boolean = false,
): FlattenSimpleInterpolation => css`
  position: relative;

  :after {
    position: absolute;
    content: "";
    left: ${getScrollIndicatorLeft(direction, inset)};
    right: ${getScrollIndicatorRight(direction, inset)};
    top: ${getScrollIndicatorTop(direction, inset)};
    bottom: ${getScrollIndicatorBottom(direction, inset)};
    height: ${getScrollIndicatorHeight(direction, size)};
    width: ${getScrollIndicatorWidth(direction, size)};
    background: ${getScrollIndicatorBackground(direction, inset)};
  }
`

import { Subtitle1 } from "components/typography"
import React from "react"
import styled from "styled-components"
import { fullscreenGridAreas } from "./fullscreen-container"

const FullscreenDetailsContainer = styled.div`
  grid-area: ${fullscreenGridAreas.details};
  padding: 0 32px 0 16px;
  max-width: 750px;
`

const FullscreenOverview = styled(Subtitle1)`
  line-height: 1.75rem;
`

export const FullscreenDetails = ({ overview }: FullscreenDetailsProps): JSX.Element => {
  return (
    <FullscreenDetailsContainer>
      <FullscreenOverview>{overview}</FullscreenOverview>
    </FullscreenDetailsContainer>
  )
}

interface FullscreenDetailsProps {
  readonly overview: string
}

import { ContentList } from "components/content-list"
import { useSimilarShows } from "domain/content/hooks/use-similar-shows"
import React from "react"

export const FullscreenSimilarShows = ({ className, movieDbId }: FullscreenSimilarShowsProps): JSX.Element | null => {
  const { data } = useSimilarShows(movieDbId)

  return data?.similarShows.length ? (
    <ContentList id={`similar_shows_${movieDbId}`} className={className} header="Similar Shows" items={data.similarShows} />
  ) : null
}

interface FullscreenSimilarShowsProps {
  readonly className?: string
  readonly movieDbId: number
}

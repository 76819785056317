import { LoadingSpinner } from "components/loading-spinner"
import { Subtitle1 } from "components/typography"
import React from "react"
import { fullSizeCss } from "style/css"
import { directionalBoxShadow } from "style/directional-dropshadow"
import styled from "styled-components"
import { MovieDbMediaType, UseEffectReturn } from "typings"
import { Levels } from "../../../constants"
import { WatchMoviePlayerOverlayHeader } from "../movie/watch-movie-player-overlay-header"
import { WatchShowPlayerOverlayHeader } from "../show/watch-show-player-overlay-header"
import { useWatchPlayer } from "../watch-player-context"
import { WatchPlayerControls } from "./watch-player-controls"

const overlayGridAreas = {
  header: "header",
  controls: "controls",
}

const overlaysGrid = `
  "${overlayGridAreas.header}" auto
  "." 1fr
  "${overlayGridAreas.controls}" auto / 1fr
`

const Overlays = styled.div<OverlayProps>`
  display: grid;
  align-content: start;
  align-items: center;
  grid: ${overlaysGrid};

  column-gap: 12px;

  ${fullSizeCss};
  padding: 8px 12px;
  /* ${directionalBoxShadow("bottom", "massive", true)}; */

  opacity: ${({ isVisible }: OverlayProps): number => (isVisible ? 1 : 0)};
  cursor: ${({ isVisible }: OverlayProps): string => (isVisible ? "" : "none")};
  transition: opacity 0.6s;

  z-index: ${Levels.ElevatedContent};
`

const CenteredContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const BackShadow = styled.div`
  ${fullSizeCss};
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.3) 90%, rgba(0, 0, 0, 0.8) 100%);
  pointer-events: none;
`

const ControlsArea = styled(WatchPlayerControls)`
  grid-area: ${overlayGridAreas.controls};
`

const WatchMoviePlayerOverlayHeaderArea = styled(WatchMoviePlayerOverlayHeader)`
  grid-area: ${overlayGridAreas.header};
`

const WatchPlayerHeader = (props: OverlayProps): JSX.Element | null => {
  const { type } = useWatchPlayer()

  switch (type) {
    case MovieDbMediaType.Movie:
      return <WatchMoviePlayerOverlayHeaderArea {...props} />

    case MovieDbMediaType.Tv:
      return <WatchShowPlayerOverlayHeader {...props} />
  }

  return null
}

export const WatchPlayerOverlay = (): JSX.Element => {
  const { isControlsVisible, overlayRef, isPaused, pause, play, isFullscreen, exitFullscreen, enterFullscreen, seeking, duration, error } = useWatchPlayer()

  const onOverlayClicked = async (e: MouseEvent): Promise<void> => {
    if (e.target === overlayRef.current) {
      if (isPaused) {
        await play()
      } else {
        pause()
      }
    }
  }

  const onOverlayDoubleClicked = async (e: MouseEvent): Promise<void> => {
    if (e.target === overlayRef.current) {
      if (isFullscreen) {
        await exitFullscreen()
      } else {
        await enterFullscreen()
      }
    }
  }

  React.useEffect(
    (): UseEffectReturn => {
      const overlay = overlayRef.current
      if (overlay) {
        overlay.addEventListener("click", onOverlayClicked)
        overlay.addEventListener("dblclick", onOverlayDoubleClicked)

        return (): void => {
          overlay.removeEventListener("click", onOverlayClicked)
          overlay.removeEventListener("dblclick", onOverlayDoubleClicked)
        }
      }
    },
  )

  const LoadingResult = React.useMemo(
    () => (): JSX.Element => (
      <CenteredContainer>
        <LoadingSpinner />
      </CenteredContainer>
    ),
    [],
  )

  const ErrorResult = React.useMemo(
    () => (): JSX.Element => (
      <CenteredContainer>
        <Subtitle1>An error occurred while loading this video</Subtitle1>
      </CenteredContainer>
    ),
    [],
  )

  const showLoading = (seeking || duration === 0) && !error
  const showOverlays = !error && duration > 0

  return (
    <Overlays ref={overlayRef} isVisible={showLoading || isPaused || isControlsVisible}>
      {showLoading && <LoadingResult />}
      {error && <ErrorResult />}
      <WatchPlayerHeader isVisible={showOverlays} />
      <ControlsArea isVisible={showOverlays} />
      <BackShadow />
    </Overlays>
  )
}

interface OverlayProps {
  readonly isVisible: boolean
}

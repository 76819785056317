
/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteMovie
// ====================================================

export interface DeleteMovieDeleteMovie {
  readonly __typename: "MovieAvailability";
  readonly id: number;
  readonly availabilityStatus: AvailabilityStatus;
}

export interface DeleteMovie {
  readonly deleteMovie: DeleteMovieDeleteMovie;
}

export interface DeleteMovieVariables {
  readonly movieDbId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCollection
// ====================================================

export interface GetCollectionCollectionMovies {
  readonly __typename: "ContentBaseResultType";
  readonly id: number;
  readonly type: MovieDbMediaType;
  readonly title: string;
  readonly posterPath: string | null;
  readonly backdropPath: string | null;
  readonly availabilityStatus: AvailabilityStatus | null;
}

export interface GetCollectionCollection {
  readonly __typename: "CollectionType";
  readonly id: number;
  readonly name: string;
  readonly overview: string;
  readonly posterPath: string | null;
  readonly backdropPath: string | null;
  readonly movies: ReadonlyArray<GetCollectionCollectionMovies>;
}

export interface GetCollection {
  readonly collection: GetCollectionCollection | null;
}

export interface GetCollectionVariables {
  readonly collectionId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetComingSoon
// ====================================================

export interface GetComingSoonComingSoon {
  readonly __typename: "ContentBaseResultType";
  readonly id: number;
  readonly type: MovieDbMediaType;
  readonly title: string;
  readonly posterPath: string | null;
  readonly backdropPath: string | null;
  readonly availabilityStatus: AvailabilityStatus | null;
}

export interface GetComingSoon {
  readonly comingSoon: ReadonlyArray<GetComingSoonComingSoon>;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetContentForCast
// ====================================================

export interface GetContentForCastContentForCast {
  readonly __typename: "ContentBaseResultType";
  readonly id: number;
  readonly type: MovieDbMediaType;
  readonly title: string;
  readonly posterPath: string | null;
  readonly backdropPath: string | null;
  readonly availabilityStatus: AvailabilityStatus | null;
}

export interface GetContentForCast {
  readonly contentForCast: ReadonlyArray<GetContentForCastContentForCast>;
}

export interface GetContentForCastVariables {
  readonly castId: number;
  readonly page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetContentForGenre
// ====================================================

export interface GetContentForGenreContentForGenre {
  readonly __typename: "ContentBaseResultType";
  readonly id: number;
  readonly type: MovieDbMediaType;
  readonly title: string;
  readonly posterPath: string | null;
  readonly backdropPath: string | null;
  readonly availabilityStatus: AvailabilityStatus | null;
}

export interface GetContentForGenre {
  readonly contentForGenre: ReadonlyArray<GetContentForGenreContentForGenre>;
}

export interface GetContentForGenreVariables {
  readonly genreId: number;
  readonly page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGenreWatchTimes
// ====================================================

export interface GetGenreWatchTimesGenreWatchTimes {
  readonly __typename: "GenreWatchTimeType";
  readonly genreId: number;
  readonly watchTimeSeconds: number;
}

export interface GetGenreWatchTimes {
  readonly genreWatchTimes: ReadonlyArray<GetGenreWatchTimesGenreWatchTimes>;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMovieDetails
// ====================================================

export interface GetMovieDetailsGetMovieDetailsCast {
  readonly __typename: "CastModel";
  readonly id: number;
  readonly castId: number;
  readonly name: string;
  readonly character: string;
  readonly imageUrl: string | null;
}

export interface GetMovieDetailsGetMovieDetailsCategories {
  readonly __typename: "GenreModel";
  readonly id: number;
  readonly name: string;
}

export interface GetMovieDetailsGetMovieDetailsAvailabilityStatus {
  readonly __typename: "MovieAvailability";
  readonly id: number;
  readonly availabilityStatus: AvailabilityStatus;
}

export interface GetMovieDetailsGetMovieDetails {
  readonly __typename: "MovieDetailsType";
  readonly id: number;
  readonly title: string;
  readonly runtime: number | null;
  readonly year: number | null;
  readonly description: string;
  readonly released: string;
  readonly movieDbId: number;
  readonly posterPath: string | null;
  readonly backdropPath: string | null;
  readonly embedUrl: string | null;
  readonly cast: ReadonlyArray<GetMovieDetailsGetMovieDetailsCast>;
  readonly categories: ReadonlyArray<GetMovieDetailsGetMovieDetailsCategories>;
  readonly voteAverage: number | null;
  readonly availabilityStatus: GetMovieDetailsGetMovieDetailsAvailabilityStatus;
  readonly collectionId: number | null;
}

export interface GetMovieDetails {
  readonly getMovieDetails: GetMovieDetailsGetMovieDetails | null;
}

export interface GetMovieDetailsVariables {
  readonly movieDbId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMovies
// ====================================================

export interface GetMoviesMoviesMovies {
  readonly __typename: "ContentBaseResultType";
  readonly id: number;
  readonly type: MovieDbMediaType;
  readonly title: string;
  readonly posterPath: string | null;
  readonly backdropPath: string | null;
  readonly availabilityStatus: AvailabilityStatus | null;
}

export interface GetMoviesMovies {
  readonly __typename: "GetMoviesResponseType";
  readonly resultCount: number;
  readonly movies: ReadonlyArray<GetMoviesMoviesMovies>;
}

export interface GetMovies {
  readonly movies: GetMoviesMovies;
}

export interface GetMoviesVariables {
  readonly searchOptions?: MovieSearchInputType | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetNextEpisode
// ====================================================

export interface GetNextEpisodeGetNextEpisode {
  readonly __typename: "TVEpisodeModel";
  readonly id: number;
}

export interface GetNextEpisode {
  readonly getNextEpisode: GetNextEpisodeGetNextEpisode | null;
}

export interface GetNextEpisodeVariables {
  readonly episodeId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPersonDetails
// ====================================================

export interface GetPersonDetailsGetPersonDetails {
  readonly __typename: "PersonDetailsType";
  readonly id: number;
  readonly name: string;
}

export interface GetPersonDetails {
  readonly getPersonDetails: GetPersonDetailsGetPersonDetails | null;
}

export interface GetPersonDetailsVariables {
  readonly personId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetShowDetails
// ====================================================

export interface GetShowDetailsGetTvDetailsGenres {
  readonly __typename: "GenreModel";
  readonly id: number;
  readonly name: string;
}

export interface GetShowDetailsGetTvDetailsCast {
  readonly __typename: "CastModel";
  readonly id: number;
  readonly castId: number;
  readonly name: string;
  readonly character: string;
  readonly imageUrl: string | null;
}

export interface GetShowDetailsGetTvDetailsSeasons {
  readonly __typename: "TvSeasonType";
  readonly episodeCount: number;
  readonly seasonNumber: number;
}

export interface GetShowDetailsGetTvDetailsNextEpisodeTvSeason {
  readonly __typename: "TVSeasonModel";
  readonly id: number;
  readonly seasonNum: number;
}

export interface GetShowDetailsGetTvDetailsNextEpisode {
  readonly __typename: "TVEpisodeModel";
  readonly id: number;
  readonly episodeNum: number;
  readonly tvSeason: GetShowDetailsGetTvDetailsNextEpisodeTvSeason;
}

export interface GetShowDetailsGetTvDetails {
  readonly __typename: "TvDetailsType";
  readonly id: number;
  readonly title: string;
  readonly added: string | null;
  readonly description: string;
  readonly released: string;
  readonly backdropPath: string | null;
  readonly posterPath: string | null;
  readonly genres: ReadonlyArray<GetShowDetailsGetTvDetailsGenres>;
  readonly cast: ReadonlyArray<GetShowDetailsGetTvDetailsCast>;
  readonly numberOfSeasons: number;
  readonly numberOfEpisodes: number;
  readonly voteAverage: number;
  readonly seasons: ReadonlyArray<GetShowDetailsGetTvDetailsSeasons>;
  readonly nextEpisode: GetShowDetailsGetTvDetailsNextEpisode | null;
}

export interface GetShowDetails {
  readonly getTvDetails: GetShowDetailsGetTvDetails | null;
}

export interface GetShowDetailsVariables {
  readonly movieDbId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetShowEpisodeDetails
// ====================================================

export interface GetShowEpisodeDetailsGetTvEpisodeDetailsTvSeason {
  readonly __typename: "TVSeasonModel";
  readonly id: number;
  readonly seasonNum: number;
}

export interface GetShowEpisodeDetailsGetTvEpisodeDetails {
  readonly __typename: "TVEpisodeModel";
  readonly id: number;
  readonly title: string;
  readonly episodeNum: number;
  readonly tvSeason: GetShowEpisodeDetailsGetTvEpisodeDetailsTvSeason;
}

export interface GetShowEpisodeDetails {
  readonly getTvEpisodeDetails: GetShowEpisodeDetailsGetTvEpisodeDetails | null;
}

export interface GetShowEpisodeDetailsVariables {
  readonly episodeId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetShowSeasonDetails
// ====================================================

export interface GetShowSeasonDetailsGetTvSeasonDetailsEpisodesAvailabilityStatus {
  readonly __typename: "TvSeasonAvailability";
  readonly id: string;
  readonly episodeNumber: string;
  readonly availabilityStatus: AvailabilityStatus;
}

export interface GetShowSeasonDetailsGetTvSeasonDetailsEpisodes {
  readonly __typename: "TvEpisodeDetailsType";
  readonly id: number;
  readonly airDate: string | null;
  readonly episodeNumber: number;
  readonly name: string;
  readonly overview: string;
  readonly seasonNumber: number;
  readonly stillPath: string;
  readonly voteAverage: number;
  readonly voteCount: number;
  readonly playbackTime: number | null;
  readonly runtime: number | null;
  readonly availabilityStatus: GetShowSeasonDetailsGetTvSeasonDetailsEpisodesAvailabilityStatus;
}

export interface GetShowSeasonDetailsGetTvSeasonDetails {
  readonly __typename: "TvSeasonDetailsType";
  readonly episodes: ReadonlyArray<GetShowSeasonDetailsGetTvSeasonDetailsEpisodes>;
}

export interface GetShowSeasonDetails {
  readonly getTvSeasonDetails: GetShowSeasonDetailsGetTvSeasonDetails | null;
}

export interface GetShowSeasonDetailsVariables {
  readonly movieDbId: number;
  readonly seasonNumber: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetShows
// ====================================================

export interface GetShowsShows {
  readonly __typename: "ContentBaseResultType";
  readonly id: number;
  readonly type: MovieDbMediaType;
  readonly title: string;
  readonly posterPath: string | null;
  readonly backdropPath: string | null;
  readonly availabilityStatus: AvailabilityStatus | null;
}

export interface GetShows {
  readonly shows: ReadonlyArray<GetShowsShows>;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SimilarMovies
// ====================================================

export interface SimilarMoviesSimilarMovies {
  readonly __typename: "ContentBaseResultType";
  readonly id: number;
  readonly type: MovieDbMediaType;
  readonly title: string;
  readonly posterPath: string | null;
  readonly backdropPath: string | null;
  readonly availabilityStatus: AvailabilityStatus | null;
}

export interface SimilarMovies {
  readonly similarMovies: ReadonlyArray<SimilarMoviesSimilarMovies>;
}

export interface SimilarMoviesVariables {
  readonly movieDbId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SimilarShows
// ====================================================

export interface SimilarShowsSimilarShows {
  readonly __typename: "ContentBaseResultType";
  readonly id: number;
  readonly title: string;
  readonly type: MovieDbMediaType;
  readonly posterPath: string | null;
  readonly backdropPath: string | null;
  readonly availabilityStatus: AvailabilityStatus | null;
}

export interface SimilarShows {
  readonly similarShows: ReadonlyArray<SimilarShowsSimilarShows>;
}

export interface SimilarShowsVariables {
  readonly movieDbId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTrending
// ====================================================

export interface GetTrendingTrending {
  readonly __typename: "ContentBaseResultType";
  readonly id: number;
  readonly type: MovieDbMediaType;
  readonly title: string;
  readonly posterPath: string | null;
  readonly backdropPath: string | null;
  readonly availabilityStatus: AvailabilityStatus | null;
}

export interface GetTrending {
  readonly trending: ReadonlyArray<GetTrendingTrending>;
}

export interface GetTrendingVariables {
  readonly page: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTvEpisodeDetails
// ====================================================

export interface GetTvEpisodeDetailsGetTvEpisodeDetailsTvSeasonTvEpisodes {
  readonly __typename: "TVEpisodeModel";
  readonly id: number;
  readonly episodeNum: number;
}

export interface GetTvEpisodeDetailsGetTvEpisodeDetailsTvSeason {
  readonly __typename: "TVSeasonModel";
  readonly id: number;
  readonly seasonNum: number;
  readonly tvEpisodes: ReadonlyArray<GetTvEpisodeDetailsGetTvEpisodeDetailsTvSeasonTvEpisodes>;
}

export interface GetTvEpisodeDetailsGetTvEpisodeDetails {
  readonly __typename: "TVEpisodeModel";
  readonly id: number;
  readonly episodeNum: number;
  readonly title: string;
  readonly runtimeSeconds: number;
  readonly hasSubtitles: boolean;
  readonly tvSeason: GetTvEpisodeDetailsGetTvEpisodeDetailsTvSeason;
}

export interface GetTvEpisodeDetails {
  readonly getTvEpisodeDetails: GetTvEpisodeDetailsGetTvEpisodeDetails | null;
}

export interface GetTvEpisodeDetailsVariables {
  readonly episodeId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportMovie
// ====================================================

export interface ImportMovieImportMovieDetails {
  readonly __typename: "MovieAvailability";
  readonly id: number;
  readonly availabilityStatus: AvailabilityStatus;
}

export interface ImportMovie {
  readonly importMovieDetails: ImportMovieImportMovieDetails;
}

export interface ImportMovieVariables {
  readonly movieDbId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestMovie
// ====================================================

export interface RequestMovieRequestMovie {
  readonly __typename: "MovieAvailability";
  readonly id: number;
  readonly availabilityStatus: AvailabilityStatus;
}

export interface RequestMovie {
  readonly requestMovie: RequestMovieRequestMovie;
}

export interface RequestMovieVariables {
  readonly movieDbId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestTvSeason
// ====================================================

export interface RequestTvSeasonRequestTvSeason {
  readonly __typename: "TvSeasonAvailability";
  readonly id: string;
  readonly episodeNumber: string;
  readonly availabilityStatus: AvailabilityStatus;
}

export interface RequestTvSeason {
  readonly requestTvSeason: ReadonlyArray<RequestTvSeasonRequestTvSeason>;
}

export interface RequestTvSeasonVariables {
  readonly movieDbId: number;
  readonly seasonNumber: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Search
// ====================================================

export interface SearchSearch {
  readonly __typename: "ContentBaseResultType";
  readonly id: number;
  readonly type: MovieDbMediaType;
  readonly title: string;
  readonly posterPath: string | null;
  readonly backdropPath: string | null;
  readonly availabilityStatus: AvailabilityStatus | null;
}

export interface Search {
  readonly search: ReadonlyArray<SearchSearch>;
}

export interface SearchVariables {
  readonly searchPhrase: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: SubscribeMovieDownloadStatus
// ====================================================

export interface SubscribeMovieDownloadStatusMovieTorrentStatus {
  readonly __typename: "DownloadProgressType";
  readonly movieDbId: number;
  readonly percentDone: number;
  readonly progress: string;
  readonly more: boolean;
}

export interface SubscribeMovieDownloadStatus {
  readonly movieTorrentStatus: SubscribeMovieDownloadStatusMovieTorrentStatus | null;
}

export interface SubscribeMovieDownloadStatusVariables {
  readonly movieDbId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: SubscribeSeasonDownloadStatus
// ====================================================

export interface SubscribeSeasonDownloadStatusSeasonTorrentStatusEpisodeProgresses {
  readonly __typename: "EpisodeDownloadProgressType";
  readonly movieDbId: number;
  readonly percentDone: number;
  readonly progress: string;
  readonly more: boolean;
  readonly episodeNumber: number;
}

export interface SubscribeSeasonDownloadStatusSeasonTorrentStatus {
  readonly __typename: "SeasonDownloadProgressType";
  readonly movieDbId: number;
  readonly seasonNumber: number;
  readonly episodeProgresses: ReadonlyArray<SubscribeSeasonDownloadStatusSeasonTorrentStatusEpisodeProgresses>;
}

export interface SubscribeSeasonDownloadStatus {
  readonly seasonTorrentStatus: SubscribeSeasonDownloadStatusSeasonTorrentStatus | null;
}

export interface SubscribeSeasonDownloadStatusVariables {
  readonly movieDbId: number;
  readonly seasonNumber: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnrequestMovie
// ====================================================

export interface UnrequestMovieUnrequestMovie {
  readonly __typename: "MovieAvailability";
  readonly id: number;
  readonly availabilityStatus: AvailabilityStatus;
}

export interface UnrequestMovie {
  readonly unrequestMovie: UnrequestMovieUnrequestMovie;
}

export interface UnrequestMovieVariables {
  readonly movieDbId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnrequestTvSeason
// ====================================================

export interface UnrequestTvSeasonUnrequestTvSeason {
  readonly __typename: "TvSeasonAvailability";
  readonly id: string;
  readonly episodeNumber: string;
  readonly availabilityStatus: AvailabilityStatus;
}

export interface UnrequestTvSeason {
  readonly unrequestTvSeason: ReadonlyArray<UnrequestTvSeasonUnrequestTvSeason>;
}

export interface UnrequestTvSeasonVariables {
  readonly movieDbId: number;
  readonly seasonNumber: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRecentlyAdded
// ====================================================

export interface GetRecentlyAddedRecentlyAdded {
  readonly __typename: "ContentBaseResultType";
  readonly id: number;
  readonly type: MovieDbMediaType;
  readonly title: string;
  readonly posterPath: string | null;
  readonly backdropPath: string | null;
  readonly availabilityStatus: AvailabilityStatus | null;
}

export interface GetRecentlyAdded {
  readonly recentlyAdded: ReadonlyArray<GetRecentlyAddedRecentlyAdded>;
}

export interface GetRecentlyAddedVariables {
  readonly maxAgeDays?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWatchHistories
// ====================================================

export interface GetWatchHistoriesWatchHistories {
  readonly __typename: "WatchHistoryResultType";
  readonly id: number;
  readonly type: MovieDbMediaType;
  readonly title: string;
  readonly playbackTime: number;
  readonly runtimeSeconds: number;
  readonly posterPath: string | null;
  readonly backdropPath: string | null;
  readonly availabilityStatus: AvailabilityStatus | null;
}

export interface GetWatchHistories {
  readonly watchHistories: ReadonlyArray<GetWatchHistoriesWatchHistories>;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCurrentUser
// ====================================================

export interface GetCurrentUserCurrentUser {
  readonly __typename: "UserModel";
  readonly id: number;
  readonly isAdmin: boolean;
}

export interface GetCurrentUser {
  readonly currentUser: GetCurrentUserCurrentUser | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetReferenceData
// ====================================================

export interface GetReferenceDataGenres {
  readonly __typename: "GenreModel";
  readonly id: number;
  readonly name: string;
}

export interface GetReferenceDataCurrentUser {
  readonly __typename: "UserModel";
  readonly id: number;
  readonly isAdmin: boolean;
}

export interface GetReferenceData {
  readonly genres: ReadonlyArray<GetReferenceDataGenres>;
  readonly currentUser: GetReferenceDataCurrentUser | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMoviePlaybackTime
// ====================================================

export interface GetMoviePlaybackTime {
  readonly getMoviePlaybackTime: number;
}

export interface GetMoviePlaybackTimeVariables {
  readonly movieDbId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTvEpisodePlaybackTime
// ====================================================

export interface GetTvEpisodePlaybackTime {
  readonly getTvEpisodePlaybackTime: number;
}

export interface GetTvEpisodePlaybackTimeVariables {
  readonly episodeId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWatchToken
// ====================================================

export interface GetWatchToken {
  readonly watchToken: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetMoviePlaybackTime
// ====================================================

export interface SetMoviePlaybackTime {
  readonly setPlaybackTime: string;
}

export interface SetMoviePlaybackTimeVariables {
  readonly movieDbId: number;
  readonly playbackTime: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetTvEpisodePlayback
// ====================================================

export interface SetTvEpisodePlayback {
  readonly setTvEpisodePlaybackTime: string;
}

export interface SetTvEpisodePlaybackVariables {
  readonly episodeId: number;
  readonly playbackTime: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TvEpisodeDetailsFields
// ====================================================

export interface TvEpisodeDetailsFieldsTvSeasonTvEpisodes {
  readonly __typename: "TVEpisodeModel";
  readonly id: number;
  readonly episodeNum: number;
}

export interface TvEpisodeDetailsFieldsTvSeason {
  readonly __typename: "TVSeasonModel";
  readonly id: number;
  readonly seasonNum: number;
  readonly tvEpisodes: ReadonlyArray<TvEpisodeDetailsFieldsTvSeasonTvEpisodes>;
}

export interface TvEpisodeDetailsFields {
  readonly __typename: "TVEpisodeModel";
  readonly id: number;
  readonly episodeNum: number;
  readonly title: string;
  readonly runtimeSeconds: number;
  readonly hasSubtitles: boolean;
  readonly tvSeason: TvEpisodeDetailsFieldsTvSeason;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ContentFields
// ====================================================

export interface ContentFields {
  readonly __typename: "ContentBaseResultType";
  readonly id: number;
  readonly type: MovieDbMediaType;
  readonly title: string;
  readonly posterPath: string | null;
  readonly backdropPath: string | null;
  readonly availabilityStatus: AvailabilityStatus | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MovieAvailabilityFields
// ====================================================

export interface MovieAvailabilityFields {
  readonly __typename: "MovieAvailability";
  readonly id: number;
  readonly availabilityStatus: AvailabilityStatus;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TvSeasonAvailabilityFields
// ====================================================

export interface TvSeasonAvailabilityFields {
  readonly __typename: "TvSeasonAvailability";
  readonly id: string;
  readonly episodeNumber: string;
  readonly availabilityStatus: AvailabilityStatus;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AvailabilityStatus {
  Available = "Available",
  DownloadInProgress = "DownloadInProgress",
  Requested = "Requested",
  Unavailable = "Unavailable",
}

export enum MovieDbMediaType {
  Book = "Book",
  Movie = "Movie",
  Person = "Person",
  Tv = "Tv",
}

export interface MovieSearchInputType {
  readonly take?: number | null;
  readonly skip?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

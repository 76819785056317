import { QueryResult } from "@apollo/react-common"
import { useQuery } from "@apollo/react-hooks"
import { SimilarShows, SimilarShowsVariables } from "typings"
import { MovieDbQueries } from "../graphql"

export const useSimilarShows = (movieDbId: number): QueryResult<SimilarShows> =>
  useQuery<SimilarShows, SimilarShowsVariables>(MovieDbQueries.GetSimilarShows, {
    variables: {
      movieDbId,
    },
  })

import { Nullable } from "typings"

export const getActiveVideo = (): Nullable<HTMLVideoElement> => document.querySelector("video")

export const getActiveVideoSrc = (): Nullable<string> => {
  const video = getActiveVideo()
  if (video) {
    const videoSrc = video.getAttribute("src")
    if (videoSrc) {
      return videoSrc
    }

    const sources = Array.from(video.querySelectorAll("source"))
    return sources.map(s => s.getAttribute("src")).filter(s => !!s)?.[0]
  }

  return null
}

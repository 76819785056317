import { QueryResult } from "@apollo/react-common"
import { useQuery } from "@apollo/react-hooks"
import { SimilarMovies, SimilarMoviesVariables } from "typings/_graphql"
import { MovieDbQueries } from "../graphql"

export const useSimilarMovies = (movieDbId: number): QueryResult<SimilarMovies> =>
  useQuery<SimilarMovies, SimilarMoviesVariables>(MovieDbQueries.GetSimilarMovies, {
    variables: {
      movieDbId,
    },
  })

import { QueryResult } from "@apollo/react-common"
import { useQuery } from "@apollo/react-hooks"
import { FetchPolicy } from "apollo-client"
import { GetShowEpisodeDetails, GetShowEpisodeDetailsVariables } from "typings"
import { MovieDbQueries } from "../graphql"

export const useShowEpisodeDetails = (episodeId: number, fetchPolicy?: FetchPolicy): QueryResult<GetShowEpisodeDetails> =>
  useQuery<GetShowEpisodeDetails, GetShowEpisodeDetailsVariables>(MovieDbQueries.GetShowEpisodeDetails, {
    fetchPolicy,
    variables: {
      episodeId,
    },
  })

import { QueryResult } from "@apollo/react-common"
import { useQuery } from "@apollo/react-hooks"
import { WatchGraphqlQueries } from "domain/watch/graphql"
import { GetTvEpisodePlaybackTime, GetTvEpisodePlaybackTimeVariables } from "typings"

export const useGetTvEpisodePlaybackTime = (episodeId: number): QueryResult<GetTvEpisodePlaybackTime, GetTvEpisodePlaybackTimeVariables> =>
  useQuery<GetTvEpisodePlaybackTime, GetTvEpisodePlaybackTimeVariables>(WatchGraphqlQueries.GetTvEpisodePlaybackTime, {
    variables: {
      episodeId,
    },
  })

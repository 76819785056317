import { CustomScroller } from "components/custom-scroller"
import { LoadingSpinner } from "components/loading-spinner"
import { NavigationArrows } from "components/navigation/navigation-arrows"
import { NavigationContainer, navigationGridAreas, NavigationHeader, NavigationScroller } from "components/navigation/navigation-container"
import { useNavbar } from "domain/navbar/context/navbar-context"
import React from "react"
import styled from "styled-components"
import {
  ApolloRefetchMethod,
  AvailabilityStatus,
  Func2,
  GetShowSeasonDetails,
  GetShowSeasonDetailsGetTvSeasonDetailsEpisodes,
  GetShowSeasonDetailsVariables,
  Nullable,
  SubscribeSeasonDownloadStatusSeasonTorrentStatus,
  UseEffectReturn,
} from "typings"
import { ContentNavigationProvider, useContentNavigation } from "util/hooks"
import { EpisodeListItem } from "./episode-list-item"

const DATA_EPISODE_NUMBER = "data-episodenumber"

const EpisodeListScrollerWrapper = styled.div.attrs((props: EpisodeListScrollerWrapperProps) => ({
  style: {
    height: `${props.itemHeight}px`,
  },
}))<EpisodeListScrollerWrapperProps>`
  grid-area: ${navigationGridAreas.scroller};
  height: ${({ itemHeight }: EpisodeListScrollerWrapperProps): string => `${itemHeight + 50}px`};
  overflow-x: hidden;

  &:after {
    height: ${({ itemHeight }: EpisodeListScrollerWrapperProps): string => `${itemHeight}px`};
  }
`

const EpisodeNavigationScroller = styled(NavigationScroller)<EpisodeNavigationScrollerProps>`
  justify-content: ${({ isLoading }: EpisodeNavigationScrollerProps): string => (isLoading ? "center" : "")};
  padding-top: ${({ isLoading }: EpisodeNavigationScrollerProps): string => (isLoading ? "32px" : "")};
`

const InnerEpisodeList = ({
  header,
  items,
  refetch,
  onItemClicked,
  loading = false,
  scrollToEpisodeNumber,
  downloadProgress,
}: EpisodeListProps): JSX.Element => {
  const {
    scrollerRef,
    customScrollerRef,
    scrollerClientWidth,
    scrollerClientHeight,
    scrollerScrollWidth,
    onCustomScrollerScrolled,
    onCustomScrollerScrollStop,
  } = useContentNavigation()
  const navbar = useNavbar()

  const mapItem = (item: GetShowSeasonDetailsGetTvSeasonDetailsEpisodes): JSX.Element => {
    const episodeDownloadProgress =
      downloadProgress?.episodeProgresses.filter(ep => downloadProgress.seasonNumber === item.seasonNumber && ep.episodeNumber === item.episodeNumber) ?? []
    return <EpisodeListItem key={item.id} item={item} refetch={refetch} onItemClicked={onItemClicked} downloadProgress={episodeDownloadProgress} />
  }

  React.useEffect((): UseEffectReturn => {
    const scroller = scrollerRef.current
    const customScroller = customScrollerRef.current
    if (scroller && customScroller && scrollToEpisodeNumber != null) {
      const episodeChild = Array.from(scroller.children).find(
        child => child.getAttribute(DATA_EPISODE_NUMBER) === scrollToEpisodeNumber.toString(),
      ) as Nullable<HTMLDivElement>
      if (episodeChild) {
        setTimeout(
          () =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (customScroller as any).view.scroll({
              left: episodeChild.offsetLeft - navbar.navbarWidth,
              behavior: "smooth",
            }),
          0,
        )
      }
    }
  }, [scrollToEpisodeNumber, loading])

  return (
    <NavigationContainer>
      <NavigationHeader {...navbar}>{header}</NavigationHeader>
      {!!scrollerClientWidth && !!scrollerScrollWidth && <NavigationArrows />}
      <EpisodeListScrollerWrapper itemHeight={scrollerClientHeight}>
        <CustomScroller ref={customScrollerRef} onScroll={onCustomScrollerScrolled} onScrollStop={onCustomScrollerScrollStop}>
          <EpisodeNavigationScroller ref={scrollerRef} isLoading={loading} {...navbar}>
            {(loading && <LoadingSpinner size="medium" />) || items.map(mapItem)}
          </EpisodeNavigationScroller>
        </CustomScroller>
      </EpisodeListScrollerWrapper>
    </NavigationContainer>
  )
}

export const EpisodeList = (props: EpisodeListProps): JSX.Element => (
  <ContentNavigationProvider>
    <InnerEpisodeList {...props} />
  </ContentNavigationProvider>
)

export interface EpisodeListProps {
  readonly header: React.ReactNode
  readonly items: ReadonlyArray<GetShowSeasonDetailsGetTvSeasonDetailsEpisodes>
  readonly refetch: ApolloRefetchMethod<GetShowSeasonDetails, GetShowSeasonDetailsVariables>
  readonly onItemClicked?: Func2<GetShowSeasonDetailsGetTvSeasonDetailsEpisodes, AvailabilityStatus, void>
  readonly loading?: boolean
  readonly scrollToEpisodeNumber?: number
  readonly downloadProgress: Nullable<SubscribeSeasonDownloadStatusSeasonTorrentStatus>
}

interface EpisodeListScrollerWrapperProps {
  readonly itemHeight: number
}

interface EpisodeNavigationScrollerProps {
  readonly isLoading: boolean
}

import * as React from "react"
import { Func0, Func1 } from "typings"

export interface UseBoolean {
  readonly value: boolean
  readonly setValue: Func1<boolean, void>
  readonly toggle: Func0<void>
}

export const useBoolean = (initialValue?: boolean): UseBoolean => {
  const [value, setValue] = React.useState(initialValue || false)

  const toggle = (): void => setValue(!value)

  return {
    value,
    setValue,
    toggle,
  }
}

import { connectRouter } from "connected-react-router"
import { navbarReducer } from "domain/navbar/store/reducer"
import { combineReducers, Reducer } from "redux"
import { getHistory } from "./history"
import { RootState } from "typings"
import { userReducer } from "domain/user/store/reducer"

export const createRootReducer = (): Reducer<RootState> => {
  return combineReducers({
    navbar: navbarReducer,
    user: userReducer,
    router: connectRouter(getHistory()),
  })
}

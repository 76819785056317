import { addSeconds, format, startOfDay } from "date-fns"

export const removeLeadingSlash = (endpoint: string): string => (endpoint.startsWith("/") ? endpoint.substring(1) : endpoint)

export const pluralize = (str: string, count: number): string => {
  switch (count) {
    case 1:
      return str
    default:
      return `${str}s`
  }
}

export const pageTitle = (title: string): string => `Mattflix${title ? ` - ${title}` : ""}`

const getPlaybackTimeFormat = (timeSeconds: number): string => {
  const hours = Math.floor(timeSeconds / 3600)
  if (hours > 0) {
    return "H:mm:ss"
  } else {
    return "m:ss"
  }
}

export const formatPlaybackTime = (timeSeconds: number): string => {
  const date = addSeconds(startOfDay(new Date()), timeSeconds)
  return format(date, getPlaybackTimeFormat(timeSeconds))
}

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import { routerActions } from "connected-react-router"
import { ParsedUrlQueryInput } from "querystring"
import { Nullable } from "typings"
import { getApiUrl } from "util/url"
import { LoginRoute } from "../../constants"
import { useStore } from "react-redux"
import { useMemo } from "react"

const baseConfig: AxiosRequestConfig = {
  withCredentials: true,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isAxiosException = <T>(ex: any): ex is AxiosError<T> => ex && ex.request && ex.response

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isUnauthorized = (ex: any): boolean => ex.response && ex.response.status === 403

export const useHttpClient = (): HttpClient => {
  const store = useStore()

  return useMemo(
    () => ({
      get: async <T>(
        endpoint: string,
        args?: Nullable<ParsedUrlQueryInput>,
        redirectIfUnauthorized: boolean = true,
        config?: AxiosRequestConfig,
      ): Promise<AxiosResponse<T>> => {
        try {
          const result = await axios.get<T>(getApiUrl(endpoint, args), { ...baseConfig, ...(config || {}) })
          return Promise.resolve(result)
        } catch (ex) {
          if (redirectIfUnauthorized && isUnauthorized(ex)) {
            store.dispatch(routerActions.replace(LoginRoute))
          }
          throw ex
        }
      },

      post: async <T>(
        endpoint: string,
        args: Nullable<ParsedUrlQueryInput>,
        data: object,
        redirectIfUnauthorized: boolean = true,
        config?: AxiosRequestConfig,
      ): Promise<AxiosResponse<T>> => {
        try {
          const result = await axios.post<T>(getApiUrl(endpoint, args), data, { ...baseConfig, ...(config || {}) })
          return Promise.resolve(result)
        } catch (ex) {
          if (redirectIfUnauthorized && isUnauthorized(ex)) {
            store.dispatch(routerActions.replace(LoginRoute))
          }

          throw ex
        }
      },
    }),
    [],
  )
}

export interface HttpClient {
  readonly get: <T>(
    endpoint: string,
    args?: Nullable<ParsedUrlQueryInput>,
    redirectIfUnauthorized?: boolean,
    config?: AxiosRequestConfig,
  ) => Promise<AxiosResponse<T>>

  readonly post: <T>(
    endpoint: string,
    args: Nullable<ParsedUrlQueryInput>,
    data: object,
    redirectIfUnauthorized?: boolean,
    config?: AxiosRequestConfig,
  ) => Promise<AxiosResponse<T>>
}

import { AvailabilityOverlay } from "components/availability-overlay"
import { ContentListItemPoster } from "components/content-list"
import { routerActions } from "connected-react-router"
import React from "react"
import styled from "styled-components"
import { AvailabilityStatus, GetMovieDetailsGetMovieDetails, Nullable, SubscribeMovieDownloadStatusMovieTorrentStatus, RootState } from "typings"
import { WatchMovieRoute } from "../../../../../constants"
import { fullscreenGridAreas } from "../fullscreen-container"
import { FullscreenMovieActionButton } from "./fullscreen-movie-action-button"
import { useStore } from "react-redux"

const MoviePosterContainer = styled.div`
  grid-area: ${fullscreenGridAreas.poster};

  display: grid;
  justify-content: start;
  justify-items: center;
  align-content: start;
  gap: 16px;
`

const StyledFullscreenPoster = styled.div`
  position: relative;

  :hover {
    img {
      filter: brightness(0.4);
    }
  }
`

export const FullscreenMoviePoster = ({ movie, downloadProgress }: FullscreenPosterProps): JSX.Element => {
  const store = useStore<RootState>()

  const {
    id,
    availabilityStatus: { availabilityStatus },
    posterPath,
  } = movie

  const watchMovieIfAvailable = (): void => {
    if (availabilityStatus === AvailabilityStatus.Available) {
      store.dispatch(routerActions.push(WatchMovieRoute(id)))
    }
  }

  return (
    <MoviePosterContainer>
      <StyledFullscreenPoster onClick={watchMovieIfAvailable}>
        <AvailabilityOverlay availabilityStatus={availabilityStatus} />
        <ContentListItemPoster src={posterPath!} />
      </StyledFullscreenPoster>
      <FullscreenMovieActionButton movie={movie} downloadProgress={downloadProgress} />
    </MoviePosterContainer>
  )
}

interface FullscreenPosterProps {
  readonly movie: GetMovieDetailsGetMovieDetails
  readonly downloadProgress: Nullable<SubscribeMovieDownloadStatusMovieTorrentStatus>
}

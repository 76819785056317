import { ButtonProps } from "@material-ui/core"
import { Button } from "components/button"
import { Card } from "components/card"
import { FieldInput } from "components/field-input"
import { Logo } from "components/logo"
import { Body1 } from "components/typography"
import { routerActions } from "connected-react-router"
import { useHttpClient } from "domain/network/api-client"
import { changePassword } from "domain/network/requests/changePassword"
import React from "react"
import { Form } from "react-final-form"
import { useStore } from "react-redux"
import { Link } from "react-router-dom"
import { ThemeProps } from "style/theme"
import styled from "styled-components"
import { ForgotRequest, RootState } from "typings"
import { LoginRoute } from "../../../constants"
import { validatePassword } from "../validators/validate-password"
import { validateUsername } from "../validators/validate-username"

const ForgotPage = styled.div`
  padding-top: 50px;
`

const StyledForgot = styled(Card)`
  background-color: ${({ theme }: ThemeProps): string => `${theme.surface.color}99`} !important;
  display: grid;
  justify-content: center;
  align-content: start;
  width: fit-content;
  height: fit-content;
  margin: 2rem auto;
  padding: 2rem;
  padding-bottom: 0.5rem;
`

const ForgotLogo = styled(Logo)`
  width: 225px;
  margin: 0 auto;
`

const StyledForgotForm = styled.form`
  display: grid;
  gap: 15px;
  justify-content: stretch;
  padding: 0 1rem 1.5rem 1rem;
  width: 20rem;
`

const ForgotButton = styled(Button).attrs<ButtonProps>({ variant: "contained" })`
  justify-self: center;
`

const LoginLink = styled(Link)`
  color: ${({ theme }: ThemeProps): string => theme.primary.color} !important;
  cursor: pointer;
  font-size: 0.85rem !important;
  justify-self: center;
`

const Requirements = styled(Body1)`
  margin: 0 auto;
  width: fit-content;
  line-height: 1.5;
`

const Forgot = (): JSX.Element => {
  const store = useStore<RootState>()
  const httpClient = useHttpClient()

  const onSubmit = async (formModel: ForgotFormModel): Promise<void> => {
    try {
      const { success, error } = await changePassword(httpClient, formModel)
      if (success) {
        store.dispatch(routerActions.push(`${LoginRoute}?f=1`))
      } else {
        alert(error)
      }
    } catch (ex) {
      console.error(`Failed to request change password`)
    }
  }

  return (
    <ForgotPage>
      <StyledForgot elevation={8}>
        <ForgotLogo />
        <Form onSubmit={onSubmit}>
          {(props): React.ReactNode => {
            return (
              <StyledForgotForm onSubmit={props.handleSubmit}>
                <span style={{ paddingTop: "8px" }} />
                <FieldInput name="username" label="Username" validate={validateUsername} />
                <FieldInput name="password" label="New Password" type="password" validate={validatePassword} />
                <FieldInput name="confirmPassword" label="Confirm Password" type="password" validate={validatePassword} />
                <ForgotButton type="submit">Request Password Change</ForgotButton>
              </StyledForgotForm>
            )
          }}
        </Form>
        <LoginLink to={LoginRoute}>Already have an account?</LoginLink>
      </StyledForgot>
      <Requirements>- Password must be at least 6 characters (letters, numbers, symbols)</Requirements>
    </ForgotPage>
  )
}

export default Forgot

export interface ForgotFormModel extends ForgotRequest {
  readonly confirmPassword: string
}

import React from "react"
import Scrollbars from "react-custom-scrollbars"
import { UseEffectReturn } from "typings"

export type PagingThreshold = "greedy" | "default" | "patient"

const pagingThresholds: ReadonlyMap<PagingThreshold, number> = new Map([
  ["greedy", 0.3],
  ["default", 0.6],
  ["patient", 0.9],
])

const getPercentageScrolled = (target: HTMLDivElement, direction: ScrollDirection): number => {
  switch (direction) {
    case "vertical":
      return (target.scrollTop + target.clientHeight) / target.scrollHeight

    case "horizontal":
      return (target.scrollLeft + target.clientWidth) / target.scrollWidth
  }
}

const getCurrentScrollValue = (view: HTMLDivElement, direction: ScrollDirection): number => {
  switch (direction) {
    case "vertical":
      return view.scrollHeight

    case "horizontal":
      return view.scrollWidth
  }
}

export const useInfiniteScrolling = (ref: React.RefObject<Scrollbars>, options?: UseInfiniteScrollingOptions): UseInfiniteScrolling => {
  const threshold = pagingThresholds.get(options?.threshold ?? "default")!
  const scrollDirection = options?.direction ?? "vertical"
  const [currentPage, setCurrentPage] = React.useState<number>(options?.initialPage ?? 1)
  const lastNotifiedScrollHeight = React.useRef<number>(0)

  React.useEffect((): UseEffectReturn => {
    const scrollingContainer = ref.current
    if (scrollingContainer) {
      // @ts-ignore
      const view = scrollingContainer.view as HTMLDivElement
      if (view) {
        //{ clientHeight, clientWidth, scrollHeight, scrollWidth, scrollTop, scrollLeft }
        const onScroll = ({ currentTarget }: React.UIEvent<HTMLDivElement>): void => {
          const percentageScrolled = getPercentageScrolled(currentTarget, scrollDirection)
          if (percentageScrolled >= threshold) {
            const currentScrollValue = getCurrentScrollValue(view, scrollDirection)
            if (lastNotifiedScrollHeight.current !== currentScrollValue) {
              lastNotifiedScrollHeight.current = currentScrollValue
              setCurrentPage(currentPage + 1)
            }
          }
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        view.addEventListener("scroll", onScroll as any)

        return (): void => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          view.removeEventListener("scroll", onScroll as any)
        }
      }
    }
  }, [currentPage])

  return {
    page: currentPage,
  }
}

export type ScrollDirection = "vertical" | "horizontal"

export interface UseInfiniteScrollingOptions {
  readonly direction?: ScrollDirection
  readonly threshold?: PagingThreshold
  readonly initialPage?: number
}

export interface UseInfiniteScrolling {
  readonly page: number
}

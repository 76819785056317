import { ContentGrid } from "components/content-list/content-grid"
import { ContentLoadingSpinner } from "components/loading-spinner"
import { PaddedPageContent } from "components/page"
import { Body1 } from "components/typography"
import { useSearch } from "domain/content/hooks/use-search"
import React from "react"
import { Redirect } from "react-router"
import { UseEffectReturn } from "typings"
import { StringParam, useQueryParam } from "use-query-params"
import { usePageHistory } from "util/hooks"
import { HomeRoute, SearchRoute } from "../../../../constants"

const Search = (): JSX.Element => {
  const [searchPhrase] = useQueryParam("q", StringParam)

  if (!searchPhrase || searchPhrase.trim().length === 0) {
    return <Redirect to={HomeRoute} />
  }

  const { pushHistory } = usePageHistory()

  React.useEffect((): UseEffectReturn => {
    pushHistory({
      title: `Search Results for '${searchPhrase}'`,
      path: SearchRoute(searchPhrase),
    })
  }, [])

  const { data, loading, error } = useSearch(searchPhrase)

  if (loading) {
    return <ContentLoadingSpinner size="medium" />
  }

  if (error) {
    return (
      <PaddedPageContent>
        <Body1>An error occurred while loading search results</Body1>
      </PaddedPageContent>
    )
  }

  if (data?.search) {
    return <ContentGrid id={`search_grid_${searchPhrase}`} header={`Search Results for '${searchPhrase}'`} items={data.search} />
  }

  return (
    <PaddedPageContent>
      <Body1>An error occurred while loading search results</Body1>
    </PaddedPageContent>
  )
}

export default Search

import inRange from "lodash-es/inRange"

export const episodeListItemMinWidth = 265
const episodeListItemAspectRatio = 0.5

export const getEffectiveItemSize = (scrollerClientWidth: number): readonly [number, number] => {
  if (scrollerClientWidth === 0) {
    return [episodeListItemMinWidth, episodeListItemMinWidth * episodeListItemAspectRatio]
  }

  const numPostersToFit = Math.floor(scrollerClientWidth / episodeListItemMinWidth)
  const posterWidth = scrollerClientWidth / numPostersToFit
  const posterHeight = posterWidth * episodeListItemAspectRatio
  return [posterWidth, posterHeight]
}

export const isContentItemRendered = (item: HTMLDivElement, scrollerScrollLeft: number, scrollerClientWidth: number): boolean => {
  const { offsetLeft } = item
  const minOffsetLeft = scrollerScrollLeft - scrollerClientWidth
  const maxOffsetLeft = scrollerScrollLeft + scrollerClientWidth * 2

  return inRange(offsetLeft, minOffsetLeft, maxOffsetLeft)
}

import { routerActions } from "connected-react-router"
import React from "react"
import { ThemeProps } from "style/theme"
import styled from "styled-components"
import { Func0, RootState } from "typings"
import { Page, usePageHistory } from "util/hooks"
import { MdIcon } from "./md-icon"
import { Body1 } from "./typography"
import { useStore } from "react-redux"

const ReturnToLinkWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 12px;
  color: ${({ theme }: ThemeProps): string => theme.surface.textOn};
  cursor: pointer;
  padding: 5px 0;
  width: fit-content;

  &:hover {
    color: white;
  }

  i {
    color: inherit;
  }

  ${Body1} {
    color: inherit;
  }
`

export const ReturnToLink = ({ page }: ReturnToLinkProps): JSX.Element => {
  const { popHistory } = usePageHistory()
  const store = useStore<RootState>()

  const navigateTo = (path: string): Func0<void> => (): void => {
    popHistory()
    store.dispatch(routerActions.push(path))
  }

  return (
    <ReturnToLinkWrapper onClick={navigateTo(page.path)}>
      <MdIcon icon="arrow_back" />
      <Body1>Return to {page.title}</Body1>
    </ReturnToLinkWrapper>
  )
}

export interface ReturnToLinkProps {
  readonly page: Page
}

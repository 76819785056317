import { Card as MaterialCard } from "@material-ui/core"
import { ThemeProps } from "style/theme"
import styled from "styled-components"

export const Card = styled(MaterialCard)`
  && {
    color: inherit;
    background-color: ${({ theme }: ThemeProps): string => theme.surface.color} !important;
  }
`

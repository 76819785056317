import React, { useRef, useState, useEffect, forwardRef } from "react"
import styled from "styled-components"
import { Logo } from "components/logo"
import { Card } from "components/card"
import { ThemeProps } from "style/theme"
import { Headline6, Body2, Body1 } from "components/typography"
import { Button } from "components/button"
import { Func0, CompleteAuthTokenRequest } from "typings"
import { Link as RouterLink, useLocation } from "react-router-dom"
import { LogoutRoute, LinkRoute, HomeRoute } from "constants/routes"
import { useSelector, useStore } from "react-redux"
import { userSelectors } from "domain/user/store/selectors"
import { ColorShift } from "style/color-shifting"
import { routerActions } from "connected-react-router"
import { completeAuthToken } from "domain/network/requests"
import { useHttpClient } from "domain/network/api-client"
import { useQueryParam, StringParam } from "use-query-params"

const LinkContainer = styled.div`
  display: grid;
  height: 100%;
`

const LinkCard = styled(Card)`
  background-color: ${({ theme }: ThemeProps): string => `${theme.surface.color}99`} !important;
  display: grid;
  gap: 8px;
  justify-content: center;
  align-content: start;
  width: fit-content;
  height: 350px;
  margin: calc(2rem + 50px) auto 0 auto;
  padding: 2rem;
  padding-bottom: 1rem;
`

const LinkLogo = styled(Logo)`
  width: 225px;
  margin: 0 auto;
  cursor: pointer;
`

const LinkError = styled(Body1)`
  justify-self: center;
  color: ${({ theme }: ThemeProps): string => theme.primary.color};

  max-width: 325px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
`

const CodeDigitsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  margin: 16px;
`

const _CodeDigit = styled.input.attrs({ maxLength: 1 })`
  border-bottom: 2px solid ${({ theme }: ThemeProps): string => theme.background.textOn};
  color: ${({ theme }: ThemeProps): string => theme.background.textOn};
  width: 64px;
  font-size: 3rem;
  text-align: center;
`

const CodeDigit = forwardRef<HTMLInputElement, CodeDigitProps>(
  ({ previousRef, nextRef, initialValue, onComplete, onEnterPressed }: CodeDigitProps, ref: React.Ref<HTMLInputElement>): JSX.Element => {
    const [value, setValue] = useState<string>(initialValue ?? "")

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      const newValue = e.target.value.replace(/[^a-z]/gi, "")
      setValue(newValue.toUpperCase())
    }

    const onFocus = (): void => {
      if (previousRef?.current) {
        const previousValue = previousRef.current.value
        if (!previousValue) {
          previousRef.current.focus()
          return
        }
      }
    }

    const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
      if (e.key === "Enter") {
        onEnterPressed()
      }
    }

    useEffect(() => {
      if (value.length > 0) {
        if (nextRef) {
          nextRef.current?.focus()
        } else if (onComplete) {
          onComplete()
        }
      }
    }, [value])

    return <_CodeDigit ref={ref} value={value} onChange={onChange} onFocus={onFocus} onKeyPress={onKeyPress} />
  },
)

const ChangeUserLink = styled(RouterLink)`
  color: ${({ theme }: ThemeProps): string => theme.primary.color} !important;
  cursor: pointer;
  font-size: 0.85rem !important;
  justify-self: center;
  text-decoration: none;

  :hover {
    color: ${({ theme }: ThemeProps): string => ColorShift.dark(theme.primary.color, theme.primary.textOn)} !important;
  }
`

const Link = (): JSX.Element => {
  const store = useStore()
  const httpClient = useHttpClient()
  const username = useSelector(userSelectors.getUsername)
  const [presetCode] = useQueryParam("code", StringParam)
  const { search } = useLocation()

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const [done, setDone] = useState<boolean>(false)

  const ref1 = useRef<HTMLInputElement>(null)
  const ref2 = useRef<HTMLInputElement>(null)
  const ref3 = useRef<HTMLInputElement>(null)
  const ref4 = useRef<HTMLInputElement>(null)

  const onComplete = async (): Promise<void> => {
    const code = `${ref1.current?.value}${ref2.current?.value}${ref3.current?.value}${ref4.current?.value}`
    if (code.length !== 4) {
      return
    }

    try {
      setLoading(true)

      const request: CompleteAuthTokenRequest = {
        code,
      }

      const response = await completeAuthToken(httpClient, request)
      console.log(response)
      if (response.success) {
        setDone(true)
        setError(undefined)
      } else if (response.error) {
        setError(response.error)
      }
    } catch (ex) {
      setError(ex.message ?? ex)
    } finally {
      setLoading(false)
    }
  }

  const onPaste = (e: React.ClipboardEvent<HTMLDivElement>): void => {
    const pastedText = e.clipboardData?.getData("text/plain") ?? ""
    const letters = pastedText.split("").filter(l => /[a-zA-Z]/g.test(l))
    if (letters[0]) ref1.current!.value = letters[0].toUpperCase()
    if (letters[1]) ref2.current!.value = letters[1].toUpperCase()
    if (letters[2]) ref3.current!.value = letters[2].toUpperCase()
    if (letters[3]) ref4.current!.value = letters[3].toUpperCase()
  }

  return (
    <LinkContainer>
      <LinkCard>
        <LinkLogo onClick={() => store.dispatch(routerActions.push(HomeRoute))} />
        {(done && <Headline6>Success! Please return to your TV.</Headline6>) || (
          <>
            <Headline6>Enter the code displayed on your TV</Headline6>
            {error && <LinkError>{error}</LinkError>}
            <CodeDigitsContainer onPaste={onPaste}>
              <CodeDigit ref={ref1} initialValue={presetCode?.[0]} nextRef={ref2} onEnterPressed={onComplete} />
              <CodeDigit ref={ref2} initialValue={presetCode?.[1]} nextRef={ref3} previousRef={ref1} onEnterPressed={onComplete} />
              <CodeDigit ref={ref3} initialValue={presetCode?.[2]} nextRef={ref4} previousRef={ref2} onEnterPressed={onComplete} />
              <CodeDigit ref={ref4} initialValue={presetCode?.[3]} previousRef={ref3} onEnterPressed={onComplete} />
            </CodeDigitsContainer>
            <Button isLoading={loading} onClick={onComplete}>
              Continue
            </Button>
            <Body2 style={{ justifySelf: "center" }}>You are currently logged in as {username}.</Body2>
            <ChangeUserLink to={`${LogoutRoute}?next=${encodeURIComponent(`${LinkRoute}${search}`)}`}>Sign in as a different user</ChangeUserLink>
          </>
        )}
      </LinkCard>
    </LinkContainer>
  )
}

export default Link

interface CodeDigitProps {
  readonly initialValue?: string
  readonly nextRef?: React.RefObject<HTMLInputElement | undefined>
  readonly previousRef?: React.RefObject<HTMLInputElement | undefined>
  readonly onComplete?: Func0<void>
  readonly onEnterPressed: Func0<void>
}
